import { format, parseISO, isValid } from 'date-fns';

export const dateFormat = (date: Date | string, dateFormat: string = 'dd/MM/yyyy') => {
    let parsedDate: Date;

    if (typeof date === 'string') {
    parsedDate = parseISO(date);
    } else {
    parsedDate = date;
    }

    return isValid(parsedDate) ? format(parsedDate, dateFormat) : 'Data inválida';
};