import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Height } from "@material-ui/icons";

const useStyles = makeStyles((theme:Theme) => ({
    root: {
        maxWidth: 520,
        padding: theme.spacing(4),
        paddingTop: 0,

        '& > div': {
          padding: 0,
        },
    },
    closeIcon: {
        position: 'absolute',
        left: 'calc(100% - 36px)',
        padding: 0,
        top: theme.spacing(2),
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        paddingTop: '0 !important',
        marginTop: theme.spacing(4),
    },
    title: {
        fontSize: theme.typography.h6.fontSize,
        fontWeight: 700,
        color: theme.palette.text.primary,
    },
    description: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 500,
        color: theme.palette.text.secondary,
    },
    InfoAlert: {
        alignItems: 'center',
        borderRadius: theme.spacing(1),
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(1),
        marginBlock: theme.spacing(3),
        textAlign: 'center',
    },
    qrCode: {
        width: 200,
        height: 200,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
    },
    copyPixButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(1),
        backgroundColor: '#F6F3F9',
        color: theme.palette.primary.dark,
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 500,
        borderRadius: theme.shape.borderRadius,
        paddingBlock: 6,
        paddingInline: theme.spacing(2),

        '& span': {
            display: 'flex',
            gap: theme.spacing(1),
            
            '& div': {
                
            },
        },
    },
    textButton: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 500,
        color: theme.palette.primary.dark,
    },
    copyIcon: {
        display: 'flex',
        justifyContent:'center',
        alignItems:'center',

        backgroundColor: theme.palette.grey[200],
        borderRadius: '50%',
        padding: theme.spacing(0.75),

        '& svg': {
            width:theme.spacing(2),
            height:theme.spacing(2),
            fill: theme.palette.primary.dark,
        },
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 32
    }
}));

export default useStyles;