import React from 'react';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import EventIcon from '@material-ui/icons/Event';

export function DatePicker({
  fullWidth = true,
  name = '',
  label = '',
  disabled = false,
  minDate = moment('2000-01-01').format('YYYY-MM-DD'),
  onChange = () => {},
  value = '',
  error = false,
  helperText = '',
  inputProps = {},
  ...otherProps
}) {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
      <KeyboardDatePicker
        clearable
        autoOk
        fullWidth={fullWidth}
        name={name}
        label={label}
        disabled={disabled}
        size="small"
        variant="inline"
        inputVariant="outlined"
        format="DD/MM/YYYY"
        onChange={onChange}
        value={value || null}
        minDate={minDate}
        error={error}
        helperText={helperText}
        minDateMessage="A data não pode ser anterior a data atual para esse tipo de pagamento"
        invalidDateMessage="A data não é válida"
        InputAdornmentProps={{ position: 'end' }}
        keyboardIcon={<EventIcon />}
        InputProps={{
          style: { borderRadius: '0.5rem' },
          ...inputProps,
          inputMode: 'numeric',
        }}
        {...otherProps}
      />
    </MuiPickersUtilsProvider>
  );
}
