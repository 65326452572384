import { reduxApi } from "../../../../services/reduxApi";
import { EmployeesResponse, FileUploadResponse, Pagination } from "./types";

const fileUploadEmployeeTags = {
    tag: 'FILE_UPLOAD_EMPLOYEE',
};

const slice = reduxApi
    .enhanceEndpoints({
        addTagTypes: [fileUploadEmployeeTags.tag],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getFileTemplate: builder.mutation({
                query: () => ({
                    url: `/files/employees/update/import/model`,
                    method: 'GET',
                }),
            }),
            getImportDetails: builder.query<FileUploadResponse, { processId: string }>({
                query: ({ processId }) => ({
                    url: `/files/employees/update/import/details/${processId}`,
                    method: 'GET',
                }),
            }),
            getSuccessImportDetails: builder.query<EmployeesResponse, { fileId: string, params: Pagination }>({
                query: ({ fileId, params }) => ({
                    url: `files/employees/update/import/success/${fileId}`,
                    method: 'GET',
                    params
                }),
            }),
            getErrorsImportDetails: builder.query<EmployeesResponse, { fileId: string, params: Pagination }>({
                query: ({ fileId, params }) => ({
                    url: `files/employees/update/import/failed/${fileId}`,
                    method: 'GET',
                    params
                }),
            }),
            fileExport: builder.mutation({
                query: ({ companyCode, fileType = '1', fileExtension, file }: { companyCode: string; fileExtension: string; fileType?: string; file: File }) => {
                    const formData = new FormData();
                    formData.append('file', file);
            
                    return {
                        url: `/files/employees/update/import/validate/${companyCode}/${fileType}`,
                        method: 'POST',
                        headers: {
                            fileExtension,
                        },
                        body: formData,
                    };
                },
            }),
            confirmFile: builder.mutation({
                query: ({ fileId }: { fileId: string }) => ({
                    url: `/files/employees/update/import/confirm/${fileId}`,
                    method: 'PUT',
                }),
            }),
        }),
    });

export const {
    useGetFileTemplateMutation,
    useGetImportDetailsQuery,
    useFileExportMutation,
    useConfirmFileMutation,
    useGetSuccessImportDetailsQuery,
    useGetErrorsImportDetailsQuery
} = slice;
