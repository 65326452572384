// @ts-ignore
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { ActionsPayrollDetails } from "../../../../redux/store/Payroll/PayrollDetails";
import { useEffect } from 'react';

export const useDownloadingNSUProof = ()=>{
    const dispatch = useDispatch()
    const history = useHistory()

    const { downloadingNSUProof } = useSelector((state: any) => state.payroll.payrollDetails);
    
    const downloadsConcluded = downloadingNSUProof.filter((item: any) => item.downloadReceiptsPercentage === 100);
    const downloadsInProgress = downloadingNSUProof.filter((item: any) => item.downloadReceiptsPercentage !== 100);
    const downloadError = downloadingNSUProof.some((item: any) => item.error === true);

    const goToSeeDetails = (nsu: number) => {
        dispatch(ActionsPayrollDetails.setNsuFromProgressBar(nsu));
        history.push(`/payroll/details`);
    }

    const handleClose = (nsu: number) => {
        dispatch(ActionsPayrollDetails.removeNsuFromProgressBar(nsu));
    }

    useEffect(() => {
        if (downloadsConcluded.length > 0) {
            setTimeout(() => {
            downloadsConcluded.map((item: any) => {
                dispatch(ActionsPayrollDetails.removeNsuFromProgressBar(item?.nsu));
            })
            }, 5000);
        }
    }, [downloadingNSUProof]);

    return {
        data: downloadingNSUProof,
        actions: {
            goToSeeDetails,
            handleClose
        },
        statusCounts: {
            allDownloading: downloadingNSUProof.length,
            inProgress: downloadsInProgress.length,
            concluded: downloadsConcluded.length,
            error: downloadError,
        }
    }
}