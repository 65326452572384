import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DownloadCnabReturnFilePusher } from './types';

const initialState: DownloadCnabReturnFilePusher | null = null ;

const downloadCnabReturnFilePusherSlice = createSlice({
  name: 'downloadCnabReturnFilePusher',
  initialState,
  reducers: {
    addDownloadCnabReturnFilePusher: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
    clearCnabDownloadPusherStatus: () => {
      return null;
    },
  },
});

export const { addDownloadCnabReturnFilePusher, clearCnabDownloadPusherStatus } = downloadCnabReturnFilePusherSlice.actions;

export default downloadCnabReturnFilePusherSlice.reducer;
