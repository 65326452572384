import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    buttonStyle: {
        color: "#ffffff",
        minWidth: 220,
        backgroundColor: "#5c2483 !important",
        textTransform: "none",
        border: "1px solid #52197f",
        boxSizing: "border-box",
        borderRadius: 51,
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.25)",
        height: 40,
    },
    buttonTextStyle: {
        textTransform: "none",
        fontWeight: 500,
    },
    menuList: {
        marginTop: '48px',
        marginLeft: '-30px',
        wordBreak: 'break-all',
    },
}));