import { useDispatch, useSelector } from "react-redux";
import { handleSnackbarStack } from "../../../../utils/handleSnackbarStack";
import { OnboardingActions } from "../../../../redux/store/Employees/Onboard";
import { objectIsEmpty } from "../../../../utils/objectIsEmpty";

export const useOnboardingReportData = ()=>{
    const dispatch = useDispatch()
    const { success, error } = handleSnackbarStack();
    

    const onboardingReportData = useSelector(
        (state: any) => state.onboarding.onboardingReportData,
    );

    const handleDownloadReport = () => {
        if (!onboardingReportData || onboardingReportData.status !== 'PROCESSED') {
            return;
        }
    
        const fileUrl = onboardingReportData.data;
    
        if (!fileUrl) {
            return error('Houve um erro ao baixar o relatório de onboarding')
        }
    
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'relatorio_onboarding.pdf');
    
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    
        dispatch(OnboardingActions.clearResponse())
    
        success('Download concluído com sucesso!');
    };

    return {
        data: onboardingReportData,
        isEmpty: objectIsEmpty(onboardingReportData),
        statusCount: {
            allDownloading: onboardingReportData?.status === 'PROCESSING' ? 1 : 0,
            error: onboardingReportData?.cnabStatus === 'CANCELED' ? 1 : 0,
            inProgress: onboardingReportData?.status === 'PROCESSING' ? 1 : 0,
            irrorNotification:  onboardingReportData?.cnabStatus === 'CANCELED' ? true : false,
        },
        actions: {
            handleDownloadReport
        }
    }
}