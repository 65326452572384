import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        marginBottom: 65,
        '& .MuiTableContainer-root': {
            boxShadow: 'unset !important',
            border: '1px solid #F2F2F2'
        }
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8
    },
    confirmationInfoContainer: {
        gap: 20,
        padding: 20,
        backgroundColor: '#FFF',
        borderRadius: 6,
        border: '1px solid var(--dark-5, #F2F2F2)',
        boxShadow: '0px 3.85px 7.7px rgba(114, 114, 114, 0.1)'
    },
    confirmationInfoTitle: {
        fontWeight: 700,
        fontSize: 18,
        color: '#000'
    },
    confirmationInfoContent: {
        gap: 60,
    },
    confirmationInfoCard: {
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
        marginTop: 20,
    },
    confirmationInfoLabel: {
        color: '#808080',
        fontSize: 10,
        fontWeight: 500,
    },
    confirmationInfoValue: {
        fontWeight: 500,
        fontSize: 14,
        color: '#000'
    },
    title: {
        fontWeight: 700,
        fontSize: 34,
        color: '#1B181C'
    },
    subtitle: {
        fontWeight: 400,
        fontSize: 16,
        color: '#666666'
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
    column: {
        display: 'flex',
        flexDirection: 'column'
    },
    footer: {
        justifyContent: 'flex-end',
        gap: 12,
        marginTop: 'auto'
    },
    accordionTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 12,
    },
    accordionTitleColumContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    accordionTextContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4,
    },
    accordionTitleEvidence: {
        color: '#2E2E2E',
        fontWeight: 700,
    },
    accordionTitle: {
        color: '#2E2E2E',
    },
    itemsErrorsContainer: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        maxHeight: 280,
        gap: 16,
    },
    paginationContainer: {
        display: 'flex',
        justifyContent:'flex-end',
        marginTop: 16,

        '& .Mui-selected': {
            backgroundColor: '#52197F',
            color: 'white',
            fontWeight: 500,

            '&:hover':{
                backgroundColor: '#52197F',
            }
        }
    },
    skeletonAccordionContainer: {
        display: 'flex',
        height: 93,
        alignItems: 'center',
        position: 'relative'
    },
    skeletonAccordion: {
        height: 93,
        '&.MuiSkeleton-text':{
            transform: 'none'
        }
    },
    skeletonAccordionIcon: {
        width:40, 
        height: 40,
        borderRadius: 40,
        marginLeft: 20,
        '&.MuiSkeleton-text':{
            transform: 'none'
        }
    },
    
    cardsGroupContainer: {
        width: "100%",
        gap: 32,
        marginBottom: 32
    },
    cardContainer: {
        gap: 8,
        padding: 16,
        border: '1px solid #F2F2F2',
        borderRadius: 6,
        width: 'fit-content'
    },
    cardTitleContainer: {
        alignItems: 'center',
        gap: 8
    },
    cardIndex: {
        width: 4,
        height: 16,
        borderRadius: 6,
        backgroundColor: '#CA2317',
        '&.green':{
            backgroundColor: '#19797F'
        }
    },
    cardLabelContainer:{
        color: '#808080',
        fontWeight: 500,
        fontSize: 12,
        lineHeight: 0
    },
    cardLabelValue:{
        color: '#1A1A1A',
        fontWeight: 500,
        marginLeft: 13
    },
    errorContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: 16,
        '& > *:not(:last-child)': {
            borderBottom: '1px solid #E0E0E0',
            paddingBottom: 8,
            width: '100%'
        }   
    },
    errorItem: {
        gap: 16,
        flex: 1,
        '& > div:first-child': {
            backgroundColor: '#FAFAFA',
        }
    },
    currentErrorContainer: {
        paddingInline: 12,
        paddingBlock: 8,
        borderRadius: 6,
    },
    errorCardLabelContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
    },
    errorCardLabelText: {
        fontSize: '12px !important',
        fontWeight: 500,
        color: '#808080',
    },
    errorCardValue: {
        fontWeight: 500,
        color: '#1A1A1A',
    },
    nameContainer: {
        width: 250,
    },
    errorLabel: {
        color: '#808080',
    },
    errorIcon: {
        color: '#CA2317',
        marginBlock: 'auto',
    },
    infoValue: {
        fontWeight: 500,
        fontSize: '14px !important',
        color: '#000000',
    },
}));