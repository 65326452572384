import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../../services/api';
import convertBytesToBlobDowload from '../../../../utils/convertBytesToBlobDowload';
import convertBytesToBlobDownloadZip from '../../../../utils/convertBytesToBlobDownloadZip';
import { BaseConstructor } from '../../helpers/BaseConstructor';
import { handleSnackbarStack } from '../../../../utils/handleSnackbarStack';

const payrollDetailsBase = new BaseConstructor({
  extraStates: {
    payrollData: {},

    seeDetailsTable: {
      selectedAll: false,
      excludedLinesList: [],
      downloadReceiptsPercentage: null,
      downloadCancel: false,
    },

    centerCosts: [],
    sectors: [],

    downloadingNSUProof: [],
    allPayrollData: [],
    nsuFromProgressBar: null,

    employeesIncluded: {
      employeesTable: {
        employeesList: [],
        page: 0,
        perPage: 5,
        length: 0,
        isLoading: false,
      },

      downloadSingleReport: {
        selectedCpf: '',
        documentDownloaded: null,
        isLoading: false,
      },

      downloadMultipleReport: {
        selectedReports: [],
        documentsCompactedDownloaded: null,
        isLoading: false,
        successOnSendList: false,
        percentage: null,
      },
    },
  },
});

const fetchCenterCosts = createAsyncThunk(
  'payrollDetails/fetchCenterCosts',
  async (companyCode, { rejectWithValue }) => {
    try {
      const response = await api.get(`/companies/${companyCode}/locations`);
      const centerCosts = response.data.map(centerCost => ({
        value: centerCost.code,
        label: centerCost.description,
      }));
      return [{ value: 'all', label: 'Todos' }, ...centerCosts];
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.errors?.[0]?.errorDetail || 'Erro ao buscar os centros de custo'
      );
    }
  }
);

const fetchSectors = createAsyncThunk(
  'payrollDetails/fetchSectors',
  async (companyCode, { rejectWithValue }) => {
    try {
      const response = await api.get(`/companies/${companyCode}/stockings`);
      const sectors = response.data.map(centerCost => ({
        value: centerCost.code,
        label: centerCost.description,
      }));
      return [{value: 'all', label: 'Todos'}, ...sectors];
    } catch (error) {
      return rejectWithValue(error.response?.data?.errors?.[0]?.errorDetail || 'Erro ao buscar os setores');
    }
  }
);

const searchEmployeesIncluded = createAsyncThunk(
  'employeesIncluded/search',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { currentCompany } = getState().companies;
    const {
      payrollData: { cpf },
      employeesIncluded: { employeesTable },
    } = getState().payroll.payrollDetails;

    const requestOptions = {
      params: {
        page: employeesTable?.page,
        size: employeesTable?.perPage,
      },
    };

    return api
      .get(`/payroll/${cpf}/${currentCompany?.code}/payments`, requestOptions)
      .then(response => ({ data: response.data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      );
  },
);

const getDownloadSinglePaymentReport = createAsyncThunk(
  'employeesIncluded/getDownloadSinglePaymentReport',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { currentCompany } = getState().companies;
    const {
      payrollData: { cpf },
      employeesIncluded: { downloadSingleReport },
    } = getState().payroll.payrollDetails;

    const requestOptions = {
      params: {
        cpf,
      },
      responseType: 'blob',
    };

    return api
      .get(
        `/employeesPayments/receiptPayment/${currentCompany?.code}/${downloadSingleReport?.selectedNsu}`,
        requestOptions,
      )
      .then(response => ({
        data: convertBytesToBlobDowload(
          response.data,
          `recibo_pagamento_${cpf}`,
        ),
      }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      );
  },
);

const postSendMultipleCpf = createAsyncThunk(
  'employeesIncluded/postSendMultipleCpf',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { currentCompany } = getState().companies;
    const {
      payrollData: { cpf },
      employeesIncluded: { downloadMultipleReport },
    } = getState().payroll.payrollDetails;

    const selectedReportsNsuList = downloadMultipleReport?.selectedReports?.map(
      report => report?.nsu,
    );

    return api
      .post(
        `employeesPayments/receiptPayment/batch/${currentCompany?.code}/${cpf}/cpf`,
        selectedReportsNsuList,
      )
      .then(response => ({ data: response?.data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      );
  },
);

const getDownloadMultiplePaymentReport = createAsyncThunk(
  'employeesIncluded/getDownloadMultiplePaymentReport',
  async (payload, { getState, dispatch, rejectWithValue }) => {
    const { cpf } = getState().payroll.payrollDetails.payrollData;

    const requestOptions = {
      headers: {
        url: payload,
      },
      responseType: 'blob',
    };

    return api
      .get('employeesPayments/receiptPayment/file', requestOptions)
      .then(response => ({
        data: convertBytesToBlobDownloadZip(
          response.data,
          `recibos_pagamento_${cpf}`,
        ),
      }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      );
  },
);

const payrollDetailsSlice = createSlice({
  name: 'payrollDetails',
  initialState: payrollDetailsBase.initialState,
  reducers: {
    ...payrollDetailsBase.methods,
    setPayrollBasicData: (state, { payload }) => {
      state.payrollData = payload;
    },
    setEmployeesListPage: (state, { payload }) => {
      state.employeesIncluded.employeesTable.page = payload;
    },
    setEmployeesListPerPage: (state, { payload }) => {
      state.employeesIncluded.employeesTable.perPage = payload;
    },
    setEmployeesIncludedSelectedReports: (state, { payload }) => {
      state.employeesIncluded.downloadMultipleReport.selectedReports = payload;
    },
    setEmployeesIncludedSelectedCpf: (state, { payload }) => {
      state.employeesIncluded.downloadSingleReport.selectedCpf = payload;
    },
    setDownloadMultipleFilesPercentage: (state, { payload }) => {
      state.employeesIncluded.downloadMultipleReport.percentage = payload;
    },
    resetDownloadMultipleReportSelectedReports: state => {
      state.employeesIncluded.downloadMultipleReport.selectedReports = [];
    },
    resetDownloadMultipleReportSuccessOnSendList: state => {
      state.employeesIncluded.downloadMultipleReport.successOnSendList = false;
    },
    setSelectedAllFromSeeDetailsTable: (state, { payload }) => {
      state.seeDetailsTable.selectedAll = payload;
    },
    setExcludedLinesListFromSeeDetailsTable: (state, { payload }) => {
      state.seeDetailsTable.excludedLinesList = payload;
    },
    setDownloadReceiptsPercentageFromSeeDetailsTable: (state, { payload }) => {
      state.seeDetailsTable.downloadReceiptsPercentage = payload;
    },
    setDownloadCancelFromSeeDetailsTable: (state, { payload }) => { 
      state.seeDetailsTable.downloadCancel = payload;
    },
    setDownloadingNSUProof: (state, { payload }) => {
      const { nsu } = payload;

      const currentIndex = state.downloadingNSUProof.findIndex(item => item.nsu === nsu);

      if (currentIndex !== -1) {
        state.downloadingNSUProof[currentIndex] = payload;
      } else {
        state.downloadingNSUProof.push(payload);
      }
    },
    setDownloadingNSUProofPercentage: (state, { payload }) => {
      const { percentage, data } = payload;

      const currentIndex = state.downloadingNSUProof.findIndex(item => item.nsu === data);

      if (currentIndex !== -1) {
        state.downloadingNSUProof[currentIndex].downloadReceiptsPercentage = percentage;
      }
    },
    setDownloadingNSUProofError: (state, { payload }) => {

      const currentIndex = state.downloadingNSUProof.findIndex(item => item.nsu === payload);

      if (currentIndex !== -1) {
        state.downloadingNSUProof[currentIndex].error = true;
      }
    },
    setAllPayrollData: (state, { payload }) => {
      const { nsu } = payload;

      const currentIndex = state.allPayrollData.findIndex(item => item.nsu === nsu);

      if (currentIndex !== -1) {
        state.allPayrollData[currentIndex] = payload;
      } else {
        state.allPayrollData.push(payload);
      }
    },
    setNsuFromProgressBar: (state, { payload }) => {
      state.nsuFromProgressBar = payload;
    },
    removeNsuFromProgressBar: (state, { payload }) => {
      state.downloadingNSUProof = state.downloadingNSUProof.filter(item => item.nsu !== payload);
    },
    cleanupCostsAndSectors: state => {
      state.centerCosts = [];
      state.sectors = [];
    }

  },
  extraReducers: {
    // --------------------------------------------- SEARCH PAYMENT HISTORY -------------------------------------
    // Add extraReducers for fetchCenterCosts
    [fetchCenterCosts.pending]: (state) => {
      state.isLoadingCenterCosts = true;
    },
    [fetchCenterCosts.fulfilled]: (state, { payload }) => {
      state.isLoadingCenterCosts = false;
      state.centerCosts = payload; // Populate centerCosts with fetched data
    },
    [fetchCenterCosts.rejected]: (state, { payload }) => {
      state.isLoadingCenterCosts = false;
      handleSnackbarStack().error(payload);
    },

    // Add extraReducers for fetchSectors
    [fetchSectors.pending]: (state) => {
      state.isLoadingSectors = true;
    },
    [fetchSectors.fulfilled]: (state, { payload }) => {
      state.isLoadingSectors = false;
      state.sectors = payload; // Populate sectors with fetched data
    },
    [fetchSectors.rejected]: (state, { payload }) => {
      state.isLoadingSectors = false;
      handleSnackbarStack().error(payload);
    },

    [searchEmployeesIncluded.pending]: state => {
      state.employeesIncluded.employeesTable.isLoading = true;
    },
    [searchEmployeesIncluded.fulfilled]: (state, { payload }) => {
      state.employeesIncluded.employeesTable.isLoading = false;
      state.employeesIncluded.employeesTable.employeesList =
        payload.data.content;
      state.employeesIncluded.employeesTable.length = payload.data.totalSize;
    },

    [searchEmployeesIncluded.rejected]: (state, { payload }) => {
      state.employeesIncluded.employeesTable.isLoading = false;

      if (!payload)
        handleSnackbarStack().error(
          'Houve um erro ao buscar histórico de pagamento',
        );
      else handleSnackbarStack().error(payload);
    },

    // --------------------------------------------- DOWNLOAD SINGLE REPORT ----------------------

    [getDownloadSinglePaymentReport.pending]: state => {
      state.employeesIncluded.downloadSingleReport.isLoading = true;
    },
    [getDownloadSinglePaymentReport.fulfilled]: (state, { payload }) => {
      state.employeesIncluded.downloadSingleReport.isLoading = false;
      state.employeesIncluded.downloadSingleReport.selectedNsu = '';

      handleSnackbarStack().success('Recibo baixado com sucesso!');

      state.employeesIncluded.downloadSingleReport.documentDownloaded = payload;
    },

    [getDownloadSinglePaymentReport.rejected]: (state, { payload }) => {
      state.employeesIncluded.downloadSingleReport.isLoading = false;
      state.employeesIncluded.downloadSingleReport.selectedNsu = '';

      if (!payload)
        handleSnackbarStack().error('Houve um erro ao baixar o recibo');
      else handleSnackbarStack().error(payload);
    },

    // --------------------------------------------- SEND NSU LIST TO DOWNLOAD MULTIPLE REPORT ----------------------

    [postSendMultipleCpf.pending]: state => {
      state.employeesIncluded.downloadMultipleReport.isLoading = true;
    },
    [postSendMultipleCpf.fulfilled]: state => {
      state.employeesIncluded.downloadMultipleReport.isLoading = false;
      state.employeesIncluded.downloadMultipleReport.successOnSendList = true;
      state.employeesIncluded.downloadMultipleReport.selectedReports = [];

      handleSnackbarStack().success(
        'Lista de comprovantes enviada com sucesso! O download irá começar em breve',
      );
    },

    [postSendMultipleCpf.rejected]: (state, { payload }) => {
      state.employeesIncluded.downloadMultipleReport.isLoading = false;
      state.employeesIncluded.downloadMultipleReport.successOnSendList = false;
      state.employeesIncluded.downloadMultipleReport.selectedReports = [];

      if (!payload)
        handleSnackbarStack().error(
          'Houve um erro ao enviar a lista de comprovantes',
        );
      else handleSnackbarStack().error(payload);
    },

    // --------------------------------------------- DOWNLOAD MULTIPLE REPORT ----------------------

    [getDownloadMultiplePaymentReport.pending]: state => {
      state.employeesIncluded.downloadMultipleReport.isLoading = true;
    },
    [getDownloadMultiplePaymentReport.fulfilled]: (state, { payload }) => {
      state.employeesIncluded.downloadMultipleReport.isLoading = false;

      handleSnackbarStack().success('Download concluído com sucesso!');

      state.employeesIncluded.downloadMultipleReport.documentsCompactedDownloaded = payload;
    },

    [getDownloadMultiplePaymentReport.rejected]: (state, { payload }) => {
      state.employeesIncluded.downloadMultipleReport.isLoading = false;

      if (!payload)
        handleSnackbarStack().error('Houve um erro ao baixar os comprovantes');
      else handleSnackbarStack().error(payload);
    },
  },
});

const payrollDetailsReducer = payrollDetailsSlice.reducer;
const ActionsPayrollDetails = {
  ...payrollDetailsSlice.actions,
  searchEmployeesIncluded,
  getDownloadSinglePaymentReport,
  postSendMultipleCpf,
  getDownloadMultiplePaymentReport,
  fetchCenterCosts,
  fetchSectors,
};

export { payrollDetailsReducer, ActionsPayrollDetails };
