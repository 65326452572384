import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        flex: 1,
        marginBottom: 65,
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8
    },
    title: {
        fontWeight: 700,
        fontSize: 34,
        color: '#1B181C'
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
    column: {
        display: 'flex',
        flexDirection: 'column'
    },
    successFeedbackContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        gap: 40,
        marginBottom: 56,
        marginTop: 56,
        '& .evidence': {
            color: '#52197F',
            fontWeight: 700,
            marginRight: 4
        }
    },
    successFeedbackTitle: {
        fontSize: 26,
        fontWeight: 700,
        color: '#000'
    },
    successFeedbackSubtitle: {
        fontSize: 18,
        fontWeight: 500,
        color: '#000',
    },
    confirmationInfoContainer: {
        gap: 20,
        padding: 20,
        backgroundColor: '#FFF',
        borderRadius: 6,
        border: '1px solid  #F2F2F2',
    },
    confirmationInfoTitle: {
        fontWeight: 700,
        fontSize: 18,
        color: '#000'
    },
    confirmationInfoContent: {
        gap: 60,
    },
    confirmationInfoCard: {
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
        marginTop: 20,
    },
    confirmationInfoLabel: {
        color: '#808080',
        fontSize: 10,
        fontWeight: 500,
    },
    confirmationInfoValue: {
        fontWeight: 500,
        fontSize: 14,
        color: '#000'
    },
}));