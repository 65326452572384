import { useDispatch, useSelector } from "react-redux";
import { handleSnackbarStack } from "../../../../utils/handleSnackbarStack";
import { useGetCnabFileDownloadMutation, useStartCnabFileGenerationMutation } from "../../../../redux/store/FileData/fileData.api";
import { FileDataActions } from "../../../../redux/store/FileData";

export const useDownloadCnab = ()=> {
    const dispatch = useDispatch()
    const { success, error } = handleSnackbarStack();

    const [handleDownloadFile] = useGetCnabFileDownloadMutation();
    const [handleDownloadRetry] = useStartCnabFileGenerationMutation();

    const cnabDownloadData = useSelector(
        (state: any) => state.fileData.cnabDownloadData,
    );

    const onRetryDownload = async (nsu:number) => {
        await handleDownloadRetry({ nsu })
        .unwrap()
        .catch(err => error(err.message));
    };

    const onDownloadCnab = async (nsu:number, hasError:boolean) => {
        if (hasError) return;

        const file = cnabDownloadData.find((data: any) => data.nsu === nsu);

        await handleDownloadFile({ url: `${file.data}` })
        .unwrap()
        .then(res => {
            if (res) {
                const downloadUrl = window.URL.createObjectURL(new Blob([res]));

                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', `${file.data.substring(80)}`);

                document.body.appendChild(link);

                link.click();
                link.remove();
                success('Download concluído com sucesso!');
                dispatch(FileDataActions.removeCnabDownloadData({ nsu: nsu }));
            }
        })
        .catch(err => error(err.message));
    };

    const handleRemoveCnabDownload = (nsu:number) => {
        dispatch(FileDataActions.removeCnabDownloadData({ nsu: nsu }));
    }

    return {
        data: cnabDownloadData,
        isEmpty: !cnabDownloadData?.length,
        statusCount: {
            allDownloading: cnabDownloadData?.filter((e:any)=>e.status === 'PROCESSING')?.length,
            error: cnabDownloadData?.filter((e:any)=>e.status === 'CANCELED')?.length,
            inProgress: cnabDownloadData?.filter((e:any)=>e.status === 'PROCESSING')?.length,
            irrorNotification: cnabDownloadData?.some((e:any)=>e.status === 'CANCELED'),
        },
        actions: {
            onDownloadCnab,
            onRetryDownload,
            handleRemoveCnabDownload
        }
    }
}