import React, { useState, useEffect } from 'react';
import { differenceInHours, parseISO } from 'date-fns';

import {
  MuiThemeProvider,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Tooltip,
  Paper,
  CircularProgress,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import moment from 'moment';
import { useSelector } from 'react-redux';
import convertBrazilianDate from '../../../../utils/convertBrazilianDate';
import KebabMenu from '../../../../pages/PixKeys/components/KebabMenu';

import colorStatus from './ColorStatus';
import EnhancedTableHead from './EnhancedTableHead';
import truncateString from '../../../../utils/truncateString';

import { ReactComponent as EyesIcon } from '../../../../assets/eyesIcon.svg';
import { ReactComponent as OptionIcon } from '../../../../assets/optionIcon.svg';
import FakeRowTable from '../../../CashIn/ConsultCashIn/UI/FakeRowTable';

import { hasAuthorization, profiles } from '../../../../utils/hasAuthorization';
import PurpleCheckbox from '../../../../components/Checkbox/PurpleCheckbox';
import Badge from '../../../../components/Badge';
import { styles } from './styles';

import { useCreateRegenQrCodePixMutation } from '../../../../redux/store/CashIn';
import { getCurrentCompanieData } from '../../../../utils/getCurrentCompanieData';
import { show } from '@ebay/nice-modal-react';
import { downloadReceiptTypesToModal } from '../consts';

export default ({
  theme,
  classes,
  classesHead,
  pageSize,
  totalSize,
  employeesPayments,
  currentPage,
  handleChangePage,
  handleChangeRowsPerPage,
  TablePaginationActions,
  onDetailsClicked,
  onCancelClicked,
  onRescheduleClicked,
  order,
  orderBy,
  selected,
  setSelected,
  handleRequestSort,
  rows,
  onBatchPaymentClick,
  immediateReleasePayroll,
  setErrorAuthorization,
  loadingFilter,
  regeneratePayment,
  handleClickQrCode,
  isLoadingQrCode,
  isSuccessQrCode,
  downloadReceiptOptions,
  setOpenModalDownloadAllReceipts,
}) => {
  const [modalSelected, setModalSelected] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRowSeleced, setCurrentRowSeleced] = useState(null);
  
  useEffect(() => {
    sessionStorage.setItem('selectedPayments', JSON.stringify(selected));
  }, [selected]);

  const isSelected = row =>
    selected
      .map(function(e) {
        return e.nsu;
      })
      .indexOf(row?.nsu) !== -1;

  const ACCESS_PROFILES = JSON.parse(sessionStorage.getItem('accessProfile'));

  function closeMenuWhenClicked() {
    setModalSelected(null);
  }

  function onSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = employeesPayments.map(row => {
        if (row.status === 'Aguardando Autorização') {
          return row;
        }
      });

      sessionStorage.setItem(
        'selectedPayments',
        JSON.stringify(newSelecteds.filter(item => item)),
      );
      setSelected(newSelecteds.filter(item => item));
      return;
    }

    sessionStorage.setItem('selectedPayments', JSON.stringify([]));
    setSelected([]);
  }

  function handleClickCheckbox(event, row) {
    const selectedIndex = selected
      .map(function(event) {
        return event.nsu;
      })
      .indexOf(row.nsu);
    let newSelected = [];
    const auxArray = selected;

    if (selectedIndex === -1) {
      newSelected = [...selected, JSON.parse(event.target.value)];
      setSelected(newSelected);
    } else {
      auxArray.splice(selectedIndex, 1);
      sessionStorage.setItem('selectedPayments', JSON.stringify(auxArray));
      setSelected([...auxArray]);
    }
  }

  function handleDisableIconButton(row) {
    return (
      !hasAuthorization(ACCESS_PROFILES, [profiles.AUTHORIZER]) ||
      row.status !== 'Aguardando Autorização' ||
      selected.length > 0
    );
  }

  function handleDisableOptionsButton(row) {
    return (
      !hasAuthorization(ACCESS_PROFILES, [
        profiles.PERSONAL_DEPARTMENT,
        profiles.AUTHORIZER,
      ]) ||
      row.status === 'Pagamento Incompleto' ||
      selected.length > 0
    );
  }

  const isExpiredDate = creationDate => {
    const now = new Date();
    const creationDateParsed = parseISO(creationDate);

    const hoursDifference = differenceInHours(now, creationDateParsed);

    return hoursDifference > 24;
  };

  const kebabActions = {
    see_key_details: () => console.log("nada"),
  }

  const handleSelectKebabOption = (optionKebab, currentPix) => {
    const action = kebabActions[optionKebab.key];
    if (action) {
      action(currentPix);
    } else {
      console.log(`No action found for ${optionKebab.key}`);
    }
  };

  const itemsKebab = [
    {key: 'see_key_details', label:`Baixar todos`, value: downloadReceiptTypesToModal.ALL, isDisabled: false},
    {key: 'see_key_details', label:`Baixar por centro de custo`,  value: downloadReceiptTypesToModal.COST, isDisabled: false},
    {key: 'see_key_details', label:`Baixar por setor`,  value: downloadReceiptTypesToModal.SECTOR, isDisabled: false},
  ]

  function getKebabSelected(currentSelected, currentRowSeleced) {
    downloadReceiptOptions(currentRowSeleced, currentSelected.value);
    setOpenModalDownloadAllReceipts(true);
  }

  function getOptions() {
    const isExpired = isExpiredDate(currentRowSeleced?.creationDate);
    const isPix = currentRowSeleced?.paymentType === 'Pix';

    switch (currentRowSeleced.status) {
      case 'Aguardando Autorização':
        return [
          <MenuItem
            disabled={
              !hasAuthorization(ACCESS_PROFILES, [
                profiles.ADMIN,
                profiles.PERSONAL_DEPARTMENT,
                profiles.FINANCIAL,
                profiles.AUTHORIZER,
              ])
            }
            onClick={() => {
              closeMenuWhenClicked(
                onRescheduleClicked(
                  currentRowSeleced.scheduleDate,
                  currentRowSeleced.nsu,
                  currentRowSeleced.paymentType,
                  currentRowSeleced,
                ),
              );
            }}
          >
            <span className={classes.menuListItem}>Alterar Data</span>
          </MenuItem>,

          <MenuItem
            disabled={
              !hasAuthorization(ACCESS_PROFILES, [
                profiles.ADMIN,
                profiles.PERSONAL_DEPARTMENT,
                profiles.FINANCIAL,
                profiles.AUTHORIZER,
              ])
            }
            onClick={() =>
              closeMenuWhenClicked(
                onCancelClicked(currentRowSeleced.nsu, currentRowSeleced),
              )
            }
          >
            <span className={classes.menuListItem}>Desfazer Agendamento</span>
          </MenuItem>,

          <MenuItem
            style={styles.menuItemRegenerate}
            disabled={
              !hasAuthorization(ACCESS_PROFILES, [
                profiles.ADMIN,
                profiles.PERSONAL_DEPARTMENT,
                profiles.FINANCIAL,
                profiles.AUTHORIZER,
              ])
            }
            onClick={() => {
              closeMenuWhenClicked(regeneratePayment(currentRowSeleced));
            }}
          >
            <span
              style={styles.nameRegenerate}
              className={classes.menuListItem}
            >
              Regerar pagamento
            </span>
          </MenuItem>,
          <MenuItem
            style={styles.menuItemRegenerate}
            disabled={
              !hasAuthorization(ACCESS_PROFILES, [
                profiles.ADMIN,
                profiles.PERSONAL_DEPARTMENT,
                profiles.FINANCIAL,
                profiles.AUTHORIZER,
              ])
            }
            onClick={() => {
              handleClickQrCode(currentRowSeleced);
            }}
          >
            <span
              style={styles.nameRegenerate}
              className={classes.menuListItem}
            >
              {isLoadingQrCode && (
                <CircularProgress
                  size={20}
                  className={classes.circularLoading}
                />
              )}
              {currentRowSeleced.qrcodeExpired
                ? 'Regerar QR Code Pix'
                : 'Visualizar QR Code Pix'}
            </span>
            <Badge title="Novo" />
          </MenuItem>,
        ];

      case 'Saldo Insuficiente':
        return [
          <MenuItem
            disabled={
              !hasAuthorization(ACCESS_PROFILES, [
                profiles.ADMIN,
                profiles.PERSONAL_DEPARTMENT,
                profiles.FINANCIAL,
                profiles.AUTHORIZER,
              ])
            }
            onClick={() => {
              closeMenuWhenClicked(
                onRescheduleClicked(
                  currentRowSeleced.scheduleDate,
                  currentRowSeleced.nsu,
                  currentRowSeleced.paymentType,
                  currentRowSeleced,
                ),
              );
            }}
          >
            <span className={classes.menuListItem}>Alterar Data</span>
          </MenuItem>,

          <MenuItem
            style={styles.menuItemRegenerate}
            disabled={
              !hasAuthorization(ACCESS_PROFILES, [
                profiles.ADMIN,
                profiles.PERSONAL_DEPARTMENT,
                profiles.FINANCIAL,
              ])
            }
            onClick={() => {
              closeMenuWhenClicked(regeneratePayment(currentRowSeleced));
            }}
          >
            <span
              style={styles.nameRegenerate}
              className={classes.menuListItem}
            >
              Regerar pagamento
            </span>
          </MenuItem>,
        ];

      case 'Autorizada':
        return [
          <MenuItem
            disabled={
              !hasAuthorization(ACCESS_PROFILES, [
                profiles.ADMIN,
                profiles.PERSONAL_DEPARTMENT,
                profiles.FINANCIAL,
                profiles.AUTHORIZER,
              ])
            }
            onClick={() => {
              closeMenuWhenClicked(onCancelClicked(currentRowSeleced.nsu));
            }}
          >
            <span className={classes.menuListItem}>Desfazer Agendamento</span>
          </MenuItem>,

          <Divider
            style={{
              display:
                currentRowSeleced.paymentType === 'Rescisão'
                  ? 'none'
                  : 'initial',
            }}
          />,

          <MenuItem
            disabled={!hasAuthorization(ACCESS_PROFILES, [profiles.AUTHORIZER])}
            onClick={() =>
              closeMenuWhenClicked(
                immediateReleasePayroll(
                  currentRowSeleced.status,
                  currentRowSeleced.nsu,
                ),
              )
            }
          >
            <span className={classes.menuListItem}>Liberação Imediata</span>
          </MenuItem>,

          <MenuItem
            style={styles.menuItemRegenerate}
            disabled={
              !hasAuthorization(ACCESS_PROFILES, [
                profiles.ADMIN,
                profiles.PERSONAL_DEPARTMENT,
                profiles.FINANCIAL,
                profiles.AUTHORIZER,
              ])
            }
            onClick={() => {
              closeMenuWhenClicked(regeneratePayment(currentRowSeleced));
            }}
          >
            <span
              style={styles.nameRegenerate}
              className={classes.menuListItem}
            >
              Regerar pagamento
            </span>
          </MenuItem>,
        ];

      case 'Expirada':
        return [
          <MenuItem
            disabled={
              !hasAuthorization(ACCESS_PROFILES, [
                profiles.ADMIN,
                profiles.PERSONAL_DEPARTMENT,
                profiles.FINANCIAL,
              ])
            }
            onClick={() => {
              closeMenuWhenClicked(
                onRescheduleClicked(
                  currentRowSeleced.scheduleDate,
                  currentRowSeleced.nsu,
                  currentRowSeleced.paymentType,
                  currentRowSeleced,
                ),
              );
            }}
          >
            <span className={classes.menuListItem}>Alterar Data</span>
          </MenuItem>,

          <MenuItem
            style={styles.menuItemRegenerate}
            disabled={
              !hasAuthorization(ACCESS_PROFILES, [
                profiles.ADMIN,
                profiles.PERSONAL_DEPARTMENT,
                profiles.FINANCIAL,
              ])
            }
            onClick={() => {
              closeMenuWhenClicked(regeneratePayment(currentRowSeleced));
            }}
          >
            <span
              style={styles.nameRegenerate}
              className={classes.menuListItem}
            >
              Regerar pagamento
            </span>
          </MenuItem>,
        ];

      case 'Paga Imediata':
      case 'Paga':
      case 'Cancelada':
        return [
          <MenuItem
            style={styles.menuItemRegenerate}
            disabled={
              !hasAuthorization(ACCESS_PROFILES, [
                profiles.ADMIN,
                profiles.PERSONAL_DEPARTMENT,
                profiles.FINANCIAL,
              ])
            }
            onClick={() => {
              closeMenuWhenClicked(regeneratePayment(currentRowSeleced));
            }}
          >
            <span
              style={styles.nameRegenerate}
              className={classes.menuListItem}
            >
              Regerar pagamento
            </span>
          </MenuItem>,

          <MenuItem
            style={styles.menuItemRegenerate}
            disabled={
              !hasAuthorization(ACCESS_PROFILES, [
                profiles.ADMIN,
                profiles.PERSONAL_DEPARTMENT,
                profiles.FINANCIAL,
                profiles.AUTHORIZER,
              ])
            }
          >
            <KebabMenu 
              items={itemsKebab}
              textButton='Baixar comprovantes'
              onSelectItem={(itemFromKebab)=>getKebabSelected(itemFromKebab, currentRowSeleced)}
              anchorOrigin={{
                vertical: 'botton',
                horizontal: 'left', // Submenu appears to the right of parent menu
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right', // Align the submenu's left edge with parent menu's right edge
              }}
              fromPayRoll
            />
          </MenuItem>,
        ];

      default:
        return [
          <MenuItem
            disabled={
              !hasAuthorization(ACCESS_PROFILES, [
                profiles.ADMIN,
                profiles.PERSONAL_DEPARTMENT,
                profiles.FINANCIAL,
              ])
            }
            onClick={() => {
              closeMenuWhenClicked(onDetailsClicked(currentRowSeleced));
            }}
          >
            <span className={classes.menuListItem}>Visualizar Detalhes</span>
          </MenuItem>,

          <MenuItem
            style={styles.menuItemRegenerate}
            disabled={
              !hasAuthorization(ACCESS_PROFILES, [
                profiles.ADMIN,
                profiles.PERSONAL_DEPARTMENT,
                profiles.FINANCIAL,
              ])
            }
            onClick={() => {
              closeMenuWhenClicked(regeneratePayment(currentRowSeleced));
            }}
          >
            <span
              style={styles.nameRegenerate}
              className={classes.menuListItem}
            >
              Regerar pagamento
            </span>
          </MenuItem>,
        ];
    }
  }

  const handleClick = (event, row) => {
    setCurrentRowSeleced(row);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onclickStatus = (
    status,
    nsu,
    paymentType,
    scheduleDate,
    row,
    event,
  ) => {
    setModalSelected();
    setErrorAuthorization(false);
  };

  useEffect(() => {
    !!isSuccessQrCode && handleClose();
  }, [isSuccessQrCode]);

  return (
    <MuiThemeProvider theme={theme}>
      <TableContainer
        component={Paper}
        className={selected.length > 0 ? classes.tableContainer : null}
      >
        <Table className={classes.table}>
          <EnhancedTableHead
            classes={classesHead}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            numSelected={selected?.length}
            onSelectAllClick={onSelectAllClick}
          />
          <TableBody>
            {loadingFilter ? (
              <FakeRowTable />
            ) : (
              employeesPayments.map((row, index) => {
                const isItemSelected = isSelected(row);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.nsu}
                  >
                    <TableCell padding="checkbox">
                      <PurpleCheckbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                        onClick={event => handleClickCheckbox(event, row)}
                        value={JSON.stringify(row)}
                        disabled={row.status !== 'Aguardando Autorização'}
                      />
                    </TableCell>
                    <TableCell
                      padding="none"
                      scope="row"
                      align="left"
                      className={classes.statusCell}
                    >
                      {colorStatus(row.status)}
                    </TableCell>
                    <TableCell align="left" className={classes.defaultCell}>
                      <Tooltip
                        title={row.paymentType}
                        disableHoverListener={row.paymentType?.length < 19}
                      >
                        <span>{truncateString(row.paymentType, 20)}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="left" className={classes.defaultCell}>
                      {row.nsu}
                    </TableCell>
                    <TableCell align="left" className={classes.dateCell}>
                      {convertBrazilianDate(row.importDate)} às{' '}
                      {moment(row.importDate).format('LT')}
                    </TableCell>
                    <TableCell align="left" className={classes.dateCell}>
                      {row.scheduleDate
                        ? `${`${convertBrazilianDate(
                            row.scheduleDate,
                          )} às ${moment(row.scheduleDate).format('LT')}`}`
                        : '-'}
                    </TableCell>
                    <TableCell align="left" className={classes.defaultCell}>
                      {row.totalAmount
                        ? row.totalAmount.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            style: 'currency',
                            currency: 'BRL',
                          })
                        : '(Em branco)'}
                    </TableCell>

                    <TableCell align="center" className={classes.defaultCell}>
                      <div style={{ display: 'flex' }}>
                        <IconButton
                          onClick={() => onDetailsClicked(row, row.nsu)}
                        >
                          <Tooltip title="Visualizar detalhes">
                            <EyesIcon />
                          </Tooltip>
                        </IconButton>

                        <IconButton
                          disabled={handleDisableIconButton(row)}
                          className={classes.checkIcon}
                          onClick={() => {
                            closeMenuWhenClicked();
                            onBatchPaymentClick(row);
                          }}
                        >
                          <Tooltip title="Autorizar pagamento">
                            <CheckIcon />
                          </Tooltip>
                        </IconButton>

                        {row.status === 'Pagamento Incompleto' ? (
                          <IconButton
                            style={{ opacity: '.3', cursor: 'initial' }}
                            disabled
                          >
                            <OptionIcon />
                          </IconButton>
                        ) : row.status === 'Autorizada Imediata' ? (
                          <IconButton
                            style={{ opacity: '.3', cursor: 'initial' }}
                          >
                            <Tooltip title="Folha liberada, não é possível realizar alterações.">
                              <OptionIcon disabled />
                            </Tooltip>
                          </IconButton>
                        ) : (
                          <IconButton
                            style={
                              selected.length > 0
                                ? { opacity: '.3', cursor: 'initial' }
                                : { color: '#5C2483' }
                            }
                            disabled={handleDisableOptionsButton(row)}
                            className={classes.checkIcon}
                            onClick={event => handleClick(event, row)}
                          >
                            <Tooltip title="Mais opções">
                              <OptionIcon />
                            </Tooltip>
                          </IconButton>
                        )}

                        <Menu
                          style={{
                            transform: 'translateX(-10px)',
                            boxShadow:'0px 0px 6px 0px rgb(209 209 209 / 62%)',
                          }}
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={!!anchorEl}
                          onClose={handleClose}
                        >
                          {currentRowSeleced && getOptions()}
                        </Menu>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                count={totalSize}
                rowsPerPage={pageSize}
                page={currentPage}
                labelRowsPerPage="Linhas por página"
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </MuiThemeProvider>
  );
};
