import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
  TextField,
  Menu,
  MenuItem,
  MuiThemeProvider,
  Hidden,
  InputAdornment,
  Tooltip,
  Badge,
  Box,
  Grid,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import MenuIcon from '@material-ui/icons/Menu';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Search from '@material-ui/icons/Search';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';

import SpringModal from '../Modal';
import SuccessModal from '../SuccessModal';
import AlreadyUploadModal from '../PrimaryModal';
import LogoutModal from '../PrimaryModal';
import StepTopBar from '../StepTopBar';

import { api } from '../../services/api';
import { pusherEvents, pusherStatus } from '../../enums/pusher';

import truncateString from '../../utils/truncateString';
import { pusher } from '../../utils/pusher';
import { isMobile } from '../../utils/breakpoints';
import textCapitalize from '../../utils/textCapitalize';

import { ReactComponent as SuitcaseIcon } from '../../assets/SuitcaseIcon.svg';
import { ReactComponent as UploadButton } from '../../assets/buttonUpload.svg';
import { ReactComponent as DownloadButton } from '../../assets/download-button.svg';

import { useConfirmation } from '../../context/ModalProcessBlock';
import { ActionsCompanies } from '../../redux/store/Companies';
import { EmployeesFileSliceActions } from '../../redux/store/Employees/FileUpload/slice';
import { CostCenterFileSliceActions } from '../../redux/store/CostAndSector/FileUpload/slice';
import { addDownloadCnabReturnFilePusher } from '../../redux/store/Payroll/DownloadMultipleCnab240';
import useNotificationMenu from './hooks/useNotificationMenu';

import useStyles, { theme } from './styles';
import styles from './styles.module.css';
import BankDataModal from './BankDataModal';
import formatCnpj from '../../utils/formatCnpj';
import { getAccountSCD } from '../../utils/getAccountSCD';
import DownloadingProgress from './DownloadingProgress';
import DownloadingProgressNew from './DownloadingProgressNew/index';
import DownloadingProgressNew2 from './DownloadingProgressNew2/index';
import { ActionsPayrollDetails } from '../../redux/store/Payroll/PayrollDetails';
import { handleSnackbarStack } from '../../utils/handleSnackbarStack';
import usePaymentsNsuDownload from './hooks/usePaymentsNsuDownload';

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export default function HeaderBar({ open, handleDrawerOpen }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { allowTransition, confirm } = useConfirmation();
  const { error } = handleSnackbarStack();


  const {
    PROCESSED_CREATE_LOCATION_FILE_SUCCESS,
    PROCESSED_CREATE_LOCATION_FILE_ERROR,

    LOCATION_CREATE_FILE_FINISHED,
    LOCATION_CREATE_FILE_ERROR,
    UNPROCESSABLE_FILE,
    PROCESSED_UPDATE_EMPLOYEE_FILE_SUCCESS,
    PROCESSED_UPDATE_EMPLOYEE_FILE_ERROR,
    PROCESSED_PAYROLL_SUCCESS,
    PROCESSED_PAYROLL_ERROR,
    PROCESSED_EMPLOYEES_SUCCESS,
    PROCESSED_EMPLOYEES_ERROR,
    PROCESSED_PAYCHECK_SUCCESS,
    PROCESSED_PAYCHECK_ERROR,
    PROCESSED_INSURANCES_ERROR,
    PROCESSED_INSURANCES_SUCCESS,
    PROCESSING,
    PROCESSED,
    CANCELED,
  } = pusherStatus;

  const uploadCanceled = useSelector(state => state.importFiles.uploadCanceled);
  const employeesFileExportStatus = useSelector(state => state?.employeesFileSlice.fileExportStatus);
  const costCenterFile = useSelector(state => state?.costCenterFileSlice.fileExportStatus);
  const { accountSelected, accounts } = useSelector(state => state.account);
  const { currentCompany, companies: companiesData } = useSelector(state => state.companies);
  const {downloadingNSUProof} = useSelector(state => state.payroll.payrollDetails);
  
  const currentCompanyAllData = companiesData.find(company => company.cnpj === currentCompany.cnpj);

  const companies = JSON.parse(sessionStorage.getItem('companies')) || [];
  const companyIndex = sessionStorage.getItem('currentCompanyIndex') || 0;
  const userStorage = sessionStorage.getItem('userJobRole');
  const codeCompany = sessionStorage.getItem('currentCompanyCode');
  const userEncodedKey = JSON.parse(userStorage)?.userEncodedKey
  
  const ACCESS_PROFILES = JSON.parse(sessionStorage.getItem('accessProfile'));
  const useTopBar = JSON.parse(sessionStorage.getItem('useTopBar'));
  const FORMATED_USER_NAME = textCapitalize(JSON.parse(userStorage)?.name);
  const COMPANY_NAME = companies[companyIndex || 0]?.name;

  const uploadDataPayroll = JSON.parse(
    localStorage.getItem(`uploadDataPayroll-${codeCompany}`),
  );

  const uploadDataEmployees = JSON.parse(
    localStorage.getItem(`uploadDataEmployees-${codeCompany}`),
  );

  const uploadDataPaycheck = JSON.parse(
    localStorage.getItem(`uploadDataPaycheck-${codeCompany}`),
  );

  const uploadDataInsurance = JSON.parse(
    localStorage.getItem(`uploadDataInsurance-${codeCompany}`),
  );

  const [uploadData, setUploadData] = useState(uploadDataPayroll || null);

  const [paycheckData, setPaycheckData] = useState(uploadDataPaycheck || null);

  const [insuranceData, setInsuranceData] = useState(
    uploadDataInsurance || null,
  );

  const [employeeData, setEmployeeData] = useState(uploadDataEmployees || null);

  const [openModal, setOpenModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalIndex, setModalIndex] = useState(0);
  const [modalCompanyCode, setModalCompanyCode] = useState('');
  const [modalCompanyName, setModalCompanyName] = useState('');
  const [modalCompanyCnpj, setModalCompanyCnpj] = useState('');
  const [openModalLogout, setOpenModalLogout] = useState(false);
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);
  const [errorAlreadyPayroll, setErrorAlreadyPayroll] = useState('');
  const [alreadyUploadModal, setAlreadyUploadModal] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [companyNameSearch, setCompanyNameSearch] = useState('');
  const [headerBlockPrompt, setHeaderBlockPrompt] = useState(false);
  const [anchorElUserMenu, setAnchorElUserMenu] = useState(null);
  const [openDataBankModal, setOpenDataBankModal] = useState(false);

  const uploadArray = [
    uploadDataPayroll,
    uploadDataEmployees,
    uploadDataPaycheck,
    uploadDataInsurance,
  ].filter(item => item);

  const handlePusherData = usePaymentsNsuDownload();

  const hasMultipleCompanies = useMemo(() => Boolean(companies.length > 1), [
    companies,
  ]);

  const accountSCD = getAccountSCD(accounts, 'SCD')

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, company) => {
    const companieValue = companies
      .map(item => item.code)
      .indexOf(company.code);

    if (company.code === codeCompany) {
      handleClose();
    } else {
      event.preventDefault();
      setOpenModal(true);
      setModalIndex(companieValue);
      setModalCompanyCode(company.code);
      setModalCompanyName(company.name);
      setModalCompanyCnpj(company.cnpj);
    }
  };

  const getConfigCompany = codeCompany => {
    api.get(`companies/${codeCompany}/search`).then(({ data }) => {
      const zeroProduct = data?.zeroProduct;
      const pontoGoProduct = data?.pontoGoProduct;
      const pontoGoActivationDate = data?.pontoGoCompany
        ? data?.pontoGoCompany?.pontoGoActivationDate
        : data?.pontoGoCompany;
      const emailNfe = data?.generalData
        ? data.generalData?.emailNfe
        : data?.generalData;

      const onboardEnabled = data?.onboardEnabled;
      const canGenerateCard = data?.canGenerateCard;

      sessionStorage.setItem('zeroProduct', zeroProduct || null);
      sessionStorage.setItem('pontoGoProduct', pontoGoProduct);
      sessionStorage.setItem('pontoGoActivationDate', pontoGoActivationDate);
      sessionStorage.setItem('emailNfe', emailNfe);
      sessionStorage.setItem('onboardEnabled', onboardEnabled);
      sessionStorage.setItem(
        'canGenerateCard',
        canGenerateCard === undefined ? false : canGenerateCard,
      );

      dispatch({
        type: 'SITUATION_POINT_PLAN',

        data: {
          situationPointPlan: pontoGoProduct,
          pontoGoActivationDate,
        },
      });
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalIndex(-1);
    setModalCompanyCode('');
    setAnchorEl(null);
  };

  const handleShowBalance = () => {
    pusher.unsubscribe(codeCompany);
    if(!!userEncodedKey) { pusher.unsubscribe(userEncodedKey) };

    setOpenModal(false);
    setAnchorEl(null);
    dispatch(EmployeesFileSliceActions.clearFileExportStatus())
    dispatch(EmployeesFileSliceActions.clearReportFeedback())
    dispatch(
      ActionsCompanies.setCurrentCompany({
        index: modalIndex,
        code: modalCompanyCode,
        name: modalCompanyName,
        cnpj: modalCompanyCnpj,
      }),
    );

    dispatch(CostCenterFileSliceActions.clearFileExportStatus())
    dispatch(CostCenterFileSliceActions.clearReportFeedback())

    sessionStorage.setItem(
      'accessProfile',
      JSON.stringify(companies[modalIndex].roles),
    );
    sessionStorage.setItem('currentCompanyCode', modalCompanyCode);
    sessionStorage.setItem('currentCompanyIndex', modalIndex);
    sessionStorage.removeItem('selectedEmployees');
    sessionStorage.removeItem('insuranceEmployeesTable');
    sessionStorage.removeItem('selectedPayments');

    history.push('/');
  };

  const handleChangeCompanyNameSearch = event => {
    setCompanyNameSearch(event.target.value);
  };

  const renderExpandIcon = () => {
    return anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />;
  };

  const renderIconButton = () => {
    if (hasMultipleCompanies) {
      return (
        <IconButton
          onClick={handleClick}
          className={isMobile ? classes.mobileIconStyle : classes.iconStyle}
        >
          {renderExpandIcon()}
        </IconButton>
      );
    }
    return null;
  };

  const systemLogout = () => {
    history.push('/logout');
  };

  const confirmLogout = () => {
    setOpenModalLogout(true);
    setAnchorElUserMenu(null);
  };

  const handleClickNotification = event => {
    setAnchorElNotifications(event.currentTarget);
  };

  const handleCloseNotification = () => {
    setAnchorElNotifications(null);
  };

  const handleClickItem = () => {
    const data = uploadDataPayroll?.data;
    const fileCode = uploadDataPayroll?.data?.fileEncodedKey;

    if (uploadDataPayroll?.status === PROCESSED_PAYROLL_ERROR) {
      if (uploadDataPayroll?.data) {
        localStorage.removeItem(`uploadDataPayroll-${codeCompany}`);
        localStorage.removeItem('fileItem');
        goToProcessingView(data);
      } else {
        goToErrorView(data);
      }
    } else if (uploadDataPayroll?.status === PROCESSED_PAYROLL_SUCCESS) {
      api
        .get(`/files/employeespayments/notAlreadyExistsPayroll/${fileCode}`)
        .then(() => goToSuccessView(data))
        .catch(
          ({
            response: {
              data: { errors },
            },
          }) => {
            setErrorAlreadyPayroll(errors[0]?.errorsDescription.message);

            if (errors[0]?.errorReason === 'FILE_ALREADY_PROCESSED') {
              localStorage.removeItem(`uploadDataPayroll-${codeCompany}`);
              localStorage.removeItem('fileItem');
              handleCloseNotification();
              setAlreadyUploadModal(true);
            }
          },
        );
    } else {
      goToProcessingView();
    }
  };

  const handleClickItemEmployees = () => {
    const data = uploadDataEmployees?.data || {};
    const status = uploadDataEmployees?.status;
    const processId = uploadDataEmployees?.processId;

    sessionStorage.setItem('employeesRegistrationData', JSON.stringify(data));

    if (status === PROCESSING) {
      handleCloseNotification();
      history.push('/employees/register-batch/menu/select-company/excel-form');
      return;
    }

    if (data?.errorReason) {
      handleCloseNotification();
      history.push('/employees/register-batch/menu/select-company/excel-form');
      localStorage.removeItem(`uploadDataEmployees-${codeCompany}`);
      return;
    }

    api
      .get(`/files/employees/result/${processId}`)
      .then(({ data }) => {
        sessionStorage.setItem('importEmployeesErrors', JSON.stringify(data));
      })
      .finally(() => {
        localStorage.removeItem(`uploadDataEmployees-${codeCompany}`);
        localStorage.removeItem('fileItem');
        handleCloseNotification();
        history.push(
          `/employees/register-batch/menu/select-company/excel-form/employee-list`,
        );
      });
  };

  const goToSuccessView = data => {
    sessionStorage.setItem(
      'importFileData',
      JSON.stringify(Object.assign(data, { isFileImport: true })),
    );
    localStorage.removeItem(`uploadDataPayroll-${codeCompany}`);
    setAnchorElNotifications(null);
    history.push('/payroll/menu/info-payroll/register-batch/data-review');
  };

  const goToErrorView = () => {
    const processId = uploadDataPayroll?.processId;

    api.get(`/files/employeespayments/errors/${processId}`).then(({ data }) => {
      sessionStorage.setItem('importFileErros', JSON.stringify(data));
      localStorage.removeItem(`uploadDataPayroll-${codeCompany}`);
      setAnchorElNotifications(null);
      history.push('/payroll/menu/info-payroll/register-batch/list-error');
    });
  };

  const goToProcessingView = () => {
    history.push('/payroll/menu/info-payroll/register-batch');
    setAnchorElNotifications(null);
  };

  const onClickNotification = item => {
    handleCloseNotification();

    if (item === 'paycheck') {
      const { status, fileId } = paycheckData;

      if (
        status === PROCESSED_PAYCHECK_SUCCESS ||
        status === PROCESSED_PAYCHECK_ERROR
      ) {
        history.push(`/paycheck/paycheck-data-review/${fileId}`);
      } else {
        history.push('/paycheck/paycheck-import');
      }

      localStorage.removeItem(`uploadDataPaycheck-${codeCompany}`);
      setPaycheckData(null);
    }

    if (item === 'insurance') {
      const { status, fileId } = insuranceData;

      if (
        status === PROCESSED_INSURANCES_SUCCESS ||
        status === PROCESSED_INSURANCES_ERROR
      ) {
        history.push(
          `/insurance-management/batch-registration/menu/validate/review/${fileId}`,
        );
      } else {
        history.push('/insurance-management/batch-registration/menu/validate');
      }

      localStorage.removeItem(`uploadDataInsurance-${codeCompany}`);
      setInsuranceData(null);
    }
  };

  const onConfirmAlreadyUploadModal = () => {
    setAlreadyUploadModal(false);
    history.push('/payroll');
  };

  const getPositionRoute = (string, subString, index) => {
    return string?.split(subString, index).join(subString).length;
  };

  const handlePopState = () => {
    if (useTopBar) {
      window.onpopstate = function() {
        if (useTopBar?.step === 'lastStep') removeTopBar();

        if (useTopBar?.step > 1) goBackTopBar();

        if (useTopBar?.step <= 1) removeTopBar();
      };
    }
  };

  const removeTopBar = () => {
    sessionStorage.removeItem('useTopBar');
    history.push(useTopBar?.route);
  };

  const goBackTopBar = () => {
    const position = getPositionRoute(useTopBar?.route, '/', useTopBar?.step);

    sessionStorage.setItem(
      'useTopBar',
      JSON.stringify({
        name: useTopBar?.name,
        step: useTopBar?.step - 1,
        route: useTopBar?.route?.substring(0, position),
      }),
    );

    history.push(useTopBar?.route);
  };

  const { renderNotificationMenuItem } = useNotificationMenu({
    uploadDataEmployees,
    uploadDataInsurance,
    uploadDataPaycheck,
    uploadDataPayroll,
    onClickNotification,
    handleClickItem,
    handleClickItemEmployees,
    handleCloseNotification,
  });

  const hasAsyncFileProcess =
    (uploadDataPayroll &&
      uploadData &&
      uploadData?.status !== CANCELED &&
      uploadData?.companyCode === codeCompany &&
      !uploadCanceled) ||
    (uploadDataEmployees &&
      employeeData &&
      employeeData?.status !== CANCELED &&
      employeeData?.companyCode === codeCompany &&
      !uploadCanceled) ||
    (uploadDataPaycheck &&
      paycheckData?.companyCode === codeCompany &&
      paycheckData?.percentage > 0 &&
      paycheckData?.status !== CANCELED) ||
    (uploadDataInsurance &&
      insuranceData?.companyCode === codeCompany &&
      insuranceData?.percentage > 0 &&
      insuranceData?.status !== CANCELED) ||
      employeesFileExportStatus ||
      costCenterFile

  useEffect(() => {
    if (confirm && headerBlockPrompt) {
      allowTransition();
      setHeaderBlockPrompt(false);
    }
  }, [headerBlockPrompt, confirm]);

  useEffect(() => {
    handlePopState();
  }, [useTopBar]);

  useEffect(() => {
    setCompanyNameSearch('');

    if(!!userEncodedKey){
      const userChannel = pusher.subscribe(userEncodedKey);

      userChannel.bind('UPDATE_EMPLOYEE_FILE_PROCESSING', (data)=> {
        dispatch(EmployeesFileSliceActions.setFileExportStatus(data))
      })
  
      userChannel.bind('UPDATE_EMPLOYEE_FILE_ERROR', (data)=> {
        dispatch(EmployeesFileSliceActions.setFileExportStatus({
          ...data,
          status: 'UNPROCESSABLE_FILE'
        }))
  
        error('Houve um erro no processamento de atualização.')
      })

      userChannel.bind('LOCATION_CREATE_FILE', (data)=> {
        dispatch(CostCenterFileSliceActions.setFileExportStatus(data))
      })
  
      userChannel.bind('LOCATION_CREATE_FILE_ERROR', (data)=> {    
        dispatch(CostCenterFileSliceActions.setFileExportStatus({    
        ...data,    
        status: 'UNPROCESSABLE_FILE'
        }))    
      })
    }

    const code = codeCompany || '';

    const channel = pusher.subscribe(code);

    channel.bind('RETURN_PAYMENT_FILE_CNAB_240_BATCH', (data)=> {
      dispatch(addDownloadCnabReturnFilePusher(data))
    })

    // Here starts nsu download listener

    channel.bind(pusherEvents.RECEIPT_PAYMENT, (pusherData)=> {
      handlePusherData(pusherData);
    });

    channel.bind(pusherEvents.RECEIPT_PAYMENT_LOCATION, (pusherData)=> {
      handlePusherData(pusherData);
    });

    channel.bind(pusherEvents.RECEIPT_PAYMENT_STOCKING, (pusherData)=> {
      handlePusherData(pusherData);
    });

    // End

    channel.bind('PROCESSED_PAYROLL', function(data) {
      if (
        ['/payroll/file-batch/upload', '/payroll/file-batch/confirm'].includes(
          history.location.pathname,
        )
      )
        return;

      const extensionFile =
        data?.fileName?.substr(data?.fileName?.length - 3) === 'txt'
          ? 'txt'
          : data?.fileName?.substr(data?.fileName?.length - 4) === 'xlsx'
          ? 'xlsx'
          : 'xls';

      const type = extensionFile === 'txt' ? 2 : 1;

      if (data?.status !== CANCELED) {
        localStorage.setItem(
          `uploadDataPayroll-${codeCompany}`,
          JSON.stringify(data),
        );

        setUploadData(data);
      }

      if (data?.status === CANCELED) {
        localStorage.removeItem(`uploadDataPayroll-${codeCompany}`);
      }

      localStorage.setItem(
        'payrollOption',
        JSON.stringify({
          fileType: type,
          extension: extensionFile,
        }),
      );

      if (
        (data?.status === PROCESSED_PAYROLL_SUCCESS ||
          data?.status === PROCESSED_PAYROLL_ERROR) &&
        data?.companyCode === codeCompany
      ) {
        if (
          history.location.pathname !==
          '/payroll/menu/info-payroll/register-batch'
        ) {
          setOpenNotification(true);
          setTimeout(() => setOpenNotification(false), 5000);
        }
      }
    });

    channel.bind('PROCESSED_EMPLOYEES', function(data) {
      if (data?.status !== CANCELED) {
        localStorage.setItem(
          `uploadDataEmployees-${codeCompany}`,
          JSON.stringify(data),
        );

        setEmployeeData(data);
      }

      if (
        (data?.status === PROCESSED_EMPLOYEES_SUCCESS ||
          data?.status === PROCESSED_EMPLOYEES_ERROR) &&
        data?.companyCode === codeCompany
      ) {
        if (
          history.location.pathname !==
          '/employees/register-batch/menu/select-company/excel-form'
        ) {
          setOpenNotification(true);
          setTimeout(() => setOpenNotification(false), 5000);
        }
      }
    });

    channel.bind('PROCESSED_PAYCHECK', function(data) {
      setPaycheckData(data);
    });

    channel.bind('PROCESSED_INSURANCES', function(data) {
      setInsuranceData(data);
    });
  }, [codeCompany]);

  if (useTopBar && isMobile) {
    return (
      <StepTopBar
        name={useTopBar?.name}
        icon={
          useTopBar?.step === 'lastStep' ? (
            <CloseRoundedIcon />
          ) : (
            <ArrowBackRoundedIcon />
          )
        }
        onClick={
          useTopBar?.step === 'lastStep' || useTopBar?.step <= 1
            ? removeTopBar
            : goBackTopBar
        }
        progress={useTopBar?.step}
      />
    );
  }

  return (
    <MuiThemeProvider theme={theme}>
      <>
        <SuccessModal
          title="Obrigado!"
          text="Recebemos o seu feedback e o utilizaremos para melhorar a sua experiência!"
          open={openSuccessModal}
          onCloseDialog={() => setOpenSuccessModal(false)}
          closeButtonText="Fechar"
        />
        <AppBar className={clsx(classes.appBar, open && classes.appBarShift)}>
          <SpringModal
            modal={openModal}
            closeModal={handleCloseModal}
            showBalance={handleShowBalance}
          />
          <Toolbar
            className={
              isMobile ? clsx(classes.toolbarMobile) : clsx(classes.toolbar)
            }
          >
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(styles.menuButton, open && classes.hide)}
              disabled={ACCESS_PROFILES?.length === 0}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.userAreaStyle}>
              <div>
                <ListItem>
                  <IconButton
                    disabled={!hasMultipleCompanies}
                    className={classes.button}
                    onClick={handleClick}
                  >
                    <SuitcaseIcon />
                  </IconButton>
                  <ListItemText
                    primary={
                      <Typography
                        className={
                          isMobile
                            ? classes.userMobilePrimaryTextStyle
                            : classes.userPrimaryTextStyle
                        }
                      >
                        Empresa
                      </Typography>
                    }
                    secondary={
                      <div className={classes.areaListButtonList}>
                        {COMPANY_NAME?.length >= 18 ? (
                          <Tooltip title={COMPANY_NAME}>
                            <Typography
                              className={
                                isMobile
                                  ? classes.userMobileSecondaryTextStyle
                                  : classes.userSecondaryTextStyle
                              }
                              onClick={
                                hasMultipleCompanies ? handleClick : () => {}
                              }
                            >
                              {truncateString(COMPANY_NAME, 18)}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography
                            className={
                              isMobile
                                ? classes.userMobileSecondaryTextStyle
                                : classes.userSecondaryTextStyle
                            }
                            onClick={
                              hasMultipleCompanies ? handleClick : () => {}
                            }
                          >
                            {COMPANY_NAME}
                          </Typography>
                        )}
                        {renderIconButton()}
                      </div>
                    }
                  />
                </ListItem>
                <Menu
                  id="lock-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  className={classes.menu}
                  style={
                    ACCESS_PROFILES?.length === 0 && !openModal
                      ? { position: 'absolute', zIndex: 1400 }
                      : {}
                  }
                >
                  <MenuItem disabled>Escolha a empresa</MenuItem>
                  <MuiThemeProvider theme={theme}>
                    <TextField
                      inputRef={input => input && input.focus()}
                      size="small"
                      variant="outlined"
                      className={styles.searchCompaniesInput}
                      placeholder="Pesquise pelo nome"
                      onChange={handleChangeCompanyNameSearch}
                      value={companyNameSearch}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search className={styles.iconColor} />
                          </InputAdornment>
                        ),
                        endAdornment: companyNameSearch?.length > 0 && (
                          <IconButton
                            aria-label="toggle password visibility"
                            className={styles.endAdornment}
                            onClick={() => setCompanyNameSearch('')}
                          >
                            <CloseRoundedIcon
                              className={styles.endAdornmentIcon}
                            />
                          </IconButton>
                        ),
                      }}
                    />
                  </MuiThemeProvider>
                  {companies
                    ?.filter(item => {
                      const lowerCased = companyNameSearch.toLowerCase();

                      if (
                        item.name.includes(companyNameSearch) ||
                        item.name
                          .replace('.', '')
                          .replace('.', '')
                          .includes(companyNameSearch) ||
                        item.name.toLowerCase().includes(lowerCased)
                      ) {
                        return true;
                      }
                      return false;
                    })
                    ?.map((company, i) => (
                      <div key={company.code}>
                        <MenuItem
                          key={company.code}
                          selected={company.code === codeCompany}
                          onClick={event => handleMenuItemClick(event, company)}
                          className={styles.accountMenuItem}
                          disabled={!company.hasIpAccess}
                        >
                          <Typography>{company.name}</Typography>
                          {company.code === codeCompany && (
                            <CheckOutlinedIcon
                              className={styles.selectAccountIcon}
                              fontSize="small"
                            />
                          )}
                        </MenuItem>
                      </div>
                    ))}
                </Menu>
              </div>
            </div>
            {hasAsyncFileProcess && (
              <>
                <Hidden smDown>
                  <IconButton
                    className={clsx(
                      classes.hiddenUploadButton,
                      hasAsyncFileProcess && classes.uploadButton,
                    )}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClickNotification}
                  >
                    <UploadButton width={32} height={32}/>
                  </IconButton>
                  {uploadData?.status === PROCESSED_PAYROLL_SUCCESS ||
                    employeeData?.status === PROCESSED_EMPLOYEES_SUCCESS ||
                    paycheckData?.status === PROCESSED_PAYCHECK_SUCCESS ||
                    employeesFileExportStatus?.status === PROCESSED_UPDATE_EMPLOYEE_FILE_SUCCESS || 
                    costCenterFile?.status === PROCESSED_CREATE_LOCATION_FILE_SUCCESS  ? (
                      <div
                        className={
                          hasAsyncFileProcess && classes.uploadButtonSuccess
                        }
                      >
                        <CheckIcon className={classes.checkIcon} />
                      </div>
                    ) : uploadData?.status === PROCESSED_PAYROLL_ERROR ||
                      employeeData?.status === PROCESSED_EMPLOYEES_ERROR ||
                      paycheckData?.status === PROCESSED_PAYCHECK_ERROR ||
                      costCenterFile?.status === PROCESSED_CREATE_LOCATION_FILE_ERROR ||
                      costCenterFile?.status === UNPROCESSABLE_FILE ||
                      employeesFileExportStatus?.status === UNPROCESSABLE_FILE ||
                      employeesFileExportStatus?.status === PROCESSED_UPDATE_EMPLOYEE_FILE_ERROR ? (
                      <div
                        className={
                          hasAsyncFileProcess && classes.uploadButtonError
                        }
                      >
                        <ErrorOutlineIcon className={classes.checkIcon} />
                      </div>
                    ) : (
                      <div
                        className={
                          hasAsyncFileProcess && classes.uploadButtonCount
                        }
                      >
                        {uploadArray.length || employeesFileExportStatus?.status? 1 : 0 || costCenterFile?.status? 1 : 0 || 0}
                      </div>
                  )}
                </Hidden>
              </>
            )}
            <Menu
              id="simple-menu"
              anchorEl={anchorElNotifications}
              keepMounted
              open={Boolean(anchorElNotifications)}
              onClose={handleCloseNotification}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              elevation={5}
            >
              {renderNotificationMenuItem()}
            </Menu>

            {/* {downloadingNSUProof.length > 0 && <DownloadingProgress />}
            <DownloadingProgressNew /> */}
            <DownloadingProgressNew2 />
            
          
            <div className='bankData'>
              <Tooltip
                title="Dados bancários"
              >
              <AccountBalanceRoundedIcon
                className={styles.dataBankButton}
                onClick={() => setOpenDataBankModal(!openDataBankModal)} />
              </Tooltip>
            </div>

            <Button
              className={styles.logoutButton}
              aria-controls="user-menu"
              aria-haspopup="true"
              onClick={event => setAnchorElUserMenu(event.currentTarget)}
            >
              <AccountCircleRoundedIcon className={styles.logoutButtonIcon} />

              <span>{truncateString(FORMATED_USER_NAME, 13)}</span>

              {anchorElUserMenu ? (
                <ExpandLessRoundedIcon className={styles.userMenuIcon} />
              ) : (
                <ExpandMoreRoundedIcon className={styles.userMenuIcon} />
              )}
            </Button>
            <Menu
              id="user-menu"
              anchorEl={anchorElUserMenu}
              keepMounted
              open={Boolean(anchorElUserMenu)}
              onClose={() => setAnchorElUserMenu(null)}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <MenuItem onClick={confirmLogout} className={styles.userMenuItem}>
                Sair
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>

        <AlreadyUploadModal
          open={alreadyUploadModal}
          onConfirmClicked={onConfirmAlreadyUploadModal}
          confirmButtonText="Ver gestão de folha"
          title="Um outro usuário já realizou está ação."
          text={errorAlreadyPayroll}
        />
        <LogoutModal
          title="Desejar realmente sair?"
          text="Antes de sair, verifique se todas as suas solicitações foram concluídas."
          confirmButtonText="Sair"
          onConfirmClicked={systemLogout}
          cancelButtonText="Cancelar"
          open={openModalLogout}
          onCancelButtonClicked={() => setOpenModalLogout(false)}
        />

        {uploadDataPayroll?.status !== PROCESSING ||
          (uploadDataEmployees?.status !== PROCESSING && (
            <Snackbar
              open={openNotification}
              autoHideDuration={5000}
              onClose={() => setOpenNotification(false)}
            >
              {uploadDataPayroll?.status === PROCESSED_PAYROLL_SUCCESS ||
              uploadDataEmployees?.status === PROCESSED_EMPLOYEES_SUCCESS
                ? 'Processamento da importação concluído com sucesso!'
                : 'Houve um erro no processamento da importação.'}
          </Snackbar>
        ))
      }

      <BankDataModal
        openModal={openDataBankModal}
        setOpenModal={setOpenDataBankModal}
        title="Dados Bancários"
        socialReason={currentCompanyAllData?.parentCompanyName || currentCompanyAllData?.name}
        bankName={'520 - Somapay SCD'
        }
        agency="0001"
        accountNumber={accountSCD?.id}
        cnpj={formatCnpj(currentCompanyAllData?.parentCompanyCnpj || currentCompanyAllData?.cnpj)}
      />
    </>
    </MuiThemeProvider>
  );
}
