import React from "react";
import { TableBody } from "@material-ui/core";
import TableCases from "../../TableCases";

export function EnhancedTableBody({
  data = [],
  tableConfig = [],
  rowsPerPage = 5,
  isLoading = false,
  hasError = false,
}) {

  if (isLoading) return <TableCases.Loading config={tableConfig} rowsPerPage={rowsPerPage} />;
  if (hasError) return <TableCases.Error config={tableConfig} />;
  if (data.length === 0) return <TableCases.Empty config={tableConfig} />;

  return (
    <TableBody>
      <TableCases.Render config={tableConfig} data={data} />
    </TableBody>
  );
}
