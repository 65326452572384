import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    wrapperKebab: {
        '& .kebab-menu':{
            width: 36,
            height: 36,
            borderRadius: 18,
            border: '1px solid #E5E5E5',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        '& path':{
            fill: '#52197F'
        },
        '& button:hover': {
            backgroundColor: 'transparent', // Impede mudança de cor no hover
            border: '1px solid transparent', // Mantém a borda original
        },
        '& button': {
            backgroundColor: 'transparent', // Impede mudança de cor no hover
            border: '1px solid transparent', // Mantém a borda original
        },
        '& .MuiTouchRipple-root': {
            display: 'none',
        },
    }
}));

export default useStyles;
