import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 'fit-content',
    height: 'fit-content',
    marginLeft: 'auto',
  },
  wrapperRounded: {
    width: 45,
    height: 45,
    borderRadius: 45,
    border: '1px solid #E5E5E5',
    marginLeft: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  kebabButton: {
    height: theme.spacing(3),
    padding: 0,
    minWidth: 'fit-content',
    minHeight: 'fit-content',
    '& svg': {
      width: theme.spacing(3),
      minWidth: theme.spacing(3),
    },
  },
  kebabButtonRounded: {
    padding: 0,
    minWidth: 'fit-content',
    minHeight: 'fit-content',
    height: "100%",
    width: "100%",
    borderRadius: '50%',
    '& svg': {
      width: theme.spacing(3),
      minWidth: theme.spacing(3),
    },
  },
  kebabMenu: {
    marginTop: theme.spacing(10),
    marginLeft: 'auto',
    transform: 'translateY(8px)',
  },
  kebabMenuPayRoll: {
    marginTop: theme.spacing(10),
    transform: 'translate(-10px, -25px)',
  },
  kebabItensContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    paddingInline: theme.spacing(1),
  },
  kebabItensContainerPayRoll: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    boxShadow:'0px 0px 6px 0px rgb(209 209 209 / 62%)',
    borderRadius: '2px',
    marginInline: theme.spacing(1),
  },
}));

export default useStyles;
