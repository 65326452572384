import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const PurpleCheckbox = withStyles({
  root: {
    color: '#3A3541AD',
    '&$checked': {
      color: '#52197F',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

export default PurpleCheckbox;
