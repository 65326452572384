import React, { useState } from 'react';
import {
  Badge,
  Box,
  Drawer,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { ReactComponent as DownloadButton } from '../../../assets/download-button.svg';
import { useStyles } from './styles';
import { DownloadCompleted, DownloadInProgress } from './components';
import { useDownloadingNSUProof } from './hooks/useDownloadingNSUProof';
import { useOnboardingReportData } from './hooks/useOnboardingReportData';
import { useDownloadCnab } from './hooks/useDownloadCnab';
import { useDownloadCnabReturnFile } from './hooks/useDownloadCnabReturnFile';

export default function DownloadingProgressNew2() {
  const styles = useStyles();
  
  const downloadingNSUProof = useDownloadingNSUProof()
  const onboardingReportData = useOnboardingReportData()
  const downloadCnab = useDownloadCnab()
  const downloadCnabReturnFile = useDownloadCnabReturnFile()

  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getDownloadDetails = () => {
    const allDownloading = downloadingNSUProof.statusCounts.allDownloading + downloadCnab.statusCount.allDownloading + onboardingReportData.statusCount.allDownloading;
    const errors = downloadingNSUProof.statusCounts.error || downloadCnab.statusCount.irrorNotification || onboardingReportData.statusCount.irrorNotification;
    const inProgress = downloadingNSUProof.statusCounts.inProgress + downloadCnab.statusCount.inProgress + onboardingReportData.statusCount.inProgress;

    if (errors) {
      return <ErrorOutlineRoundedIcon className={styles.iconErrorTop} />;
    }
  
    if (inProgress < 1) {
      return <CheckIcon className={styles.iconAllDownloadConcluded} />;
    }
  
    return (
      <Typography className={styles.count} variant="caption">
        {allDownloading}
      </Typography>
    );
  };

  const renderDownloadIcon = () => {
      const isAnythingDownload = downloadingNSUProof?.data?.length || !onboardingReportData.isEmpty || !downloadCnab.isEmpty || !downloadCnabReturnFile?.isEmpty
      
      if(!isAnythingDownload) return <></>

      return (
        <Tooltip arrow title="Downloads">
          <IconButton onClick={toggleMenu} aria-label="Abrir menu de downloads">
            <Badge color="primary">
              <DownloadButton />
            </Badge>
            {getDownloadDetails()}
          </IconButton>
        </Tooltip>
      )
  };

  return (
    <>
      {renderDownloadIcon()}
      
      <Drawer
        elevation={10}
        id="notifications-drawer"
        anchor="right"
        open={open}
        onClose={toggleMenu}
        PaperProps={{
          className: styles.root,
        }}
        BackdropProps={{ invisible: true }}
      >
        <>
          {downloadingNSUProof?.data?.map((nsu: any) => (
            <Box key={nsu.nsu} className={styles.positionWrapper}>
              {nsu.downloadReceiptsPercentage < 100 && !nsu.error ? (
                <DownloadInProgress 
                  title={`Gerando comprovantes - NSU: ${nsu.nsu}`}
                  progress={nsu.downloadReceiptsPercentage} 
                  action={() => downloadingNSUProof.actions.goToSeeDetails(nsu.nsu)} 
                />
              ) : (
                <DownloadCompleted 
                  title={`Gerando comprovantes - NSU: ${nsu.nsu}`}
                  subtitle={nsu.error ? 'Ocorreu um erro na geração dos comprovantes.':'Arquivos gerados com sucesso! Clique para fazer o download.' }
                  isError={nsu.error} 
                  onClose={() => downloadingNSUProof.actions.handleClose(nsu.nsu)}
                  closeAndActio={false}
                />
              )}
            </Box>
          ))}

          {!onboardingReportData?.isEmpty && (
            <Box className={styles.positionWrapper}>
              {onboardingReportData.data.status === 'PROCESSING' ? (
                <DownloadInProgress 
                  title='Geração do relatório de onboarding' 
                  progress={onboardingReportData.data.percentage} 
                  onClose={()=> handleClose()}
                />
              ):(
                <DownloadCompleted
                  title='Geração do relatório de onboarding' 
                  subtitle={onboardingReportData.data.status === 'PROCESSED_ONBOARDING_REPORT_ERROR' ? 'Ocorreu um erro na geração do relatório.' : 'Relatório gerado com sucesso! Clique para fazer o download.'}
                  isError={onboardingReportData.data.status === 'PROCESSED_ONBOARDING_REPORT_ERROR'}
                  action={() => onboardingReportData.actions.handleDownloadReport()} 
                  onClose={()=> handleClose()}
                />
              )}
            </Box>
          )}

          {!downloadCnab.isEmpty && downloadCnab.data.map((file: any) => (
            <Box key={file.nsu} className={styles.positionWrapper}>
              {file.percentage < 100 ? (
                <DownloadInProgress 
                  title={`Download CNAB 240`} 
                  subtitle={`NSU: ${file.nsu}`} 
                  progress={file.percentage}
                />
              ) : (
                <DownloadCompleted 
                  title={`Download CNAB 240 - NSU: ${file.nsu}`}
                  subtitle={ file.cnabStatus === 'CANCELED'? 'Ocorreu um erro ao processar o arquivo.' : 'Arquivo processado com sucesso! Clique para fazer o download.'}
                  isError={ file.cnabStatus === 'CANCELED' } 
                  action={() => file.cnabStatus === 'CANCELED' ? downloadCnab.actions.onRetryDownload(file.nsu):downloadCnab.actions.onDownloadCnab(file.nsu, file.cnabStatus === 'CANCELED')} 
                  onClose={()=> {
                    downloadCnab.actions.handleRemoveCnabDownload(file.nsu)
                    handleClose()
                  }}
                />
              )}
            </Box>
          ))}

          {downloadCnabReturnFile?.data && (
            <Box className={styles.positionWrapper}>
              {downloadCnabReturnFile.data.status === 'PROCESSING' ? (
                <DownloadInProgress 
                  title='Geração de comprovantes CNABs' 
                  progress={downloadCnabReturnFile.data.percentage} 
                />
              ):(
                <DownloadCompleted
                  title='Geração de comprovantes CNABs' 
                  subtitle={'Comprovantes gerados com sucesso.'}
                  isError={downloadCnabReturnFile.data.status === 'CANCELED' || downloadCnabReturnFile.data.status === 'PROCESSED_RETURN_PAYMENT_FILE_CNAB_240_BATCH_ERROR'}
                  onClose={()=> {
                    downloadCnabReturnFile.actions.handleClear()
                    handleClose()
                  }}
                />
              )}
            </Box>
          )}
        </>
      </Drawer>
    </>
  );
}
