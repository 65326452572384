import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  Typography,
  FormControl,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  makeStyles,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import PriorityHighRoundedIcon from '@material-ui/icons/PriorityHighRounded';
import styles from './styles';
import { costCenterOptions, downloadReceiptTypesToModal } from '../../pages/Payroll/ConsultPayroll/consts';
import CustomSelect from '../SharedComponents/CustomSelect/index.tsx';
import { useSelector } from 'react-redux';
import Buttons from '../Buttons/index.jsx';

const useStyles = makeStyles({
  root: {
    '&$checked': {
      color: '#52197f',
    },
  },
  checked: {},
});

const valuesType = {
  GROUPED: 'grouped',
  INDIVIDUAL: 'individual',
}

export default function ModalDownloadAllReceipts ({
  open,
  onCloseDialog,
  title,
  text,
  confirmText,
  onConfirmClicked,
  cancelText,
  downloadReceiptType,
  setDownloadReceiptItems,
  downloadReceiptItems,
  centerCosts,
  sectors,
  currentNSU,
}) {
  const classes = useStyles();
  const {downloadingNSUProof} = useSelector(state => state.payroll.payrollDetails);
  const [value, setValue] = React.useState(valuesType.GROUPED);

  const nsuDownloadData = downloadingNSUProof.find(item => item.nsu === currentNSU);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleClose = () => {
    setDownloadReceiptItems([]);
    onCloseDialog();
  }

  const disableButton = () => {
    if (!downloadReceiptType) {
      return false;
    }
    if (downloadReceiptType === downloadReceiptTypesToModal.COST && downloadReceiptItems.length < 1) {
      return true;
    }
    if (downloadReceiptType === downloadReceiptTypesToModal.SECTOR && downloadReceiptItems.length < 1) {
      return true;
    }
    return false;
  }
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
     <Box style={styles.dialogMain}>
        <DialogTitle id="alert-dialog-title" style={styles.dialogHeader}>
          <Typography style={styles.title}>{title}</Typography>
          <Typography style={styles.text}>{text}</Typography>
        </DialogTitle>

        {downloadReceiptType !== downloadReceiptTypesToModal.ALL && 
        <Box style={styles.firstNumberBox}>
          <Typography style={styles.number}>1</Typography>
          <Typography style={styles.textNumber}>Escolha o tipo de organização do arquivo</Typography>
        </Box>}

        <FormControl component="fieldset">
          <RadioGroup aria-label="groupedorOrIndividual" name="groupedorOrIndividual" value={value} onChange={handleChange}>
            <Grid  style={styles.dialogContent}>
              <Grid item xs={6} style={styles.dialogContentOption}>
                <FormControlLabel value={valuesType.GROUPED} control={<Radio classes={{ root: classes.root, checked: classes.checked }} />} label="Baixar agrupado" />
              </Grid>
              <Grid item xs={6} style={styles.dialogContentOption}>
                <FormControlLabel value={valuesType.INDIVIDUAL} control={<Radio classes={{ root: classes.root, checked: classes.checked }} />} label="Baixar individualmente" />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>

        {downloadReceiptType !== downloadReceiptTypesToModal.ALL && 
          <Box style={styles.SelectItemsBox}>
            <Box style={styles.secondNumberBox}>
              <Typography style={styles.number}>2</Typography>
              <Typography style={styles.textNumber}>
                {downloadReceiptType === downloadReceiptTypesToModal.COST && 'Selecione os centros de custos'}
                {downloadReceiptType === downloadReceiptTypesToModal.SECTOR && 'Selecione os setores'}
              </Typography>
            </Box>
            <CustomSelect
              options={downloadReceiptType === downloadReceiptTypesToModal.COST ? centerCosts : sectors}
              setOptions={setDownloadReceiptItems}
              label={downloadReceiptType === downloadReceiptTypesToModal.COST ? 'Centro de custo' : 'Setores'} />
          </Box>
        }

        {nsuDownloadData?.error 
          && <Box style={styles.downloadError}>
                <PriorityHighRoundedIcon style={styles.downloadErrorIcon}/>
                <Typography style={styles.textError}>
                  Houve um erro ao baixar os comprovantes
                </Typography>
              </Box>
        }
        <Box  style={styles.dialogActions}>
          <Button
            onClick={handleClose}
            color="primary"
            autoFocus
            style={styles.cancelButtonStyle}
          >
            {cancelText}
          </Button>
          <Buttons.PrimaryButton
            size="large"
            color="secondary"
            title={confirmText}
            onClick={() => onConfirmClicked(value === valuesType.GROUPED ? true : false)}
            disabled={disableButton()}
            style={styles.confirmButtonStyle} 
          />
        </Box>
     </Box>
  </Dialog>
  )
};
