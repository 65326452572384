// @ts-ignore
import { useHistory } from 'react-router-dom';
import { Box, Typography } from "@material-ui/core"
import React from "react"
import { useStyles } from "./styles"
import { ReactComponent  as DefaultCompletion } from '../../../assets/defaultCompletion.svg'

import ProgressToolBar from "../../../components/ProgressToolBar"
import { useDispatch, useSelector } from 'react-redux';
import formatDate from '../../../utils/formatDate';
import { EmployeesFileSliceActions } from '../../../redux/store/Employees/FileUpload/slice';


const FeedbackSuccess = ()=> {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()

    const fileReportFeedback = useSelector((state:any) => state?.employeesFileSlice.fileReportFeedback);
    
    const pluralTitle = fileReportFeedback?.successEmployees?.length > 1 ? 'Colaboradores atualizados' : 'Colaborador atualizado'
    const pluralMessage = fileReportFeedback?.successEmployees?.length > 1 ? 'colaboradores foram atualizados corretamente.' : 'colaborador foi atualizado corretamente.'


    return (
        <Box className={classes.wrapper}>
            <Box className={classes.header}>
                <Typography variant="h2" className={classes.title}>{pluralTitle}</Typography>
            </Box>

            <Box className={`${classes.row} ${classes.successFeedbackContainer}`}>
                <DefaultCompletion />
                <Box className={classes.column} style={{gap: 8}}>
                    <Typography variant="h2" className={classes.successFeedbackTitle}>Atualização concluída com sucesso!</Typography>
                    <Box className={classes.row}>
                        <Typography variant="h2" className={`${classes.successFeedbackSubtitle} evidence`}>{fileReportFeedback?.successEmployees?.length || 0}</Typography>
                        <Typography variant="h2" className={classes.successFeedbackSubtitle}>{pluralMessage}</Typography>
                    </Box>
                </Box>
            </Box>

            <Box className={classes.confirmationInfoContainer}>
                <Typography variant="h2" className={classes.confirmationInfoTitle}>Detalhes da atualização</Typography>
                <Box className={`${classes.row} ${classes.confirmationInfoContent}`}>
                    <Box className={classes.confirmationInfoCard}>
                        <Typography variant="h2" className={classes.confirmationInfoLabel}>Nome do arquivo</Typography>
                        <Typography variant="h2" className={classes.confirmationInfoValue}>{fileReportFeedback?.fileName}</Typography>
                    </Box>
                    <Box className={classes.confirmationInfoCard}>
                        <Typography variant="h2" className={classes.confirmationInfoLabel}>Qtde. de colaboradores atualizados</Typography>
                        <Typography variant="h2" className={classes.confirmationInfoValue}>{fileReportFeedback?.successEmployees?.length}</Typography>
                    </Box>
                    <Box className={classes.confirmationInfoCard}>
                        <Typography variant="h2" className={classes.confirmationInfoLabel}>Atualizado em:</Typography>
                        <Typography variant="h2" className={classes.confirmationInfoValue}>{formatDate(new Date(),'DD/MM/YYYY [às] HH:mm')}</Typography>
                    </Box>
                </Box>
            </Box>
            
            <ProgressToolBar
                disableNext={false}
                loading={false}
                disableBack={true}
                orangeNextButton={false} 
                onBackClicked={()=>''}
                onNextClicked={()=>{
                    dispatch(EmployeesFileSliceActions.clearReportFeedback())
                    dispatch(EmployeesFileSliceActions.clearFileExportStatus())
                    history.push('/employees')
                }}
                nextButtonText={'Gerenciar funcionários'}
                numberOfSteps={undefined} 
                currentStep={undefined} 
                count={undefined} 
                numberAccount={undefined} 
                textAccount={undefined} 
                hidden={undefined} 
                typeNextButton={undefined} 
                formRef={undefined} 
                placementTooltip={undefined}                
            />
            
        </Box>
    )
}

export default FeedbackSuccess