import { reduxApi } from "../../../../services/reduxApi";
import { CostCentersResponse, FileUploadResponse, Pagination } from "./types";

const fileUploadCostCenterTags = {
    tag: 'FILE_UPLOAD_COST_CENTER',
};

const slice = reduxApi
    .enhanceEndpoints({
        addTagTypes: [fileUploadCostCenterTags.tag],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getCostCenterFileTemplate: builder.mutation({
                query: () => ({
                    url: `/companies/location/file/import/model`,
                    method: 'GET',
                }),
            }),
            getCostCenterSuccessImportDetails: builder.query<CostCentersResponse, { fileId: string, params: Pagination }>({
                query: ({ fileId, params }) => ({
                    url: `/companies/location/file/import/success/${fileId}`,
                    method: 'GET',
                    params
                }),
            }),
            getCostCenterErrorsImportDetails: builder.query<CostCentersResponse, { fileId: string, params: Pagination }>({
                query: ({ fileId, params }) => ({
                    url: `/companies/location/file/import/failed/${fileId}`,
                    method: 'GET',
                    params
                }),
            }),
            fileCostCenterExport: builder.mutation({
                query: ({ companyCode, fileType = '1', fileExtension, file }: { companyCode: string; fileExtension: string; fileType?: string; file: File }) => {
                    const formData = new FormData();
                    formData.append('file', file);
            
                    return {
                        url: `/companies/location/file/import/validate/${companyCode}/${fileType}`,
                        method: 'POST',
                        headers: {
                            fileExtension,
                        },
                        body: formData,
                    };
                },
            }),
            confirmCostCenterFile: builder.mutation({
                query: ({ fileId }: { fileId: string }) => ({
                    url: `companies/location/file/import/confirm/${fileId}`,
                    method: 'PUT',
                }),
            }),
        }),
    });

export const {
    useGetCostCenterFileTemplateMutation,
    useFileCostCenterExportMutation,
    useConfirmCostCenterFileMutation,
    useGetCostCenterSuccessImportDetailsQuery,
    useGetCostCenterErrorsImportDetailsQuery
} = slice;
