import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cnabDownloadData: [],
};

const FileDataSlice = createSlice({
  name: 'fileData',
  initialState,
  reducers: {
    setCnabDownloadData: (state, { payload }) => {
      const existingNsu = state.cnabDownloadData.find(
        file => file.nsu === payload.nsu,
      );

      if (!existingNsu) {
        state.cnabDownloadData = [...state.cnabDownloadData, payload];
        return;
      }

      const existingIndex = state.cnabDownloadData
        .map(({ nsu }) => nsu)
        .indexOf(existingNsu.nsu);

      state.cnabDownloadData.splice(existingIndex, 1, { ...payload });
    },

    removeCnabDownloadData: (state, { payload }) => {
      state.cnabDownloadData = state.cnabDownloadData.filter(
        file => file.nsu !== payload.nsu
      );
    },
  },
});

const FileDataActions = { ...FileDataSlice.actions };
const fileDataReducer = FileDataSlice.reducer;

export { FileDataActions, fileDataReducer };
