export const statusOptions = [
    { label: 'Aguardando Aprovação', value: 'PEDING_APPROVAL' },
    { label: 'Autorizado', value: 'AUTHORIZED' },
    { label: 'Saldo Insuficiente', value: 'INSUFFICIENT_FUNDS' },
    { label: 'Pago', value: 'PAID' },
    { label: 'Cancelado', value: 'CANCELED' },
    { label: 'Autorizado Expressamente', value: 'EXPRESSLY_AUTHORIZED' },
    { label: 'Pago Expressamente', value: 'EXPRESSLY_PAID' },
    { label: 'Expirado', value: 'EXPIRED' },
    { label: 'Pagamento Incompleto', value: 'INCOMPLETE_PAYMENT' },
    { label: 'Pagamento Não Realizado', value: 'PAYMENT_NOT_PERFORMED' },
    { label: 'Processando', value: 'PROCESSING' },
    { label: 'Processando TED', value: 'TED_PROCESSING' },
];

export const typeOptions = [
    { label: 'Salário', value: 'SALARY' },
    { label: 'Rescisão', value: 'RESCISSION' },
    { label: 'Férias', value: 'VACATION' },
    { label: '13º Salário', value: 'THIRTEENTH_SALARY' },
    { label: '1ª Parcela do 13º Salário', value: 'THIRTEENTH_SALARY_FIRST_INSTALLMENT' },
    { label: '2ª Parcela do 13º Salário', value: 'THIRTEENTH_SALARY_SECOND_INSTALLMENT' },
    { label: 'Adiantamento de Salário', value: 'ADVANCE_SALARY' },
    { label: 'Comissão', value: 'COMMISSION' },
    { label: 'Gorjeta', value: 'TIP' },
    { label: 'Auxílio Combustível', value: 'FUEL_ASSISTANCE' },
    { label: 'Vale Transporte', value: 'TRANSPORTATION_VOUCHER' },
    { label: 'Vale Alimentação', value: 'FOOD_VOUCHER' },
    { label: 'Vale Refeição', value: 'MEAL_VOURCHER' },
    { label: 'Cesta Básica', value: 'BASIC_FOOD_BASKET' },
    { label: 'Pagamento de PLR', value: 'PROFIT_SHARING_PAYMENT' },
    { label: 'Pagamento de PIS', value: 'PIS_PAYMENT' },
    { label: 'Pagamento de Pró-labore', value: 'PRO_LABORE_PAYMENT' },
    { label: 'Gratificação', value: 'REWARD' },
    { label: 'Depósito', value: 'DEPOSIT' },
    { label: 'Diária', value: 'DAILY' },
    { label: 'Diferença Salarial', value: 'SALARY_DIFFERENCE' },
];