import React, { useEffect, useRef, useState } from 'react'
import { useStyles } from './styles'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Box, Dialog, Typography, Button } from '@material-ui/core'
import { useDownloadMultipleCnabsMutation, useSearchForCnabQuery } from '../../../../../redux/store/Payroll/DownloadMultipleCnab240'
import currencyFormatter from '../../../../../utils/currencyFormatter'
import { dateFormat } from '../../../../../utils/dateFormat'
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';

import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import { ReactComponent as PersonCheck } from '../../../../../assets/personCheck.svg'
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import UpdateRoundedIcon from '@material-ui/icons/UpdateRounded';
import TonalityRoundedIcon from '@material-ui/icons/TonalityRounded';
import Checkbox from '../../../../../components/Checkbox/PurpleCheckbox'
import { useDispatch, useSelector } from 'react-redux';
import { addCnabFile, clearMultipleCnabFiles, removeCnabFile } from '../../../../../redux/store/Payroll/DownloadMultipleCnab240/slice';
import { PaymentFile } from '../../../../../redux/store/Payroll/DownloadMultipleCnab240/types'
import Form from '../../../../../components/Form'
import { Controller, useForm } from 'react-hook-form';
import Buttons from '../../../../../components/Buttons'
import { statusOptions, typeOptions } from './constants'
import { Alert } from '@material-ui/lab'
import { handleSnackbarStack } from '../../../../../utils/handleSnackbarStack'
import { CustomTable } from '../../../../../components/CustomTable/CustomTable'

const DownloadCnabModal = NiceModal.create(()=> {
    const modal = useModal();
    const classes = useStyles()
    const dispatch = useDispatch()
    const { success } = handleSnackbarStack();

    const { companies, currentCompany:{cnpj, code} } = useSelector((state: any) => state.companies);
    
    const [pages, setPages] = useState<{ page: number, size: number }>({ page: 0, size: 5 });

    const [allSelected, setAllSelected] = useState(false)
    
    const { reset, control, watch, getValues, formState: { errors }} = useForm({
        defaultValues: {
            nsu: '',
            initialDate: '',
            finalDate: '',
            companyCodeFilter: '',
            status: '' as any,
            type: '' as any
        },
    });
    
    watch(["nsu", "initialDate", "finalDate", "companyCodeFilter", "status", "type"]);
    
    const handleCloseModal = ()=> {
        dispatch(clearMultipleCnabFiles())
        modal.remove()
    }

    const setValuesRequest = () => {
        const currentValues = getValues();
        const { initialDate, finalDate, ...rest } = currentValues;
        const filterDateIsValid = initialDate && finalDate;
        const initialDateFormatted = dateFormat(new Date(initialDate), 'yyyy-MM-dd');
        const finalDateFormatted = dateFormat(new Date(finalDate), 'yyyy-MM-dd');

        return {
            ...rest,
            initialDate: filterDateIsValid ? initialDateFormatted : '',
            finalDate: filterDateIsValid ? finalDateFormatted : ''
        };
    };

    const prevFiltersRef = useRef(setValuesRequest());

    const filters = setValuesRequest();

    const hasFilters = Object.values(filters).some(value => value !== '' && value !== null && value !== undefined);

    const [ downloadMultipleCnabs, { isLoading: isLoadingDownloadMultipleCnabs, isError: isErrorDownloadMultipleCnabs } ] = useDownloadMultipleCnabsMutation()

    const editFilterToSearchForCnab = (currentFilters: any)=>{
        const { companyCodeFilter, ...restFilters } = currentFilters 

        return {
            ...restFilters,
            companyCode: companyCodeFilter,
        }
    }

    const { data, isLoading: isLoadingSearchForCnab, isFetching } = useSearchForCnabQuery(
        {
            filters: editFilterToSearchForCnab(filters),
            pageable: pages as any,
        }
        ,
        {
            skip: !pages,
            refetchOnMountOrArgChange: true,
        }
    );
    useEffect(() => {
        const newFilters = setValuesRequest();

        const filtersChanged = JSON.stringify(newFilters) !== JSON.stringify(prevFiltersRef.current);

        if (filtersChanged) {
            prevFiltersRef.current = newFilters;
            dispatch(clearMultipleCnabFiles());
            setAllSelected(false)
        }
    }, [isFetching]);


    const multipleCnabFiles: PaymentFile[] = useSelector((state: any) => state.multipleCnabFiles);
    
    const maxNumberDownload = 200
    
    const limitReached = (data?.totalSize || 0) >= maxNumberDownload

    const returnCountSelected = () => {
        const totalSizeRequest = data?.totalSize || 0
        const count = multipleCnabFiles?.length || 0

        if(allSelected) return totalSizeRequest - count

        return count
    }
    
    const multipleCnabFilesCount = returnCountSelected()
    
    const companyOptions = companies.map((company: any) => ({value: company.code, label: company.socialReason}));

    const onSubmit = async () => {
        const listNsu = multipleCnabFiles.map(cnab => cnab.nsu)

        downloadMultipleCnabs({
            companyCode: code,
            getAllFiles: allSelected,
            filters,
            ...((allSelected && listNsu?.length) && { excludedFileList: listNsu}),
            ...(!allSelected && { fileIdList: listNsu}),
        }).unwrap()
        .then(() => {
            handleCloseModal();
            success('Comprovantes gerados com sucesso!')
        })
    }; 

    const icons: any = {
        "Paga": <CheckCircleOutlineRoundedIcon className='check-circle'/>,
        "Autorizada": <PersonCheck style={{marginLeft: 3}}/>,
        "Aguardando Autorização": <ScheduleRoundedIcon className='schedule'/>,
        "Expirada": <UpdateRoundedIcon className='update'/>,
        "Pagamento Incompleto": <TonalityRoundedIcon className='tonality'/>,
    }

    const tableConfig = [
        {
            type: 'field',
            align: 'left',
            label: (
                <Box className={classes.statusTable}>
                    <Checkbox
                        disabled = {limitReached}
                        indeterminate={allSelected && !!multipleCnabFiles.length}
                        checked={allSelected}
                        
                        onChange={()=>{
                            dispatch(clearMultipleCnabFiles())
                            setAllSelected(prevState => !prevState)
                        }}
                    />
                </Box>
            ),
            render: (row: PaymentFile)=>{
                const isAlreadySelected = multipleCnabFiles.some(cnabFile => cnabFile.nsu === row.nsu);

                const isChecked = allSelected ? !isAlreadySelected : isAlreadySelected;

                const isDisabled = multipleCnabFiles.length >= maxNumberDownload;

                const handleChange = () => {
                    if (isAlreadySelected) {
                        dispatch(removeCnabFile(row.nsu));
                    } else if (!isDisabled) {
                        dispatch(addCnabFile(row));
                    }
                };

                return (
                    <Box className={classes.statusTable}>
                        <Checkbox checked={isChecked} disabled={isDisabled} onChange={handleChange} />
                    </Box>
                );
                
            }
        },
        {
            key: 'status',
            type: 'field',
            align: 'left',
            label: 'Status',
            render: (row:any)=>{
                return (
                    <Box className={classes.statusTable}>
                        {icons[row.status]}
                        {row.status}
                    </Box>
                )
                
            }
        },
        {
            key: 'paymentType',
            type: 'field',
            align: 'left',
            label: 'Tipo',
        },
        {
            key: 'nsu',
            type: 'field',
            align: 'left',
            label: 'NSU',
        },
        {
            key: 'importDate',
            type: 'field',
            align: 'left',
            label: 'Criado',
            render: (row: any)=> dateFormat(row.importDate)
        },
        {
            key: 'scheduleDate',
            type: 'field',
            align: 'left',
            label: 'Agendado',
            render: (row: any)=> dateFormat(row.scheduleDate)
        },
        {
            key: 'amountPaid',
            type: 'field',
            align: 'left',
            label: 'Valor',
            render: (row: any) => currencyFormatter(row.amountPaid) || '-'
        },
    ]

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={modal.visible}
            onClose={modal.remove}
            PaperProps={{ className: classes.root }}
        >
            <Box className={classes.header}>
                <Typography variant='h2' className={classes.title}>Baixar Arquivos de Retorno</Typography>
                <Typography variant='h5' className={classes.description}>
                    Selecione as folhas de pagamento para gerar os arquivos de retorno
                </Typography>
            </Box>
            <Form.FormBase>
                <Box className={classes.filterContainer}>
                    <Controller
                        control={control}
                        name="companyCodeFilter"
                        render={({ field }) => (
                            <Form.SelectField
                                InputLabelProps={{ shrink: true }}
                                label="Empresa"
                                size="small"
                                options={companyOptions || []}
                                error={!!errors?.companyCodeFilter}
                                helperText={errors?.companyCodeFilter?.message}
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="nsu"
                        render={({ field }) => (
                            <Form.TextField
                                InputLabelProps={{ shrink: true }}
                                label="NSU"
                                size="small"
                                error={!!errors?.nsu}
                                helperText={errors?.nsu?.message}
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="type"
                        render={({ field }) => (
                            <Form.SelectField
                                InputLabelProps={{ shrink: true }}
                                label="Tipo do pagamento"
                                size="small"
                                options={typeOptions}
                                error={!!errors?.type}
                                helperText={errors?.type?.message}
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="status"
                        render={({ field }) => (
                            <Form.SelectField
                                InputLabelProps={{ shrink: true }}
                                label="Situação de pagamento"
                                size="small"
                                options={statusOptions}
                                error={!!errors?.status}
                                helperText={errors?.status?.message}
                                {...field}
                            />
                        )}
                    />
                </Box>
                <Box className={classes.filterContainer} style={{marginBottom: 0}}>
                    <Controller
                        control={control}
                        name="initialDate"
                        render={({ field }) => (
                            <Form.DatePicker
                                InputLabelProps={{ shrink: true }}
                                label="Data inicial"
                                size="small"
                                error={!!errors?.initialDate}
                                helperText={errors?.initialDate?.message}
                                {...field}
                                value={field.value} 
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="finalDate"
                        render={({ field }) => (
                            <Form.DatePicker
                                InputLabelProps={{ shrink: true }}
                                label="Data final"
                                size="small"
                                error={!!errors?.initialDate}
                                helperText={errors?.initialDate?.message}
                                {...field}
                                value={field.value}
                            />
                        )}
                    />
                </Box>
            </Form.FormBase>
            
            {hasFilters && (
                <Box className={classes.clearFilterContainer}>
                    <Button onClick={()=> reset()} className={classes.clearFilterButton}>
                        <RemoveCircleOutlineRoundedIcon className={classes.iconClear}/>
                        Limpar filtros
                    </Button>
                </Box>
            )}

            {limitReached && (
                <Box className={classes.alertContainer}>
                    <Alert severity="error">Para uma melhor experiência, o limite de registros selecionados é de 200. Por favor, refine sua pesquisa utilizando os filtros.</Alert>
                </Box>
            )}
            
            <Box className={classes.tableContainer}>
                <CustomTable
                    identifier="nsu"
                    requests={(pageNumber:number, currentRowsNumber:number)=> {
                        setPages((prevState: any) => ({
                            ...prevState,
                            page: pageNumber || 0,
                            size: currentRowsNumber || 5,
                        }))
                    }}
                    data={data?.paymentsFiles || []}
                    length={data?.totalSize || 0}
                    isLoading={isLoadingSearchForCnab || isFetching}
                    tableConfig={tableConfig}
                    hasPagination
                    maxHeight={324}
                />
            </Box>

            {isErrorDownloadMultipleCnabs && (
                <Box style={{ marginTop: 32}}>
                    <Alert severity="error">Houve um erro ao baixar os comprovantes CNABs</Alert>
                </Box>
            )}
                
            <Box className={classes.footerContainer}>

                {(!!multipleCnabFilesCount) && (
                    <Box className={classes.countCnabsContainer}>
                        <Box className={classes.countCnab}>
                            {multipleCnabFilesCount}
                        </Box>
                        {`Selecionado${multipleCnabFilesCount > 1?'s':''}`}
                    </Box>
                )}

                <Box className={classes.actionContainer}>
                    <Buttons.PrimaryButton
                        size="medium"
                        variant='text'
                        color="primary"
                        title="CANCELAR"
                        titleStyle={{ fontWeight: 'bold' }}
                        onClick={handleCloseModal}
                    />
                    <Buttons.PrimaryButton
                        size="medium"
                        color="secondary"
                        title="BAIXAR COMPROVANTES"
                        titleStyle={{ fontWeight: 'bold' }}
                        disabled={!allSelected && !multipleCnabFiles?.length}
                        onClick={()=>{
                            onSubmit()
                        }}
                    />
                </Box>
            </Box>
        </Dialog>
    )
})

export default DownloadCnabModal