import React, { useEffect, useState } from 'react';
import { Add } from '@material-ui/icons';
import { Header } from '../../../components/Header';
import Buttons from '../../../components/Buttons';
import { useHistory } from 'react-router-dom';

import { CircularProgress, Box } from '@material-ui/core';

import {ReactComponent as DownloadIcon } from '../../../assets/download.svg'

import { Tabs } from './components/Tabs';
import KebabMenu from '../../PixKeys/components/KebabMenu';
import { useEmployeesList } from './hooks/useEmployeesList';
import { EmployeesProvider, useEmployeesContext } from './hooks/useEmployeesList';
import { useSelector } from 'react-redux'
import useStyles from './styles';

function List() {
  const {
    history,
    handleEmployeesRegisterBatchOptionClick,
    currentTab,
    handleChangeTab,
    totalApprovedEmployees,
    totalPendingEmployees,
    totalReprovedEmployees,
    handleCreateOnboardReport,
    isLoadingCreateOnboardingReport,
    totalCanceledEmployee
  } = useEmployeesContext();

  const classes = useStyles();

  const onboardingReportData = useSelector(
    state => state.onboarding.onboardingReportData,
  );

  const isLoadingOnboardReportData = onboardingReportData?.status === 'PROCESSING' || isLoadingCreateOnboardingReport

  const kebabActions = {
    download_onboard_report: () => handleCreateOnboardReport(),
    upload_employee: () => history.push('/employees/file-upload'),
  }

  const itemsKebab = [
    {key: 'download_onboard_report', label: 'Baixar relatório onboarding', isDisabled: isLoadingOnboardReportData},
    {key: 'upload_employee', label:'Atualizar dados cadastrais'},
  ]

  return (
    <>
      <Header
        title="Gerenciar funcionários"
        buttons={
          <>
            <Box className={classes.wrapperKebab}>
              <KebabMenu 
                items={itemsKebab}
                onSelectItem={(currentSelected)=>kebabActions[currentSelected?.key]()}
              />
            </Box>
            <Buttons.RoundedButton
              icon={<Add />}
              title="Incluir funcionários"
              options={[
                {
                  title: 'Cadastrar individualmente',
                  onClick: () => history.push('employees/register-single'),
                },
                {
                  title: 'Cadastrar a partir de um arquivo',
                  onClick: handleEmployeesRegisterBatchOptionClick,
                },
              ]}
            />
          </>
        }
      />

      <Tabs
        currentTab={currentTab}
        handleChangeTab={handleChangeTab}
        employeesTabsQuantities={{
          totalApprovedEmployees,
          totalPendingEmployees,
          totalReprovedEmployees,
          totalCanceledEmployee
        }}
      />
    </>
  );
}

export const EmployeesList = ()=>(
  <EmployeesProvider>
    <List/>
  </EmployeesProvider>
)
