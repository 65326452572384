import { createSlice } from '@reduxjs/toolkit';

const costCenterFileSlice = createSlice({
  name: 'costCenterFile',
  initialState: {
    fileExportStatus: null,
    fileReportFeedback: null
  },
  reducers: {
    setFileExportStatus(state, action) {
      state.fileExportStatus = action.payload;
    },
    clearFileExportStatus(state) {
      state.fileExportStatus = null;
    },
    setFileReportFeedback(state, action) {
      state.fileReportFeedback = action.payload;
    },
    clearReportFeedback(state) {
      state.fileReportFeedback = null;
    },
  },
});

export const CostCenterFileSliceActions = costCenterFileSlice.actions;
export const costCenterFileSliceReducer = costCenterFileSlice.reducer;
export default costCenterFileSliceReducer;