import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, MenuItem, Typography } from '@material-ui/core';
import { ReplayRounded } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';

import LinearProgressWithLabel from '../../ProgressBarWithLabel';
import Buttons from '../../Buttons';

import { ReactComponent as NotificationError } from '../../../assets/notificationError.svg';
import { ReactComponent as NotificationSuccess } from '../../../assets/notificationSuccess.svg';
import { ReactComponent as ArrowRight } from '../../../assets/chevron-right.svg';

import {
  useStartCnabFileGenerationMutation,
  useGetCnabFileDownloadMutation,
} from '../../../redux/store/FileData/fileData.api';

import {
  OnboardingActions
} from '../../../redux/store/Employees/Onboard';

import { pusherStatus as status } from '../../../enums/pusher';
import { handleSnackbarStack } from '../../../utils/handleSnackbarStack';

import useStyles from '../styles';

export default function useNotificationMenu({
  uploadDataEmployees,
  uploadDataInsurance,
  uploadDataPaycheck,
  uploadDataPayroll,
  onClickNotification,
  handleClickItem,
  handleClickItemEmployees,
  handleCloseNotification,
}) {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles();
  const { success, error } = handleSnackbarStack();
  const employeesFileExportStatus = useSelector(state => state.employeesFileSlice.fileExportStatus);
  const costCenterFile = useSelector(state => state.costCenterFileSlice.fileExportStatus);
  
  const getOnboardingReportPercentage = (status, percentage) => {
    if (status === "PROCESSING" && percentage > 0) {
      return percentage - 1;
    }
    return percentage ?? 0;
  };

  const renderTitle = title => {
    return (
      <Typography className={classes.menuNotificationTitle}>{title}</Typography>
    );
  };

  const renderError = (hasError, description) => {
    error('Houve um erro ao baixar o relatório de onboarding')

    return (
      <Grid item>
        <Typography
          className={
            hasError
              ? classes.statusDescriptionError
              : classes.statusDescriptionSuccess
          }
        >
          {description}
        </Typography>
      </Grid>
    );
  };

  const returnUploadDataPayroll = () => {
    if(!uploadDataPayroll) return <></>

    const {
      status: payrollStatus,
      statusDescription,
      percentage,
    } = uploadDataPayroll;

    const hasError = payrollStatus === status.PROCESSED_PAYROLL_ERROR;
    const hasSuccess = payrollStatus === status.PROCESSED_PAYROLL_SUCCESS;

    return (
      <MenuItem
        onClick={handleClickItem}
        className={classes.menuNotification}
      >
        <div style={{display: 'flex', flexDirection: 'row', gap: 12, alignItems: 'center'}}>
          <div>
          {renderTitle(uploadDataPayroll?.titleProcess)}

          {hasError ? (
            <div style={{ display: 'flex' }}>
              <NotificationError style={{ marginTop: 5, marginRight: 5 }} />
              <Typography className={classes.statusDescriptionError}>
                {statusDescription}
              </Typography>
            </div>
          ) : null}

          {hasSuccess ? (
            <div style={{ display: 'flex' }}>
              <NotificationSuccess style={{ marginTop: 4, marginRight: 5 }} />
              <Typography className={classes.statusDescriptionSuccess}>
                {statusDescription}
              </Typography>
            </div>
          ) : null}

          {!hasError && !hasSuccess ? (
            <>
              <div className={classes.linearProgress}>
                <LinearProgressWithLabel value={percentage || 0} />
              </div>
              <div style={{ display: 'flex' }}>
                <Typography className={classes.statusDescriptionDefault}>
                  {statusDescription}
                </Typography>
              </div>
            </>
          ) : null}
          </div>
          

          <div>
            <ArrowRight />
          </div>
        </div>
      </MenuItem>
    );
  } 

  const returnUploadDataInsurance = ()=>{
    if (!uploadDataInsurance) return <></>

    const {
      titleProcess,
      status: insuranceStatus,
      percentage,
      statusDescription,
    } = uploadDataInsurance;

    const hasError = insuranceStatus === status.PROCESSED_INSURANCES_ERROR;

    return (
      <MenuItem
        onClick={() => onClickNotification('insurance')}
        className={classes.menuNotification}
      >
        <div>
          {renderTitle(titleProcess)}

          {uploadDataInsurance?.status === status.PROCESSING ? (
            <>
              <LinearProgressWithLabel
                value={percentage || 0}
                className={classes.linearProgress}
              />

              <Typography className={classes.statusDescriptionDefault}>
                {statusDescription}
              </Typography>
            </>
          ) : (
            <Grid container>
              <Grid item>
                <Box mr={1} mt={0.1}>
                  {hasError ? <NotificationError /> : <NotificationSuccess />}
                </Box>
              </Grid>

              {renderError(hasError, uploadDataInsurance?.statusDescription)}
            </Grid>
          )}
        </div>
        <ArrowRight />
      </MenuItem>
    );
    
  }

  const returnUploadDataPaycheck = () => {
    if (!uploadDataPaycheck) return <></>

    const {
      percentage,
      status: paycheckStatus,
      statusDescription,
      titleProcess,
    } = uploadDataPaycheck;

    const hasError = paycheckStatus === status.PROCESSED_PAYCHECK_ERROR;

    return (
      <MenuItem
        onClick={() => onClickNotification('paycheck')}
        className={classes.menuNotification}
      >
        <div>
          {renderTitle(titleProcess)}

          {paycheckStatus === status.PROCESSING ? (
            <>
              <LinearProgressWithLabel
                value={percentage || 0}
                className={classes.linearProgress}
              />

              <Typography className={classes.statusDescriptionDefault}>
                {statusDescription}
              </Typography>
            </>
          ) : (
            <Grid container>
              <Grid item>
                <Box mr={1} mt={0.1}>
                  {hasError ? <NotificationError /> : <NotificationSuccess />}
                </Box>
              </Grid>

              {renderError(hasError, uploadDataPaycheck?.statusDescription)}
            </Grid>
          )}
        </div>

        <ArrowRight />
      </MenuItem>
    );
  }

  const returnUploadDataEmployees = () => {
    if (!uploadDataEmployees) return <></>
    
    const {
      percentage,
      status: employeesStatus,
      statusDescription,
      titleProcess,
    } = uploadDataEmployees;

    const hasError = employeesStatus === status.PROCESSED_EMPLOYEES_ERROR;
    const hasSuccess = employeesStatus === status.PROCESSED_EMPLOYEES_SUCCESS;

    return (
      <MenuItem
        onClick={handleClickItemEmployees}
        className={classes.menuNotification}
      >
        <div>
          {renderTitle(titleProcess)}

          {hasError && (
            <div style={{ display: 'flex' }}>
              <NotificationError style={{ marginTop: 5, marginRight: 5 }} />
              <Typography className={classes.statusDescriptionError}>
                {statusDescription}
              </Typography>
            </div>
          )}

          {hasSuccess && (
            <div style={{ display: 'flex' }}>
              <NotificationSuccess style={{ marginTop: 4, marginRight: 5 }} />
              <Typography className={classes.statusDescriptionSuccess}>
                {statusDescription}
              </Typography>
            </div>
          )}

          {!hasError && !hasSuccess && (
            <>
              <div className={classes.linearProgress}>
                <LinearProgressWithLabel value={percentage || 0} />
              </div>
              <div style={{ display: 'flex' }}>
                <Typography className={classes.statusDescriptionDefault}>
                  {statusDescription}
                </Typography>
              </div>
            </>
          )}
        </div>
        <div>
          <ArrowRight />
        </div>
      </MenuItem>
    );
  }

  const returnCostCenterFileExportStatus = () => {
    if (!costCenterFile) return <></>
    const {
      percentage,
      status: costCenterFileExportPusherStatus,
      statusDescription,
      titleProcess,
    } = costCenterFile;

    const hasError = costCenterFileExportPusherStatus === status.PROCESSED_CREATE_LOCATION_FILE_ERROR ||costCenterFileExportPusherStatus === status.UNPROCESSABLE_FILE;
    const hasSuccess = costCenterFileExportPusherStatus === status.PROCESSED_CREATE_LOCATION_FILE_SUCCESS;

    return (
      <MenuItem
        onClick={()=>{
          if(costCenterFileExportPusherStatus === status.UNPROCESSABLE_FILE){
            return history.push('/cost-and-sector-center/file-upload')
          }
          if(costCenterFileExportPusherStatus === status.PROCESSED_CREATE_LOCATION_FILE_SUCCESS || costCenterFileExportPusherStatus === status.PROCESSED_CREATE_LOCATION_FILE_ERROR){
            history.push('/cost-and-sector-center/file-report')
          }
        }}
        className={classes.menuNotification}
      >
        <div>
          {renderTitle('Envio de cadastro de centro de custo')}

          {hasError && (
            <div style={{ display: 'flex' }}>
              <NotificationError style={{ marginTop: 5, marginRight: 5 }} />
              <Typography className={classes.statusDescriptionError}>
                Ocorreu um erro. Clique para corrigir.
              </Typography>
            </div>
          )}

          {hasSuccess && (
            <div style={{ display: 'flex' }}>
              <NotificationSuccess style={{ marginTop: 4, marginRight: 5 }} />
              <Typography className={classes.statusDescriptionSuccess}>
                Arquivo enviado. Clique para retomar.
              </Typography>
            </div>
          )}

          {!hasError && !hasSuccess && (
            <>
              <div className={classes.linearProgress}>
                <LinearProgressWithLabel value={percentage || 0} />
              </div>
              <div style={{ display: 'flex' }}>
                <Typography className={classes.statusDescriptionDefault}>
                  {statusDescription}
                </Typography>
              </div>
            </>
          )}
        </div>
        <div>
          <ArrowRight />
        </div>
      </MenuItem>
    );
  }

  const returnEmployeesFileExportStatus = () => {
    if (!employeesFileExportStatus) return <></>
    const {
      percentage,
      status: employeesFileExportPusherStatus,
      statusDescription,
      titleProcess,
    } = employeesFileExportStatus;

    const hasError = employeesFileExportPusherStatus === status.PROCESSED_UPDATE_EMPLOYEE_FILE_ERROR || employeesFileExportPusherStatus === status.UNPROCESSABLE_FILE;
    const hasSuccess = employeesFileExportPusherStatus === status.PROCESSED_UPDATE_EMPLOYEE_FILE_SUCCESS;

    return (
      <MenuItem
        onClick={()=>{
          if(employeesFileExportPusherStatus === status.UNPROCESSABLE_FILE || employeesFileExportPusherStatus === status.PROCESSING){
            history.push('/employees/file-upload')
          }

          if(employeesFileExportPusherStatus === status.PROCESSED_UPDATE_EMPLOYEE_FILE_SUCCESS || employeesFileExportPusherStatus === status.PROCESSED_UPDATE_EMPLOYEE_FILE_ERROR){
            history.push('/employees/file-error-report')
          }
        }}
        className={classes.menuNotification}
      >
        <div>
          {renderTitle('Envio de atualização de colaboradores')}

          {hasError && (
            <div style={{ display: 'flex' }}>
              <NotificationError style={{ marginTop: 5, marginRight: 5 }} />
              <Typography className={classes.statusDescriptionError}>
                Preenchimento de arquivo incorreto
              </Typography>
            </div>
          )}

          {hasSuccess && (
            <div style={{ display: 'flex' }}>
              <NotificationSuccess style={{ marginTop: 4, marginRight: 5 }} />
              <Typography className={classes.statusDescriptionSuccess}>
                Processamento concluído com sucesso!
              </Typography>
            </div>
          )}

          {!hasError && !hasSuccess && (
            <>
              <div className={classes.linearProgress}>
                <LinearProgressWithLabel value={percentage || 0} />
              </div>
              <div style={{ display: 'flex' }}>
                <Typography className={classes.statusDescriptionDefault}>
                  {statusDescription}
                </Typography>
              </div>
            </>
          )}
        </div>
        <div>
          <ArrowRight />
        </div>
      </MenuItem>
    );
  }

  const renderNotificationMenuItem = () => {
    return (
      <>
        {returnUploadDataEmployees()}
        {returnUploadDataPayroll()}
        {returnUploadDataInsurance()}
        {returnEmployeesFileExportStatus()}
        {returnCostCenterFileExportStatus()}
        {returnUploadDataPaycheck()}
      </>
    );
  };

  return {
    renderNotificationMenuItem,
  };
}
