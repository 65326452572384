import { useState } from "react";
import { useFileCostCenterExportMutation , useGetCostCenterFileTemplateMutation} from '../../../redux/store/CostAndSector/FileUpload'
import { handleSnackbarStack } from "../../../utils/handleSnackbarStack";
import { useDispatch, useSelector } from "react-redux";
import { EmployeesFileSliceActions } from "../../../redux/store/Employees/FileUpload/slice";
import { CostCenterFileSliceActions } from "../../../redux/store/CostAndSector/FileUpload/slice";
import { pusher } from "../../../utils/pusher";

export const useFileUpload = () => {

    const dispatch = useDispatch()
    const { error } = handleSnackbarStack();
    
    const {
        currentCompany:{code},
    } = useSelector((state: any) => state.companies);

    const [ getCostCenterFileTemplate, { isLoading: isLoadingFileTemplate } ] = useGetCostCenterFileTemplateMutation()
    const [ fileCostCenterExport, { isLoading: isLoadingFileExport } ] = useFileCostCenterExportMutation()
    
    const [file, setFile] = useState<File[] | null>(null)

    const downloadFile = async (fileUrl: string) => {
        if (!fileUrl) {
            console.error('Erro: URL do arquivo inválida.');
            return;
        }
    
        const link = document.createElement('a');
        link.href = fileUrl;
        
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const download = async () => {
        try {
            const fileTemplateData: any = await getCostCenterFileTemplate({})
            await downloadFile(fileTemplateData?.error?.data)
        } catch (err) {
            error('Não foi possível baixar o arquivo. Por favor, tente novamente.');
        }
    };

    const submitFile = async () => {
        if(!file) return error('Não foi possível enviar o arquivo. Por favor, tente novamente.')
        const fileExtension: string = file[0].name.split('.').pop() || ''

        try {
            await fileCostCenterExport({
                companyCode: code,
                fileExtension: `.${fileExtension}`,
                file: file[0]
            }).unwrap();

        } catch (err) {
            error('Não foi possível enviar o arquivo. Por favor, verifique o arquivo e tente novamente.');
        }
    };

    const handleCancelFile = () => {
        dispatch(CostCenterFileSliceActions.clearReportFeedback());
        dispatch(CostCenterFileSliceActions.clearFileExportStatus());
        setFile(null)
    };

    return ({
        fileTemplate: {
            download,
            isLoading: isLoadingFileTemplate
        },
        submitFile: {
            file,
            setFile,
            submit: submitFile,
            isLoading: isLoadingFileExport,
            handleCancelFile
        }
    })
}