// @ts-ignore
import { useHistory } from 'react-router-dom';
import { Box, Typography } from "@material-ui/core"
import React from "react"
import { useStyles } from "./styles"
import { ReactComponent  as DefaultCompletion } from '../../../assets/defaultCompletion.svg'

import ProgressToolBar from "../../../components/ProgressToolBar"
import { useDispatch, useSelector } from 'react-redux';
import formatDate from '../../../utils/formatDate';
import { CostCenterFileSliceActions } from '../../../redux/store/CostAndSector/FileUpload/slice';
import { CostCentersResponse } from '../../../redux/store/CostAndSector/FileUpload/types';

interface FileReportFeedback {
    errorsDetailsData: CostCentersResponse;
    successDetailsData: CostCentersResponse;
}

const FeedbackSuccess = ()=> {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()

    const fileStatus = useSelector((state:any) => state?.costCenterFileSlice.fileExportStatus);
    const fileReportFeedback: FileReportFeedback = useSelector((state:any) => state?.costCenterFileSlice.fileReportFeedback);
    
    const fileName = fileStatus?.fileName || '-'

    const allCreatedLocations = fileReportFeedback?.successDetailsData?.totalSize || 0
    
    const pluralMessage = allCreatedLocations > 1 ? 'Os centros de custos foram cadastrados.' : 'O centro de custo foi cadastrado.'


    return (
        <Box className={classes.wrapper}>
            <Box className={classes.header}>
                <Typography variant="h2" className={classes.title}>Cadastro via planilha do Excel</Typography>
            </Box>

            <Box className={`${classes.row} ${classes.successFeedbackContainer}`}>
                <DefaultCompletion />
                <Box className={classes.column} style={{gap: 8}}>
                    <Typography variant="h2" className={classes.successFeedbackTitle}>Cadastro realizado com sucesso!</Typography>
                    <Box className={classes.row}>
                        <Typography variant="h2" className={classes.successFeedbackSubtitle}>{pluralMessage}</Typography>
                    </Box>
                </Box>
            </Box>

            <Box className={classes.confirmationInfoContainer}>
                <Typography variant="h2" className={classes.confirmationInfoTitle}>Detalhes da atualização</Typography>
                <Box className={`${classes.row} ${classes.confirmationInfoContent}`}>
                    <Box className={classes.confirmationInfoCard}>
                        <Typography variant="h2" className={classes.confirmationInfoLabel}>Nome do arquivo</Typography>
                        <Typography variant="h2" className={classes.confirmationInfoValue}>{fileName}</Typography>
                    </Box>
                    <Box className={classes.confirmationInfoCard}>
                        <Typography variant="h2" className={classes.confirmationInfoLabel}>Atualizado em:</Typography>
                        <Typography variant="h2" className={classes.confirmationInfoValue}>{formatDate(new Date(),'DD/MM/YYYY [às] HH:mm')}</Typography>
                    </Box>
                    <Box className={classes.confirmationInfoCard}>
                        <Typography variant="h2" className={classes.confirmationInfoLabel}>Centros de custos criados</Typography>
                        <Typography variant="h2" className={classes.confirmationInfoValue}>{allCreatedLocations}</Typography>
                    </Box>
                </Box>
            </Box>
            
            <ProgressToolBar
                disableNext={false}
                loading={false}
                disableBack={true}
                orangeNextButton={false} 
                onBackClicked={()=>''}
                onNextClicked={()=>{
                    dispatch(CostCenterFileSliceActions.clearReportFeedback())
                    dispatch(CostCenterFileSliceActions.clearFileExportStatus())
                    history.push('/cost-and-sector-center')
                }}
                nextButtonText={'Centro de custo e setor'}
                numberOfSteps={undefined} 
                currentStep={undefined} 
                count={undefined} 
                numberAccount={undefined} 
                textAccount={undefined} 
                hidden={undefined} 
                typeNextButton={undefined} 
                formRef={undefined} 
                placementTooltip={undefined}                
            />
            
        </Box>
    )
}

export default FeedbackSuccess