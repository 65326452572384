import { useDispatch, useSelector } from "react-redux";
import { handleSnackbarStack } from "../../../../utils/handleSnackbarStack";
import { objectIsEmpty } from "../../../../utils/objectIsEmpty";
import { useEffect } from "react";
import { clearCnabDownloadPusherStatus } from "../../../../redux/store/Payroll/DownloadMultipleCnab240";

export const useDownloadCnabReturnFile = ()=> {
    const dispatch = useDispatch()
    const { success, error } = handleSnackbarStack();

    const downloadCnabReturnFile = useSelector((state: any) => state.downloadCnabReturnFilePusher)

    const handleClear = ()=> {
        dispatch(clearCnabDownloadPusherStatus())
    }

    const handleDownload = () => {
        if (!downloadCnabReturnFile || downloadCnabReturnFile.status !== 'PROCESSED') {
            return;
        }
    
        const fileUrl = downloadCnabReturnFile.data;
    
        if (!fileUrl) {
            return error('Houve um erro ao baixar o relatório de onboarding')
        }
    
        const link = document.createElement('a');
        link.href = fileUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    
        setTimeout(()=>{
            handleClear()
        }, 1000)
    };

    useEffect(()=>{
        handleDownload()
    },[downloadCnabReturnFile])

    return {
        data: downloadCnabReturnFile,
        isEmpty: objectIsEmpty(downloadCnabReturnFile),
        actions: {
            handleClear
        }
    }
}