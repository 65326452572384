import React from 'react';
import { TextField as MuiTextField } from '@material-ui/core';

export function TextField({
  fullWidth = true,
  name = '',
  label = '',
  size = 'small',
  variant = 'outlined',
  disabled = false,
  value = '',
  onChange = (value='') => {},
  inputRef = {},
  type = 'text',
  ...otherProps
}) {
  return (
    <MuiTextField
      fullWidth={fullWidth}
      name={name}
      label={label}
      size={size}
      variant={variant}
      disabled={disabled}
      value={value}
      onChange={event => {
        let newValue = event.target.value;

        if (type === 'number') {
          newValue = newValue.replace(/[^0-9]/g, '');
        }

        onChange(newValue);
      }}
      type={type}
      InputProps={{
        style: { borderRadius: '0.5rem' },
      }}
      inputRef={inputRef}
      {...otherProps}
    />
  );
}
