import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentFile } from './types';

type MultipleCnabFilesState = PaymentFile[];

const initialState: MultipleCnabFilesState = [];

const multipleCnabFilesSlice = createSlice({
  name: 'multipleCnabFiles',
  initialState,
  reducers: {
    addCnabFile: (state, action: PayloadAction<PaymentFile>) => {
      state.push(action.payload);
    },
    removeCnabFile: (state, action: PayloadAction<number>) => {
      const index = state.findIndex(file => file.nsu === action.payload);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
    clearMultipleCnabFiles: () => {
      return [];
    },
  },
});

export const { addCnabFile, removeCnabFile, clearMultipleCnabFiles } = multipleCnabFilesSlice.actions;

export default multipleCnabFilesSlice.reducer;