import React, { useState, useRef } from 'react';
import { Box, Button, Menu, MenuItem, Typography } from '@material-ui/core';
import { ReactComponent as IconButtonDot } from '../../../../assets/iconButtonDot.svg';

import useStyles from './styles';

const KebabMenu = ({ 
    onSelectItem,
    items,
    textButton='', 
    anchorOrigin=null,
    transformOrigin=null,
    fromPayRoll=false,
    
    }) => {
    const classes = useStyles();
    const buttonRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleClickKebab = () => {
        setIsOpen(!isOpen);
    };

    const handleClickItem = (selected) => {
        onSelectItem(selected);
        handleClickKebab();
    };

    return (
        <Box className={`${classes.wrapper} kebab-menu`}>
            {textButton && 
                <Typography
                    ref={buttonRef}
                    onClick={handleClickKebab}
                    className={classes.kebabButton}
                >
                    {textButton}
                </Typography>
            }
            { !textButton &&
                <Button
                ref={buttonRef}
                onClick={handleClickKebab}
                className={classes.kebabButton}
                >
                    <IconButtonDot />
                </Button>
            }
            <Menu
                anchorEl={buttonRef.current}
                open={isOpen}
                onClose={handleClickKebab}
                className={fromPayRoll ? classes.kebabMenuPayRoll : classes.kebabMenu}
                anchorOrigin={anchorOrigin || {
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin= {transformOrigin || {
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: {
                        padding: 0,
                    },
                }}
            >
                <Box className={fromPayRoll ? classes.kebabItensContainerPayRoll : classes.kebabItensContainer}>
                    {items.map((item) => {
                        if(item?.isHidden) return <></>

                        return (
                            <MenuItem key={item.key} disabled={item?.isDisabled} onClick={() => handleClickItem(item)}>
                                {item.label}
                            </MenuItem>
                        )
                    })}
                </Box>
            </Menu>
        </Box>
    );
};

export default KebabMenu;
