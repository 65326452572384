import { Box, Typography } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import ReportRoundedIcon from '@material-ui/icons/ReportRounded';
import { useStyles } from './styles';
import LinearProgressBar from '../../../../SharedComponents/ProgressBar';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

interface DownloadInProgressProps {
    title: string,
    subtitle?: string
    progress: number;
    action?: () => void;
    onClose?: () => void;
}

export const DownloadInProgress = ({
    title,
    subtitle,
    progress,
    action,
    onClose
}: DownloadInProgressProps)=> {
    const styles = useStyles();

    return (
        <Box className={styles.concludedMain}>
            <LinearProgressBar
                title={title}
                progressValue={progress}
                width={385}
                height={60}
                titleTop={true}
            />
            <ArrowForwardIosIcon
                className={styles.iconStyle}
                style={{ color: '#52197F' }}
                onClick={() => action && action()}
            />
        </Box>
    )
}