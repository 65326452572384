import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme:Theme) => ({
    wrapper: {
        overflowY: "auto",
        border: "1px solid #E5E5E5",
        borderRadius: "6px",

        "&::-webkit-scrollbar": {
            width: "4px",
            height: "6px",
        },
        "&::-webkit-scrollbar-track": {
            background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#C4C4C4",
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#A0A0A0",
        },
        scrollbarWidth: "thin",
        scrollbarColor: "#C4C4C4 transparent",
    },
    tableHeader: {
        position: "sticky",
        top: 0,
        backgroundColor: theme.palette.background.paper,
        zIndex: 2,
    },
    
}));