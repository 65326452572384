import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  accordionStyle: {
    border: '1px solid #F2F2F2',
    boxShadow: '0px 3.85px 7.7px rgba(114, 114, 114, 0.1)',
    margin: '0 !important',
    borderRadius: 8
  },
  AccordionSummary: {
    paddingBlock: 12,
    paddingInline: 24,
    height: '68px !important',
    marginBlock: '12px !important',
    marginInline: '0 !important',
  },
  accordionContainer: {
    // marginBottom: 20,
  },
  accordionDetails: {
    padding: '20px !important',
  },
  hidden: {
    display: 'none',
  },
});
