const styles = {
  dialogMain: {
    padding: '32px',
  },
  dialogHeader: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
    gap: '50px',
    marginBottom: '24px'
  },
  title: {
    fontWeight: 700,
    fontSize: 20,
    color: '#000000'
  },
  text: {
    fontSize: '14px',
    color: '#666666'
  },
  firstNumberBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    justifyContent: 'left',
    marginBottom: '16px',
  },
  SelectItemsBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  secondNumberBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    justifyContent: 'left',
    marginTop: '32px',
    marginBottom: '16px',
  },
  number: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    backgroundColor: '#52197F1A',
    color: '#52197f',
    fontWeight: 700,
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
  },
  textNumber: {
    fontSize: 14,
    fontWeight: 500,
    color: '#1A1A1A',
  },
  downloadError: {
    display: 'flex',
    gap: '10px',
    backgroundColor: '#CA23171A',
    margin: '32px 0px',
    padding: '14px',
    borderRadius: '5px'
  },
  downloadErrorIcon: {
    backgroundColor: '#CA2317',
    borderRadius: '50%',
    padding: '2px',
    color: '#fff',
    fontSize: '24px'
  },
  textError: {
    fontSize: '14px',
    color: '#5F2120',
  },
  dialogContent: {
    display: 'flex',
    gap: '1.5rem',
    overflow: 'hidden',
    padding: '0px',
  },
  dialogContentOption: {
    display: 'flex',
    width: '16rem',
    height: '3.625rem',
    paddingLeft: '1rem',
    borderRadius: '6px',
    border: '1px solid #52197f',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '24px',
    marginTop: '24px',
    marginBottom: '0px',
    padding: '0px',
  },
  confirmButtonStyle: {
    textTransform: 'uppercase',
  },
};

export default styles;
