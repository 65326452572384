import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        flex: 1
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8
    },
    title: {
        fontWeight: 700,
        fontSize: 34,
        color: '#1B181C'
    },
    subtitle: {
        fontWeight: 400,
        fontSize: 16,
        color: '#666666'
    },
    stepContainer: {
        
    },
    stepTitle: {
        fontWeight: 700,
        fontSize: 18,
        color: '#1B181C'
    },
    stepAlert: {
        backgroundColor: '#FCF5E8',
        color: '#663C00',
        '& .MuiSvgIcon-root':{
            fill: '#E39F17'
        }
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
    column: {
        display: 'flex',
        flexDirection: 'column'
    },
    footer: {
        justifyContent: 'flex-end',
        gap: 12,
        marginTop: 'auto'
    }
}));