/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

interface IOption {
  value: string;
  label: string;
}

interface CustomSelectProps {
  options: IOption[];
  label: string;
  setOptions: (options: IOption[]) => void;
}

export default function CustomSelect({options, label, setOptions}: CustomSelectProps) {
  const classes = useStyles();
  const [selectedOptions, setSelectedOptions] = useState<IOption[]>([]);

  const handleChange = (event: any, value: IOption[]) => {
    setSelectedOptions(value); // Update the state with selected options
    setOptions(value); // Update the parent component with selected options
  };

  return (
    <Box className={classes.root}>
      <Autocomplete
        multiple
        id="tags-outlined"
        options={options}
        onChange={handleChange}
        getOptionLabel={(option) => option.label}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
          />
        )}
      />
    </Box>
  );
}
