import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputAdornment,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { ResponsiveFilters } from '../responsive';

import { useStyles } from '../styles';
import optionStyle from './styles.module.css';
import { api } from '../../../../services/api';
import { isMobile } from '../../../../utils/breakpoints';
import style from './styles.module.css';
import page from './../../../../components/Chat/store/config/reducer';

const Filter = ({
  nsu,
  setData,
  setEmployees,
  setPaymentType,
  setLength,
  setLoading,
  pagination = {},
  situation,
  setSituation,
  costCenterSelected,
  setCostCenterSelected,
  searchText,
  setSearchText,
}) => {
  const currentCompanyCode = sessionStorage.getItem('currentCompanyCode');
  
  const [costCenter, setCostCenter] = useState([]);
  
  const classes = useStyles();

  const page = pagination?.page || 0;
  const size = pagination?.size || 5;

  if (!pagination.size) {
    sessionStorage.setItem('TablePageSize', 5);
  }

  useEffect(() => {
    getCostCenter();
  }, []);

  useEffect(() => {
    getEmployees();
  }, [situation, costCenterSelected, pagination]);

  useEffect(() => {
    const delay = setTimeout(() => {
      getEmployees();
    }, 500);

    return () => clearTimeout(delay);
  }, [searchText]);

  const getEmployees = () => {
    setLoading(true);

    const requestOptions = {
      params: {
        cpfOrName: searchText.toUpperCase(),
        processingStatus: situation === 'Todos' ? '' : situation,
        location: costCenterSelected?.code,
        page,
        size,
      },
    };
    api
      .get(`/employeesPayments/${nsu}`, requestOptions)
      .then(({ data }) => {
        const axuResult = JSON.stringify(data.details);
        setData(data);
        setLength(data.totalSize);
        setEmployees(JSON.parse(axuResult));
        setPaymentType(data.details.shift()?.type);
      })
      .catch(error => {
        return error;
      })
      .finally(() => setLoading(false));
  };

  function getCostCenter() {
    api.get(`companies/${currentCompanyCode}/locations`).then(({ data }) => {
      setCostCenter(data);
    });
  }

  const onSearchChange = event => {
    setSearchText(event.target.value);
  };

  const handleChangeSituation = event => {
    setSituation(event.target.value);
  };

  const handleChangeCostCenter = value => {
    if (value === 'Todos') {
      setCostCenterSelected({
        code: '',
        description: 'Todos',
      });

      return;
    }
    setCostCenterSelected(
      costCenter.filter(
        costCenterItem => costCenterItem.description === value,
      )[0],
    );
  };

  return (
    <div>
      {isMobile ? (
        <ResponsiveFilters
          onSearchChange={onSearchChange}
          handleChangeSituation={handleChangeSituation}
          searchText={searchText}
          setSituation={setSituation}
          situation={situation}
        />
      ) : (
        <div className={classes.inputs}>
          <TextField
            onChange={onSearchChange}
            value={searchText}
            className={classes.inputFilterNameCPF}
            placeholder="Pesquise por nome ou CPF"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search className={classes.searchIcon} />
                </InputAdornment>
              ),
              endAdornment: searchText?.length > 0 && (
                <IconButton
                  className={style.endAdornment}
                  onClick={() => setSearchText('')}
                >
                  <CloseRoundedIcon className={style.endAdornmentIcon} />
                </IconButton>
              ),
            }}
          />

          <FormControl
            variant="outlined"
            className={classes.inputFilterSituation}
          >
            <InputLabel id="outlined-situation">Situação</InputLabel>
            <Select
              labelId="outlined-situation"
              id="outlined-situation"
              value={situation}
              onChange={handleChangeSituation}
              label="Situação"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem className={optionStyle.optionSelect} value="Todos">
                Todos
              </MenuItem>
              <MenuItem className={optionStyle.optionSelect} value={1}>
                Pagamento pendente
              </MenuItem>
              <MenuItem className={optionStyle.optionSelect} value={2}>
                Pago
              </MenuItem>
              <MenuItem className={optionStyle.optionSelect} value={3}>
                Pagamento não realizado
              </MenuItem>
              <MenuItem className={optionStyle.optionSelect} value={4}>
                Em processamento
              </MenuItem>
              <MenuItem className={optionStyle.optionSelect} value={5}>
                Agendado
              </MenuItem>
              <MenuItem className={optionStyle.optionSelect} value={6}>
                Cancelado
              </MenuItem>
              <MenuItem className={optionStyle.optionSelect} value={7}>
                Autorizado
              </MenuItem>
              <MenuItem className={optionStyle.optionSelect} value={8}>
                Ted não Paga
              </MenuItem>
              <MenuItem className={optionStyle.optionSelect} value={9}>
                TED Agendada
              </MenuItem>
              <MenuItem className={optionStyle.optionSelect} value={10}>
                Pagamento Invalido
              </MenuItem>
              <MenuItem className={optionStyle.optionSelect} value={11}>
                Pix não pago
              </MenuItem>
              <MenuItem className={optionStyle.optionSelect} value={12}>
                Pix em processamento
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            className={classes.inputFilterSituation}
          >
            <InputLabel id="cost-center">Centro de custo</InputLabel>
            <Select
              labelId="cost-center"
              id="cost-center"
              value={costCenterSelected?.description}
              onChange={({ target: { value } }) =>
                handleChangeCostCenter(value)
              }
              label="Centro de custo"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem className={optionStyle.optionSelect} value="Todos">
                Todos
              </MenuItem>
              {costCenter?.map(item => (
                <MenuItem key={item.code} value={item.description}>
                  {item.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
    </div>
  );
};

export default Filter;
