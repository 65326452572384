// @ts-ignore
import { useHistory } from 'react-router-dom';
import { Box, Typography } from "@material-ui/core"
import React, { useEffect } from "react"
import { useStyles } from "./styles"
import { Alert } from "@material-ui/lab"
import { FileDropzone } from "../../../components/DropZone"
import Buttons from "../../../components/Buttons"
import ProgressToolBar from "../../../components/ProgressToolBar"
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { useFileUpload } from "../hooks/useFileUpload"
import { pusherStatus } from "../../../enums/pusher"
import { useDispatch, useSelector } from "react-redux"
import GenericModal from "../../../components/GenericModal"
import NiceModal from "@ebay/nice-modal-react"
import { EmployeesFileSliceActions } from '../../../redux/store/Employees/FileUpload/slice';

export const handleInvalidFile = (handleClick: ()=>void) => {
    return (
        NiceModal.show(GenericModal, {
            title: 'Ocorreu um erro no processamento do arquivo',
            subtitle: 'Verifique sua conexão e tente novamente.',
            nextButton: {
                title: 'TENTAR NOVAMENTE',
                disabled: false,
                isLoading: false,
                onClick: handleClick,
                color: 'secondary',
                closeButtonAfterOnclick: true
            }
        })
    )
};

const FileUpload  = () => {
    const classes = useStyles();
    const history = useHistory();
    const { fileTemplate, submitFile } = useFileUpload()
    const employeesFileExportStatus = useSelector((state: any) => state.employeesFileSlice.fileExportStatus);
    const currentStatus = employeesFileExportStatus?.status

    const handlePusherError = () => {
        return (
            NiceModal.show(GenericModal, {
                title: 'Preenchimento de arquivo incorreto',
                subtitle: 'Verifique se você preencheu o arquivo modelo corretamente e tente novamente.',
                nextButton: {
                    title: 'VERIFICAR ERROS',
                    disabled: false,
                    isLoading: false,
                    onClick: ()=>history.push('/employees/file-error-report'),
                }
            })
        )
    };

    const handleSuccess = () => {
        return (
            NiceModal.show(GenericModal, {
                title: 'Processamento concluído com sucesso!',
                subtitle: 'Clique em “CONTINUAR” para seguir com o processo',
                nextButton: {
                    title: 'CONTINUAR',
                    disabled: false,
                    isLoading: false,
                    onClick: ()=>history.push('/employees/file-error-report'),
                    color: 'primary'
                }
            })
        )
    };

    const handleCancelConfirm = () => {
        return (
            NiceModal.show(GenericModal, {
                title: 'Tem certeza que quer cancelar o processamento do arquivo?',
                subtitle: 'Você perderá o progresso processado até agora e essa ação não poderá ser desfeita.',
                cancelButton: {
                    title: 'NÃO',
                    disabled: false,
                    isLoading: false,
                    onClick: ()=> {},
                    color: 'primary',
                    variant: 'text',
                },
                nextButton: {
                    title: 'SIM',
                    disabled: false,
                    isLoading: false,
                    onClick: ()=> submitFile.handleCancelFile(),
                    color: 'secondary'
                }
            })
        )
    };
    
    const modalMapp: any = {
        'PROCESSED_UPDATE_EMPLOYEE_FILE_ERROR': handlePusherError,
        'PROCESSED_UPDATE_EMPLOYEE_FILE_SUCCESS': handleSuccess,
        'UNPROCESSABLE_FILE': () => handleInvalidFile(submitFile.handleCancelFile),
    }

    useEffect(()=>{
        const currentModal = modalMapp[currentStatus]
        !!currentModal && currentModal()

        return () => {
            NiceModal.hide(GenericModal)
        };
    },[currentStatus])

    return (
        <>
            <Box className={classes.wrapper}>
                <Box className={classes.header}>
                    <Typography variant="h2" className={classes.title}>Atualização via planilha do Excel</Typography>
                    <Typography variant="h4" className={classes.subtitle}>Atualize os dados dos colaboradores a partir de um arquivo no modelo XLS</Typography>
                </Box>
                <Box className={classes.stepContainer}>
                    <Box className={classes.row} style={{ justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="caption" className={classes.stepTitle}>1. Comece fazendo o download do modelo da planilha.</Typography>
                        <Buttons.PrimaryButton
                            size="large"
                            color="primary"
                            variant="text"
                            icon={<GetAppOutlinedIcon /> as any}
                            title="Baixar modelo"
                            loading={fileTemplate.isLoading}
                            disabled={fileTemplate.isLoading}
                            onClick={fileTemplate.download}
                        />
                    </Box>
                    <Alert severity='error' className={classes.stepAlert}>Após o download, utilize o modelo XLS e preencha os dados para CPF, Centro de Custo, Setor e Matrícula para atualizá-los.</Alert>
                </Box>
                <Box className={classes.stepContainer}>
                    <Typography variant="caption" className={classes.stepTitle}>2. Envie o arquivo preenchido.</Typography>
                    <Alert severity='error' className={classes.stepAlert}>Com a planilha preenchida, envie o arquivo utilizando o botão abaixo.</Alert>
                    <Alert severity='error' style={{marginTop: 16}} className={classes.stepAlert}>Apenas os campos preenchidos serão atualizados, os que ficarem em branco permanecerão inalterados.</Alert>
                </Box>
                <Box className={classes.wrapper}>
                    <FileDropzone 
                        progress={employeesFileExportStatus?.percentage || 0}
                        progressActive={employeesFileExportStatus?.status}
                        fileName={employeesFileExportStatus?.fileName}
                        onDrop={submitFile.setFile}
                        onRemove={submitFile.handleCancelFile}
                        onCancel={handleCancelConfirm}
                        statusDescription={employeesFileExportStatus?.statusDescription}
                        accept={{
                            "application/vnd.ms-excel": [".xls"],
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"]
                        }}
                        listeners={[
                            {
                                observer: employeesFileExportStatus?.fileName,
                                action: (removeFile)=>{
                                    if(currentStatus === 'UNPROCESSABLE_FILE'){  
                                        removeFile()
                                    }
                                }
                            }
                        ]}
                    />
                </Box>
                
                <ProgressToolBar
                    disableNext={submitFile.isLoading || !submitFile.file || currentStatus === pusherStatus.PROCESSING}
                    loading={submitFile.isLoading}
                    onBackClicked={() => history.push('/employees')}
                    onNextClicked={submitFile.submit}
                    nextButtonText="Prosseguir" 
                    numberOfSteps={undefined} 
                    currentStep={undefined} 
                    count={undefined} 
                    numberAccount={undefined} 
                    textAccount={undefined} 
                    orangeNextButton={undefined} 
                    hidden={undefined} 
                    typeNextButton={undefined} 
                    formRef={undefined} 
                    placementTooltip={undefined}                
                />
            </Box>
        </>
    )
}

export default FileUpload 