// @ts-ignore
import { useHistory } from 'react-router-dom';
import { Box, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useStyles } from "./styles"
import ProgressToolBar from '../../../components/ProgressToolBar';
import SimpleAccordion from '../../../components/SimpleAccordion';
import { ReactComponent as WarnCircleRed } from '../../../assets/warnCircleRed.svg';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import formatCPF from '../../../utils/formatCPF';
import { useDispatch, useSelector } from 'react-redux';
import { useConfirmFileMutation, useGetImportDetailsQuery, useGetSuccessImportDetailsQuery, useGetErrorsImportDetailsQuery } from '../../../redux/store/Employees/FileUpload/api';
import { ReactComponent as CheckGreen } from '../../../assets/checkGreen.svg';
import { Table } from '../../../components/Table';
import formatDate from '../../../utils/formatDate';
import { handleSnackbarStack } from '../../../utils/handleSnackbarStack';
import { EmployeesFileSliceActions } from '../../../redux/store/Employees/FileUpload/slice';
import { Pagination, Skeleton } from '@material-ui/lab';
import { mockFileUploadResponse } from './mock';


const FileErrorReport  = () => {
    const classes = useStyles()
    const history = useHistory();
    const { error } = handleSnackbarStack();
    const dispatch = useDispatch()

    const [paginationSuccess, setPaginationSuccess] = useState({
        page: 0,
        size: 5
    })

    const [paginationError, setPaginationError] = useState(0)

    const employeesFileExportStatus = useSelector((state: any) => state.employeesFileSlice.fileExportStatus);

    
    const { data: successDetailsData, isFetching } = useGetSuccessImportDetailsQuery({
        fileId: employeesFileExportStatus?.fileId,
        params: {
            page: paginationSuccess.page,
            size: paginationSuccess.size,
        }
    },{
        skip: !employeesFileExportStatus?.fileId
    })

    const { data: errorsDetailsData, isFetching: isFetchingErrorDetail } = useGetErrorsImportDetailsQuery({
        fileId: employeesFileExportStatus?.fileId,
        params: {
            page: paginationError,
            size: 10,
            sort: ['lineNumber']
        }
    },{
        skip: !employeesFileExportStatus?.fileId
    })

    const totalPerPage = 10

    const totalErrorsPages = Math.ceil(((errorsDetailsData?.totalSize || 0) / totalPerPage))
    
    const { data, isFetching: isFetchingFileExport } = useGetImportDetailsQuery({
        processId: employeesFileExportStatus?.processId
    },{
        skip: !employeesFileExportStatus?.processId
    }
)

    const [ confirmFile, { isLoading } ] = useConfirmFileMutation()

    useEffect(()=>{
        dispatch(EmployeesFileSliceActions.setFileReportFeedback(data))
    },[data])

    const tableConfig = [
        {
            key: 'cpf',
            type: 'field',
            align: 'left',
            label: 'CPF',
            render: (row: any) => formatCPF(row?.cpf),
        },
        {
            key: 'registration',
            type: 'field',
            align: 'left',
            label: 'Matrícula',
        },
        {
            key: 'location',
            type: 'field',
            align: 'left',
            label: 'Centro de custo',
        },
        {
            key: 'stocking',
            type: 'field',
            align: 'left',
            label: 'Setor',
        },
    ];

    const handleConfirmFile = ()=> {
        confirmFile({ fileId: employeesFileExportStatus?.fileId })
        .then(() => {
            if(!!data?.success){
                history.push('/employees/file-feedback-success')
                return
            }

            history.push('/employees/file-update-with-errors')
        })
        .catch(()=>{
            error('Não foi possível validar suas informações. Por favor, revise os dados e tente novamente.')
        })
    }

    const titleAndSubtitleMapping: any = {
        'PROCESSED_UPDATE_EMPLOYEE_FILE_ERROR': {
            title: 'Identificamos os seguintes problemas',
            subtitle: 'Por favor, corrija os erros na sua planilha antes de enviá-la novamente.',
        },
        'PROCESSED_UPDATE_EMPLOYEE_FILE_SUCCESS': {
            title: 'Confirme a atualização via planilha do Excel',
            subtitle: '',
        }
    }

    const backButtonMapping: any = {
        'PROCESSED_UPDATE_EMPLOYEE_FILE_SUCCESS': {
            title: 'Voltar',
            actions: ()=> {
                dispatch(EmployeesFileSliceActions.clearFileExportStatus())
                dispatch(EmployeesFileSliceActions.clearReportFeedback())
                history.push("/employees/file-upload")
            }
        },
        'PROCESSED_UPDATE_EMPLOYEE_FILE_ERROR_WITH_SUCCESS': {
            title: 'Reenviar planilha',
            actions: ()=> {
                dispatch(EmployeesFileSliceActions.clearFileExportStatus())
                dispatch(EmployeesFileSliceActions.clearReportFeedback())
                history.push("/employees/file-upload")
            }
        },
    }

    const nextButtonMapping: any = {
        'PROCESSED_UPDATE_EMPLOYEE_FILE_ERROR': {
            title: 'Reenviar planilha',
            actions: ()=> {
                dispatch(EmployeesFileSliceActions.clearFileExportStatus())
                dispatch(EmployeesFileSliceActions.clearReportFeedback())
                history.push("/employees/file-upload")
            }
        },
        'PROCESSED_UPDATE_EMPLOYEE_FILE_SUCCESS': {
            title: 'Confirmar',
            actions: ()=> {
                handleConfirmFile()
            }
        },
        'PROCESSED_UPDATE_EMPLOYEE_FILE_ERROR_WITH_SUCCESS': {
            title: 'Ignorar erros e confirmar',
            isOrangeButton: true,
            actions: ()=> {
                handleConfirmFile()
            }
        },
    }

    const currentTitleAndSubtitle = titleAndSubtitleMapping[employeesFileExportStatus?.status]

    const statusToButtons = `${ !!data?.successEmployees?.length && employeesFileExportStatus?.status === 'PROCESSED_UPDATE_EMPLOYEE_FILE_ERROR' ? `${employeesFileExportStatus?.status}_WITH_SUCCESS` : employeesFileExportStatus?.status}`
    const currentBackButtonProps = backButtonMapping[statusToButtons]
    const currentNextButtonProps = nextButtonMapping[statusToButtons]

    const handleChangeErrorPage = (_:any, value: any) => {
        setPaginationError(value - 1)
    }

    if(!employeesFileExportStatus) return history.push('/employees/file-upload')

    return (
        <>
            <Box className={classes.wrapper}>
                <Box className={classes.header}>
                    <Typography variant="h2" className={classes.title}>{currentTitleAndSubtitle?.title}</Typography>
                    <Typography variant="h4" className={classes.subtitle}>{currentTitleAndSubtitle?.subtitle}</Typography>
                </Box>

                {data?.success && (
                    <Box className={classes.confirmationInfoContainer}>
                        <Typography variant="h2" className={classes.confirmationInfoTitle}>As informações abaixo estão corretas?</Typography>
                        <Box className={`${classes.row} ${classes.confirmationInfoContent}`}>
                            <Box className={classes.confirmationInfoCard}>
                                <Typography variant="h2" className={classes.confirmationInfoLabel}>Nome do arquivo</Typography>
                                <Typography variant="h2" className={classes.confirmationInfoValue}>{data?.fileName}</Typography>
                            </Box>
                            <Box className={classes.confirmationInfoCard}>
                                <Typography variant="h2" className={classes.confirmationInfoLabel}>Data de atualização</Typography>
                                <Typography variant="h2" className={classes.confirmationInfoValue}>{formatDate(new Date(), 'DD/MM/YYYY')}</Typography>
                            </Box>
                        </Box>
                    </Box>
                )}

                {!!errorsDetailsData?.employees?.length && (
                    <SimpleAccordion
                        title={
                            <Box className={classes.accordionTitleContainer}>
                                <WarnCircleRed />
                                <Box className={classes.accordionTitleColumContainer}>
                                    <Box className={classes.accordionTextContainer}>
                                    <Typography className={classes.accordionTitleEvidence}>
                                        {errorsDetailsData?.totalSize} colaborador{errorsDetailsData?.totalSize > 1 && 'es'}
                                    </Typography>
                                    <Typography className={classes.accordionTitle}>
                                        com problema{errorsDetailsData?.totalSize > 1 && 's'}
                                    </Typography>
                                    </Box>
                                    <Typography className={classes.accordionTitle}>
                                        Verifique os motivos dos erros abaixo e reenvie o arquivo.
                                    </Typography>
                                </Box>
                            </Box>
                        }
                        content={
                            <Box className={classes.column} style={{width: '100%'}}>
                                <Box className={`${classes.row} ${classes.cardsGroupContainer}`}>
                                    <Box className={`${classes.column} ${classes.cardContainer}`}>
                                        <Box className={`${classes.row} ${classes.cardTitleContainer}`}>
                                            <Box className={`${classes.cardIndex} green`}/>
                                            <Typography variant="subtitle2" className={classes.cardLabelContainer}>
                                                Nome do arquivo
                                            </Typography>
                                        </Box>
                                        <Typography className={classes.cardLabelValue}>
                                            {data?.fileName || '-'}
                                        </Typography>
                                    </Box>
                                    <Box className={`${classes.column} ${classes.cardContainer}`}>
                                        <Box className={`${classes.row} ${classes.cardTitleContainer}`}>
                                            <Box className={classes.cardIndex}/>
                                            <Typography variant="subtitle2" className={classes.cardLabelContainer}>
                                                Total de erros
                                            </Typography>
                                        </Box>
                                        <Typography className={classes.cardLabelValue}>
                                            {data?.errorsQuantity || 0}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box className={classes.itemsErrorsContainer}>
                                    {errorsDetailsData?.employees?.map(currentError => (
                                        <>
                                            { isFetchingErrorDetail && (
                                                <Box>
                                                    <Skeleton className={classes.skeletonAccordion}/>
                                                </Box>
                                            )}
                                            { !isFetchingErrorDetail && (
                                                <SimpleAccordion
                                                    title={
                                                        <Box className={`${classes.row} ${classes.errorItem}`}>
                                                            <Box className={classes.currentErrorContainer}>
                                                                <Box className={classes.errorCardLabelContainer}>
                                                                <Typography className={classes.errorCardLabelText}>
                                                                    Linha do arquivo
                                                                </Typography>
                                                                </Box>
                                                                <Typography className={classes.errorCardValue}>
                                                                {currentError?.lineNumber || '-'}
                                                                </Typography>
                                                            </Box>
                                                            <Box className={classes.currentErrorContainer}>
                                                                <Box className={classes.errorCardLabelContainer}>
                                                                <Typography className={classes.errorCardLabelText}>
                                                                    CPF
                                                                </Typography>
                                                                </Box>
                                                                <Typography className={classes.errorCardValue}>
                                                                {formatCPF(currentError?.cpf)}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    }
                                                    content={
                                                        <Box className={classes.errorContainer}>
                                                            {currentError.errors?.map(
                                                                (causeError, index) => (
                                                                <Box className={classes.accordionTitleContainer}>
                                                                    <ErrorOutlineIcon className={classes.errorIcon} />
                                                                    <Box className={classes.accordionTitleColumContainer}>
                                                                    <Typography
                                                                        variant="caption"
                                                                        className={classes.errorLabel}
                                                                    >
                                                                        {`Motivo do erro ${index + 1}`}
                                                                    </Typography>
                                                                    <Box className={classes.accordionTextContainer}>
                                                                        <Typography className={classes.infoValue}>
                                                                            {causeError}
                                                                        </Typography>
                                                                    </Box>
                                                                    </Box>
                                                                </Box>
                                                                ),
                                                            )}
                                                        </Box>
                                                    }
                                                />
                                            )}
                                        </>
                                    ))}
                                </Box>
                                
                                <Box className={classes.paginationContainer}>
                                    <Pagination count={totalErrorsPages} page={paginationError  + 1} onChange={handleChangeErrorPage} />
                                </Box>
                            </Box>
                        }
                    />
                )}

                {!!successDetailsData?.employees?.length && (
                    <SimpleAccordion
                    title={
                        <Box className={classes.accordionTitleContainer}>
                            <CheckGreen />
                            <Box className={classes.accordionTitleColumContainer}>
                                <Box className={classes.accordionTextContainer}>
                                    <Typography className={classes.accordionTitleEvidence}>
                                    { successDetailsData?.employees?.length } colaborador{ successDetailsData?.employees?.length > 1 && 'es'}
                                    </Typography>
                                    <Typography className={classes.accordionTitle}>
                                    válido{ successDetailsData?.employees?.length > 1 && 's'}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        }
                        content={
                            <Table
                                identifier="cpf"
                                data={successDetailsData?.employees as any}
                                length={successDetailsData?.totalSize}
                                tableConfig={tableConfig as any}
                                hasPagination={true}
                                disableSelection={undefined}
                                isLoading={isFetching}
                                requests={(page: any,size: any): void => {
                                    setPaginationSuccess({
                                        page,
                                        size
                                    })
                                }}
                            />
                        }
                    />
                )}

                {isFetchingFileExport && !errorsDetailsData?.employees?.length && (
                    <Box style={{display: 'flex', gap: 24, flexDirection: 'column'}}>
                        <Box className={classes.skeletonAccordionContainer}>
                            <Skeleton className={classes.skeletonAccordion} style={{ position: 'absolute', width: '100%'}}/>
                            <Box className={classes.accordionTitleContainer}>
                                <Skeleton className={classes.skeletonAccordionIcon}/>
                                <Box className={classes.accordionTitleColumContainer}>
                                    <Skeleton component="title" width={195}/>
                                    <Skeleton component="title" width={321}/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                )}

                {isFetchingFileExport && !successDetailsData?.employees?.length && (
                    <Box style={{display: 'flex', gap: 24, flexDirection: 'column'}}>
                        <Box className={classes.skeletonAccordionContainer}>
                            <Skeleton className={classes.skeletonAccordion} style={{ position: 'absolute', width: '100%'}}/>
                            <Box className={classes.accordionTitleContainer}>
                                <Skeleton className={classes.skeletonAccordionIcon}/>
                                <Box className={classes.accordionTitleColumContainer}>
                                    <Skeleton component="title" width={195}/>
                                    <Skeleton component="title" width={321}/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                )}
                
                <ProgressToolBar
                    disableNext={isLoading}
                    loading={isLoading}
                    backButtonText={currentBackButtonProps?.title}
                    onBackClicked={currentBackButtonProps?.actions}
                    disableBack={!data?.successEmployees?.length}
                    onNextClicked={currentNextButtonProps?.actions}
                    nextButtonText={currentNextButtonProps?.title}
                    numberOfSteps={undefined} 
                    currentStep={undefined} 
                    count={undefined} 
                    numberAccount={undefined} 
                    textAccount={undefined} 
                    orangeNextButton={currentNextButtonProps?.isOrangeButton} 
                    hidden={undefined} 
                    typeNextButton={undefined} 
                    formRef={undefined} 
                    placementTooltip={undefined}                
                />
            </Box>
        </>
    )
}

export default FileErrorReport 