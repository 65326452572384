export const downloadReceiptTypesToModal = {
    ALL: 'all',
    COST: 'by_cost_center',
    SECTOR: 'by_sector',
};

export const costCenterOptions = [
    { value: 'all', label: 'Todos' },
    { value: 'financial', label: 'Financeiro' },
    { value: 'commercial', label: 'Comercial' },
];