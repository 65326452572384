export default function brazilianCurrencyFormatter(value: number): string {
  if (isNaN(value) || value === Infinity || value === -Infinity) {
    return 'R$ 0,00';
  }

  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(Math.abs(value));

  return value < 0 ? amount.replace('R$', 'R$ -') : amount;
}
