import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ActionsPayrollDetails } from '../../../redux/store/Payroll/PayrollDetails';
import { pusherStatus } from '../../../enums/pusher';
import { handleSnackbarStack } from '../../../utils/handleSnackbarStack';

const usePaymentsNsuDownload = () => {
    const dispatch = useDispatch();

    const { success: snackbarSuccess, error: snackbarError } = handleSnackbarStack();

    const handlePusherData = useCallback((pusherData) => {
        if (!pusherData) return;

        if (pusherData.status === pusherStatus.PROCESSING) {
            dispatch(ActionsPayrollDetails.setDownloadReceiptsPercentageFromSeeDetailsTable(pusherData.percentage));
            dispatch(ActionsPayrollDetails.setDownloadingNSUProofPercentage(pusherData));
            return;
        }
        if (pusherData.status === pusherStatus.PROCESSED) {
            window.open(pusherData.data, '_blank');
        
            dispatch(ActionsPayrollDetails.setDownloadReceiptsPercentageFromSeeDetailsTable(null));
            snackbarSuccess('Comprovantes gerados com sucesso!');
            return;
        }

        if (pusherData.status === pusherStatus.PROCESSED_RECEIPT_PAYMENT_ERROR) {
            dispatch(ActionsPayrollDetails.setDownloadingNSUProofError(pusherData.data));
            snackbarError(pusherData.statusDescription || 'Houve um erro ao enviar a lista de comprovantes');
            return;
        }
    }, [dispatch, snackbarSuccess, snackbarError]);

    return handlePusherData;
};

export default usePaymentsNsuDownload;
