import React, { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useStyles } from './styles';
import { Divider } from '@material-ui/core';

interface SimpleAccordionProps {
  title: React.JSX.Element;
  content: React.JSX.Element;
  disabled?: boolean;
  keepOpen?: boolean;
}

export default function SimpleAccordion({
  title,
  content,
  disabled,
  keepOpen
}: SimpleAccordionProps) {
  const classes = useStyles();

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Accordion className={classes.accordionStyle} expanded={keepOpen || isExpanded}>
      <AccordionSummary
        id="panel1a-header"
        aria-controls="panel1a-content"
        onClick={() => (!keepOpen || !disabled) && setIsExpanded(prevState => !prevState)}
        expandIcon={<ExpandMoreIcon />}
        className={classes.AccordionSummary}
      >
        {title}
      </AccordionSummary>
      <Divider />
      <AccordionDetails className={classes.accordionDetails}>
        {content}
      </AccordionDetails>
    </Accordion>
  );
}
