import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import {
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Drawer,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';

import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ReportRoundedIcon from '@material-ui/icons/ReportRounded';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import LinearProgressBar from '../../SharedComponents/ProgressBar';
import { ActionsPayrollDetails } from '../../../redux/store/Payroll/PayrollDetails';
import { ReactComponent as DownloadButton } from '../../../assets/download-button.svg';

import { useStyles } from './styles';
import { style } from './../../../pages/Extract/styles';

const useStylesToolTip = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: '#4D4D4D',
    color: 'white',
    minWidth: 320,
    fontSize: '12px',
    padding: theme.spacing(2),
    borderRadius: '4px',
    textAlign: 'justify',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  },
  customArrow: {
    color: '#4D4D4D',
  },
  divButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  helperText: {
    backgroundColor: 'transparent',
    color: '#FFF',
    fontSize: '12px',
    fontWeight: 500,
  },
  actionButton: {
    backgroundColor: 'transparent',
    color: '#FFF',
    fontSize: '14px',
    fontWeight: 500,
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0',
      color: '#000',
    },
  },
}));

export default function DownloadingProgress() {
  const history = useHistory();
  const dispatch = useDispatch();
  const styles = useStyles();
  const classes = useStylesToolTip();

  const {downloadingNSUProof} = useSelector(state => state.payroll.payrollDetails);
  const [showTooltipAlternative, setShowTooltipAlternative] = useState(true);
  const [open, setOpen] = useState(false);
  
  const downloadsConcluded = downloadingNSUProof.filter((item) => item.downloadReceiptsPercentage === 100);
  const downloadsInProgress = downloadingNSUProof.filter((item) => item.downloadReceiptsPercentage !== 100);
  const downloadError = downloadingNSUProof.some((item) => item.error === true);

  const toggleMenu = () => {
    setOpen(!open);
  };

  function goToSeeDetails(nsu) {
    dispatch(ActionsPayrollDetails.setNsuFromProgressBar(nsu));
    setOpen(false);
    history.push(`/payroll/details`);
  }

  function handleClose(nsu) {
    dispatch(ActionsPayrollDetails.removeNsuFromProgressBar(nsu));
  }

  useEffect(() => {
    if (downloadsConcluded.length > 0) {
      setTimeout(() => {
        downloadsConcluded.map(item => {
          dispatch(ActionsPayrollDetails.removeNsuFromProgressBar(item?.nsu));
        })
      }, 5000);
    }
  }, [downloadingNSUProof]);

  const showTooltipAlternativeFromLocalStorage = localStorage.getItem('showTooltipAlternative');

  function handleSetShowTooltipAlternative() {
    localStorage.setItem('showTooltipAlternative', 'false');
    setShowTooltipAlternative(false);
  };

  useEffect(() => {
    setShowTooltipAlternative(showTooltipAlternativeFromLocalStorage === 'false' ? false : true);
  }, [showTooltipAlternativeFromLocalStorage, showTooltipAlternative]);

  const renderIcon = () => {
    return (
      <Tooltip
        arrow
        classes={{
          tooltip: showTooltipAlternative && classes.customTooltip,
          arrow: showTooltipAlternative && classes.customArrow,
        }}
        title={
          showTooltipAlternative ? (
            <div onClick={(e) => e.stopPropagation()} style={{ pointerEvents: 'auto' }}>
              <div  className={classes.helperText}>
                Os comprovantes estão sendo baixados. Clique no botão acima para
                visualizar o progresso.
              </div>
              <div className={classes.divButton}>
                <Button
                  onClick={handleSetShowTooltipAlternative}
                  className={classes.actionButton}
                >
                  ENTENDI
                </Button>
              </div>
            </div>
          ) : (
            'Downloads'
          )
        }
        open={showTooltipAlternativeFromLocalStorage !== 'false' ? true : undefined}
        interactive
      >
        <IconButton
          onClick={toggleMenu}
          className={styles.buttonIcon}
        >
          <Badge color="primary">
            <DownloadButton />
          </Badge>
          {downloadError ? (
            <ErrorOutlineRoundedIcon className={styles.iconErrorTop} />
          ) : downloadsInProgress.length < 1 ? (
            <CheckIcon className={styles.iconAllDownloadConcluded} />
          ) : (
            <Typography className={styles.count} variant="caption">
              {downloadingNSUProof.length}
            </Typography>
          )}
        </IconButton>
      </Tooltip>
    );
  };
  
  return (
    <>
      {renderIcon()}
      <Drawer
        classes={{
          // root: styles.drawerDownload,
        }}
        elevation={10}
        id="notifications-drawer"
        anchor="right"
        open={open}
        onClose={toggleMenu}
        PaperProps={{
          className: styles.root,
        }}
        BackdropProps={{ invisible: true }}
      >
        {downloadingNSUProof.map(nsu => (
          <Box key={nsu.nsu} className={styles.positionWrapper}>
            {!nsu.error && nsu.downloadReceiptsPercentage < 100
              ? <>
                <LinearProgressBar
                  title={`Gerando comprovantes - NSU: ${nsu.nsu}`}
                  progressValue={nsu.downloadReceiptsPercentage}
                  width={385}
                  height={60}
                  titleTop={true}
                />
                <ArrowForwardIosIcon
                  className={styles.iconStyle}
                  style={{ color: '#52197F' }}
                  onClick={() => goToSeeDetails(nsu.nsu)} />
              </>
              : <Box className={styles.concludedMain}>
                  <Box className={styles.concludedBox}>
                    <Typography variant='body1'>Geração de comprovantes</Typography>{}
                    <Box>
                      {nsu.error
                        ? <Box className={'ErrorTeste', styles.error} >
                            <ReportRoundedIcon className={styles.iconError} />
                            <Typography variant='caption' className={styles.errorText} >Ocorreu um erro na geração dos comprovantes.</Typography>
                        </Box>
                      : <Box>
                          <CheckIcon className={styles.iconCheck} />
                          <Typography variant='caption' className={styles.concludedText} >Arquivos gerados com sucesso.</Typography>
                        </Box>}
                    </Box>
                  </Box>
                  <CloseIcon
                    className={styles.iconStyle}
                    style={{ color: '#52197F' }}
                    onClick={() => handleClose(nsu.nsu)}/>
                </Box>
            }
          </Box>
        ))}
      </Drawer>
    </>
  );
}
