/* eslint-disable prefer-destructuring */
import { useEffect, useMemo, useRef, useState } from 'react';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const PER_PAGE_KEY = 'TablePageSize';

export const useTable = ({
  length = 0,
  rowsPerPageOptions = [5, 10, 50, 100, 200],
  tableConfig = [],
  resetPageWhen = [],
  requests = () => {},
}) => {
  const orderBy = useRef('');
  const orderDirection = useRef('asc');
  const cardListBlock = useRef(10);
  const hasOrdination = tableConfig?.some(item => item?.hasOrder);

  const [ page, setPage ] = useState(0)
  const [ perPage, setPerPage ] = useState(
    parseInt(sessionStorage.getItem(PER_PAGE_KEY)) || rowsPerPageOptions[0]
  )

  const theme = useTheme();
  const isMobile = useMediaQuery(
    useMemo(() => theme.breakpoints.down('sm'), [
      useMediaQuery(theme.breakpoints.down('sm')),
    ]),
  );

  const handlePageChange = (_, newPage) => {
    setPage(Number(newPage));

    if (hasOrdination) {
      requests(
        newPage,
        perPage,
        orderBy.current,
        orderDirection.current,
      );
    } else {
      requests(newPage, perPage);
    }
  };

  const handleRowsPerPageChange = event => {
    const newPerPage = Number(event?.target?.value);
    setPage(0);
    setPerPage(newPerPage);
    sessionStorage.setItem(PER_PAGE_KEY, newPerPage);
  
    cardListBlock.current = newPerPage;
  
    if (hasOrdination) {
      requests(0, newPerPage, orderBy.current, orderDirection.current);
    } else {
      requests(0, newPerPage);
    }
  };

  const handleSortChange = columnId => _ => {
    if (orderBy.current === columnId) {
      orderDirection.current =
        orderDirection.current === 'asc' ? 'desc' : 'asc';

      orderBy.current = columnId;
    } else {
      orderDirection.current = 'asc';
      orderBy.current = columnId;
    }

    requests(
      page,
      perPage,
      orderBy.current,
      orderDirection.current,
    );
  };

  const handleFetchMoreCards = () => {
    if (cardListBlock.current < length) {
      cardListBlock.current =
        length - (perPage + 10) < 0 ? length : perPage + 10;

      setPerPage(Number(length - (perPage + 10) < 0 ? length : perPage + 10));

      requests(0, cardListBlock.current);
    }
  };

  useEffect(() => {
    return () => {
      setPage(0);
      setPage(rowsPerPageOptions[0]);
    };
  }, []);

  useEffect(()=>{
    setPage(0)
  },[length, ...resetPageWhen]);

  useEffect(() => {
    sessionStorage.setItem(PER_PAGE_KEY, perPage);
  }, [perPage]);

  useEffect(() => {
    requests(page, perPage);
  }, []);

  return {
    isMobile,
    hasOrdination,
    page: page,
    perPage: perPage,
    orderBy: orderBy.current,
    orderDirection: orderDirection.current,
    handlePageChange,
    handleRowsPerPageChange,
    handleSortChange,
    handleFetchMoreCards,
  };
};
