// @ts-ignore
import { useHistory } from 'react-router-dom';
import { Box, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useStyles } from "./styles"
import ProgressToolBar from '../../../components/ProgressToolBar';
import SimpleAccordion from '../../../components/SimpleAccordion';
import { ReactComponent as WarnCircleRed } from '../../../assets/warnCircleRed.svg';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import formatCPF from '../../../utils/formatCPF';
import { useDispatch, useSelector } from 'react-redux';
import { useConfirmCostCenterFileMutation, useGetCostCenterSuccessImportDetailsQuery, useGetCostCenterErrorsImportDetailsQuery } from '../../../redux/store/CostAndSector/FileUpload/api';
import { ReactComponent as CheckGreen } from '../../../assets/checkGreen.svg';
import { Table } from '../../../components/Table';
import formatDate from '../../../utils/formatDate';
import { handleSnackbarStack } from '../../../utils/handleSnackbarStack';
import { CostCenterFileSliceActions } from '../../../redux/store/CostAndSector/FileUpload/slice';
import { Pagination, Skeleton } from '@material-ui/lab';
import { mockFileUploadResponse } from './mock';
import NiceModal from '@ebay/nice-modal-react';
import GenericModal from '../../../components/GenericModal';

const mockPusherResponse = {
    "processId": "7ad21594-194b-4c6a-99e7-05c1e4a887fe",
    "fileName": "location_create_file_model00 (1).xlsx",
    "companyCode": "8a818e217db391aa017db559656b6547",
    "titleProcess": "Processando linhas do arquivo de criação de centro de custo para a empresa 8a818e217db391aa017db559656b6547",
    "percentage": 100,
    "status": "PROCESSED_CREATE_LOCATION_FILE_SUCCESS",
    "statusDescription": "Conferindo dados (2/2)"
}

const mockSuccess = {
    "totalSize": 1,
    "currentPage": 0,
    "currentPageSize": 5,
    "locations": [
        {
            "name": "MARINHO LOCACAO",
            "code": "2030",
            "lineNumber": 1
        }
    ]
}

const mockError = {
    "totalSize": 1,
    "currentPage": 0,
    "currentPageSize": 5,
    "locations": [
        {
            "name": "MARINHO LOCACAO",
            "code": "2030",
            "lineNumber": 1,
            "errors": [
                "Centro de custo não encontrado na base de dados",
                "Setor não encontrado na base de dados"
            ]
        }
    ]
}

const FileErrorReport  = () => {
    const classes = useStyles()
    const history = useHistory();
    const { error } = handleSnackbarStack();
    const dispatch = useDispatch()

    const [paginationSuccess, setPaginationSuccess] = useState({
        page: 0,
        size: 5
    })

    const [paginationError, setPaginationError] = useState(0)

    const costCenterFileExportStatus = useSelector((state: any) => state.costCenterFileSlice.fileExportStatus);

    // const successDetailsData = mockSuccess

    // const errorsDetailsData = mockError
    
    const mockDevFileId = 44

    const { data: successDetailsData, isFetching: isFetchingSuccessDetail } = useGetCostCenterSuccessImportDetailsQuery({
        fileId: costCenterFileExportStatus?.fileId,
        params: {
            page: paginationSuccess.page,
            size: paginationSuccess.size,
        }
    },{
        skip: !costCenterFileExportStatus?.fileId
    })

    const { data: errorsDetailsData, isFetching: isFetchingErrorDetail } = useGetCostCenterErrorsImportDetailsQuery({
        fileId: costCenterFileExportStatus?.fileId,
        params: {
            page: paginationError,
            size: 10,
            // sort: ['lineNumber']
        }
    },{
        skip: !costCenterFileExportStatus?.fileId
    })

    const totalPerPage = 10

    const totalErrorsPages = Math.floor(((errorsDetailsData?.totalSize || 0) / totalPerPage))
    
    const [ confirmFile, { isLoading } ] = useConfirmCostCenterFileMutation()

    const tableConfig = [
        {
            key: 'name',
            type: 'field',
            align: 'left',
            label: 'Centro de custo',
        },
        {
            key: 'code',
            type: 'field',
            align: 'left',
            label: 'Código',
        },
    ];

    const handleConfirmFile = ()=> {
        confirmFile({ fileId: costCenterFileExportStatus?.fileId })
        .then(() => {
            // dispatch(EmployeesFileSliceActions.clearFileExportStatus())
            dispatch(CostCenterFileSliceActions.setFileReportFeedback({
                ...(!!successDetailsData?.locations?.length && { successDetailsData }),
                ...(!!errorsDetailsData?.locations?.length && { errorsDetailsData }),
            }))

            history.push('/cost-and-sector-center/file-feedback-success')
        })
        .catch(()=>{
            error('Não foi possível validar suas informações. Por favor, revise os dados e tente novamente.')
        })
    }

    const titleAndSubtitleMapping: any = {
        'PROCESSED_CREATE_LOCATION_FILE_ERROR': {
            title: 'Identificamos os seguintes problemas',
            subtitle: 'Por favor, corrija os erros na sua planilha antes de enviá-la novamente.',
        },
        'PROCESSED_CREATE_LOCATION_FILE_SUCCESS': {
            title: 'Confirme a atualização via planilha do Excel',
            subtitle: '',
        }
    }

    const backButtonMapping: any = {
        'PROCESSED_CREATE_LOCATION_FILE_SUCCESS': {
            title: 'Voltar',
            actions: ()=> {
                dispatch(CostCenterFileSliceActions.clearFileExportStatus())
                dispatch(CostCenterFileSliceActions.clearReportFeedback())
                history.push("/cost-and-sector-center/file-upload")
            }
        },
        'PROCESSED_CREATE_LOCATION_FILE_ERROR_WITH_SUCCESS': {
            title: 'Reenviar planilha',
            actions: ()=> {
                dispatch(CostCenterFileSliceActions.clearFileExportStatus())
                dispatch(CostCenterFileSliceActions.clearReportFeedback())
                history.push("/cost-and-sector-center/file-upload")
            }
        },
    }

    const handleConfirmSendWithErrors = () => {
        return (
            NiceModal.show(GenericModal, {
                title: 'Tem certeza que quer ignorar os erros?',
                subtitle: 'Ao confirmar, apenas os centros de custos identifcados corretamente serão cadastrados com sucesso',
                cancelButton: {
                    title: 'CANCELAR',
                    disabled: false,
                    isLoading: false,
                    closeButtonAfterOnclick: true,
                    onClick: ()=>{},
                    color: 'primary',
                    variant: 'text',
                },
                nextButton: {
                    title: 'CONFIRMAR',
                    disabled: false,
                    isLoading: false,
                    closeButtonAfterOnclick: true,
                    onClick: ()=> handleConfirmFile(),
                }
            })
        )
    };

    const nextButtonMapping: any = {
        'PROCESSED_CREATE_LOCATION_FILE_ERROR': {
            title: 'Reenviar planilha',
            actions: ()=> {
                dispatch(CostCenterFileSliceActions.clearFileExportStatus())
                history.push("/cost-and-sector-center/file-upload")
            }
        },
        'PROCESSED_CREATE_LOCATION_FILE_SUCCESS': {
            title: 'Confirmar',
            actions: ()=> {
                handleConfirmFile()
            }
        },
        'PROCESSED_CREATE_LOCATION_FILE_ERROR_WITH_SUCCESS': {
            title: 'Ignorar erros e confirmar',
            isOrangeButton: true,
            actions: ()=> {
                handleConfirmSendWithErrors()
            }
        },
    }
    
    const fileName = costCenterFileExportStatus?.fileName || '-'

    const allSuccessLocations = successDetailsData?.totalSize  || 0

    const allErrorsLocations = errorsDetailsData?.totalSize  || 0

    const hasErrorsLocations = (allErrorsLocations) > 0

    const errorsQuantity = errorsDetailsData?.locations?.length || 0

    const hasSuccessLocations = (successDetailsData?.totalSize || 0) > 0
    // const isFetchingErrorDetail = false
    // const isFetchingSuccessDetail = false

    const currentTitleAndSubtitle = titleAndSubtitleMapping[costCenterFileExportStatus?.status]

    const statusToButtons = `${ !!hasSuccessLocations && costCenterFileExportStatus?.status === 'PROCESSED_CREATE_LOCATION_FILE_ERROR' ? `${costCenterFileExportStatus?.status}_WITH_SUCCESS` : costCenterFileExportStatus?.status}`
    
    const currentBackButtonProps = backButtonMapping[statusToButtons]
    
    const currentNextButtonProps = nextButtonMapping[statusToButtons]

    const handleChangeErrorPage = (_:any, value: any) => {
        setPaginationError(value - 1)
    }

    // if(!employeesFileExportStatus) return history.push('/cost-and-sector-center/file-upload')

    

    return (
        <>
            <Box className={classes.wrapper}>
                <Box className={classes.header}>
                    <Typography variant="h2" className={classes.title}>{currentTitleAndSubtitle?.title}</Typography>
                    <Typography variant="h4" className={classes.subtitle}>{currentTitleAndSubtitle?.subtitle}</Typography>
                </Box>

                {hasSuccessLocations && (
                    <Box className={classes.confirmationInfoContainer}>
                        <Typography variant="h2" className={classes.confirmationInfoTitle}>As informações abaixo estão corretas?</Typography>
                        <Box className={`${classes.row} ${classes.confirmationInfoContent}`}>
                            <Box className={classes.confirmationInfoCard}>
                                <Typography variant="h2" className={classes.confirmationInfoLabel}>Nome do arquivo</Typography>
                                <Typography variant="h2" className={classes.confirmationInfoValue}>{fileName}</Typography>
                            </Box>
                            <Box className={classes.confirmationInfoCard}>
                                <Typography variant="h2" className={classes.confirmationInfoLabel}>Data de atualização</Typography>
                                <Typography variant="h2" className={classes.confirmationInfoValue}>{formatDate(new Date(), 'DD/MM/YYYY')}</Typography>
                            </Box>
                        </Box>
                    </Box>
                )}

                {hasErrorsLocations && (
                    <SimpleAccordion
                        title={
                            <Box className={classes.accordionTitleContainer}>
                                <WarnCircleRed />
                                <Box className={classes.accordionTitleColumContainer}>
                                    <Box className={classes.accordionTextContainer}>
                                    <Typography className={classes.accordionTitleEvidence}>
                                        {allErrorsLocations} Centros de custo{allErrorsLocations > 1 && 's'}
                                    </Typography>
                                    <Typography className={classes.accordionTitle}>
                                        identificado{allErrorsLocations > 1 && 's'} com erro{allErrorsLocations > 1 && 's'}
                                    </Typography>
                                    </Box>
                                    <Typography className={classes.accordionTitle}>
                                        Verifique os motivos dos erros abaixo e reenvie o arquivo.
                                    </Typography>
                                </Box>
                            </Box>
                        }
                        content={
                            <Box className={classes.column} style={{width: '100%'}}>
                                <Box className={`${classes.row} ${classes.cardsGroupContainer}`}>
                                    <Box className={`${classes.column} ${classes.cardContainer}`}>
                                        <Box className={`${classes.row} ${classes.cardTitleContainer}`}>
                                            <Box className={`${classes.cardIndex} green`}/>
                                            <Typography variant="subtitle2" className={classes.cardLabelContainer}>
                                                Nome do arquivo
                                            </Typography>
                                        </Box>
                                        <Typography className={classes.cardLabelValue}>
                                            {fileName || '-'}
                                        </Typography>
                                    </Box>
                                    <Box className={`${classes.column} ${classes.cardContainer}`}>
                                        <Box className={`${classes.row} ${classes.cardTitleContainer}`}>
                                            <Box className={classes.cardIndex}/>
                                            <Typography variant="subtitle2" className={classes.cardLabelContainer}>
                                                Total de erros
                                            </Typography>
                                        </Box>
                                        <Typography className={classes.cardLabelValue}>
                                            {errorsQuantity}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box className={classes.itemsErrorsContainer}>
                                    {errorsDetailsData?.locations?.map(currentError => (
                                        <>
                                            { isFetchingErrorDetail && (
                                                <Box>
                                                    <Skeleton className={classes.skeletonAccordion}/>
                                                </Box>
                                            )}
                                            { !isFetchingErrorDetail && (
                                                <SimpleAccordion
                                                    title={
                                                        <Box className={`${classes.row} ${classes.errorItem}`}>
                                                            <Box className={classes.currentErrorContainer}>
                                                                <Box className={classes.errorCardLabelContainer}>
                                                                    <Typography className={classes.errorCardLabelText}>
                                                                        Linha do arquivo
                                                                    </Typography>
                                                                </Box>
                                                                <Typography className={classes.errorCardValue}>
                                                                {currentError?.lineNumber || '-'}
                                                                </Typography>
                                                            </Box>
                                                            {/* <Box className={classes.currentErrorContainer}>
                                                                <Box className={classes.errorCardLabelContainer}>
                                                                <Typography className={classes.errorCardLabelText}>
                                                                    CPF
                                                                </Typography>
                                                                </Box>
                                                                <Typography className={classes.errorCardValue}>
                                                                {formatCPF(currentError?.cpf)}
                                                                </Typography>
                                                            </Box> */}
                                                        </Box>
                                                    }
                                                    content={
                                                        <Box className={classes.errorContainer}>
                                                            {currentError.errors?.map(
                                                                (causeError, index) => (
                                                                <Box className={classes.accordionTitleContainer}>
                                                                    <ErrorOutlineIcon className={classes.errorIcon} />
                                                                    <Box className={classes.accordionTitleColumContainer}>
                                                                    <Typography
                                                                        variant="caption"
                                                                        className={classes.errorLabel}
                                                                    >
                                                                        {`Motivo do erro ${index + 1}`}
                                                                    </Typography>
                                                                    <Box className={classes.accordionTextContainer}>
                                                                        <Typography className={classes.infoValue}>
                                                                            {causeError}
                                                                        </Typography>
                                                                    </Box>
                                                                    </Box>
                                                                </Box>
                                                                ),
                                                            )}
                                                        </Box>
                                                    }
                                                />
                                            )}
                                        </>
                                    ))}
                                </Box>
                                {totalErrorsPages > 1 &&(
                                    <Box className={classes.paginationContainer}>
                                        <Pagination count={totalErrorsPages} page={paginationError  + 1} onChange={handleChangeErrorPage} />
                                    </Box>
                                )}
                            </Box>
                        }
                    />
                )}

                {!!allSuccessLocations && (
                    <SimpleAccordion
                    title={
                        <Box className={classes.accordionTitleContainer}>
                            <CheckGreen />
                            <Box className={classes.accordionTitleColumContainer}>
                                <Box className={classes.accordionTextContainer}>
                                    <Typography className={classes.accordionTitleEvidence}>
                                    { allSuccessLocations } Centro de custo{ allSuccessLocations > 1 && 's'}
                                    </Typography>
                                    <Typography className={classes.accordionTitle}>
                                        identificado{ allSuccessLocations > 1 && 's'}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        }
                        content={
                            <Table
                                identifier="cpf"
                                data={successDetailsData?.locations as any}
                                length={successDetailsData?.totalSize}
                                tableConfig={tableConfig as any}
                                hasPagination={true}
                                disableSelection={undefined}
                                isLoading={isFetchingSuccessDetail}
                                requests={(page: any,size: any): void => {
                                    setPaginationSuccess({
                                        page,
                                        size
                                    })
                                }}
                            />
                        }
                    />
                )}

                {isFetchingErrorDetail && !errorsDetailsData?.locations?.length && (
                    <Box style={{display: 'flex', gap: 24, flexDirection: 'column'}}>
                        <Box className={classes.skeletonAccordionContainer}>
                            <Skeleton className={classes.skeletonAccordion} style={{ position: 'absolute', width: '100%'}}/>
                            <Box className={classes.accordionTitleContainer}>
                                <Skeleton className={classes.skeletonAccordionIcon}/>
                                <Box className={classes.accordionTitleColumContainer}>
                                    <Skeleton component="title" width={195}/>
                                    <Skeleton component="title" width={321}/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                )}

                {isFetchingSuccessDetail && !successDetailsData?.locations?.length && (
                    <Box style={{display: 'flex', gap: 24, flexDirection: 'column'}}>
                        <Box className={classes.skeletonAccordionContainer}>
                            <Skeleton className={classes.skeletonAccordion} style={{ position: 'absolute', width: '100%'}}/>
                            <Box className={classes.accordionTitleContainer}>
                                <Skeleton className={classes.skeletonAccordionIcon}/>
                                <Box className={classes.accordionTitleColumContainer}>
                                    <Skeleton component="title" width={195}/>
                                    <Skeleton component="title" width={321}/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                )}
                
                <ProgressToolBar
                    disableNext={isLoading}
                    loading={isLoading}
                    backButtonText={currentBackButtonProps?.title}
                    onBackClicked={currentBackButtonProps?.actions}
                    disableBack={!hasSuccessLocations}
                    onNextClicked={currentNextButtonProps?.actions}
                    nextButtonText={currentNextButtonProps?.title}
                    numberOfSteps={undefined} 
                    currentStep={undefined} 
                    count={undefined} 
                    numberAccount={undefined} 
                    textAccount={undefined} 
                    orangeNextButton={currentNextButtonProps?.isOrangeButton} 
                    hidden={undefined} 
                    typeNextButton={undefined} 
                    formRef={undefined} 
                    placementTooltip={undefined}                
                />
            </Box>
        </>
    )
}

export default FileErrorReport 