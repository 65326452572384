import React from "react";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableFooter,
    TablePagination,
    Box,
    CircularProgress,
    useTheme
} from "@material-ui/core";
import { PaginationComponent } from "../Table/Components/EnhancedTable/TableParts/EnhancedTableFooter/Components/PaginationComponent";
import { useStyles } from "./styles";
import TableCases from "../Table/Components/EnhancedTable/TableCases";

export const CustomTable = ({ 
    identifier, 
    requests, 
    data, 
    length, 
    isLoading, 
    tableConfig, 
    hasPagination = true,
    maxHeight = 0
}:any) => {
    const theme = useTheme();
    const classes = useStyles()
    
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (_: any, newPage: any) => {
        setPage(newPage);
        requests(newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        const newSize = parseInt(event.target.value, 10);
        setRowsPerPage(newSize);
        setPage(0);
        requests(0, newSize);
    };

    const isTableBlank = data.length === 0

    const renderBodyTable = () => {
        if(isLoading) return <TableCases.Loading config={tableConfig} rowsPerPage={rowsPerPage} />
        
        if(isTableBlank) return <TableCases.Empty config={tableConfig} />

        return (
            data.map((row: any, rowIndex: any) => (
                <TableRow key={rowIndex}>
                    {tableConfig.map((column: any, colIndex: any) => (
                        <TableCell key={colIndex} align={column.align || "left"}>
                            {column.render ? column.render(row) : row[column.key]}
                        </TableCell>
                    ))}
                </TableRow>
            ))
        )
    }

    return (
        <Box className={classes.wrapper} style={maxHeight?{ maxHeight: maxHeight }:{}}>
            <Table>
                <TableHead style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 2,
                }}>
                    <TableRow>
                        {tableConfig.map((column:any, index: number) => (
                            <TableCell key={index} align={column.align || "left"}>
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderBodyTable()}
                </TableBody>
                {hasPagination && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
                                count={length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage="Exibindo"
                                labelDisplayedRows={({ from, to }) => `${from}-${to} de ${length}`}
                                ActionsComponent={PaginationComponent}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </Box>
    );
};
