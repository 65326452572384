// @ts-ignore
import { useHistory } from 'react-router-dom';
import { Box, Typography } from "@material-ui/core"
import React, { useEffect } from "react"
import { useStyles } from "./styles"
import { Alert } from "@material-ui/lab"
import { FileDropzone } from "../../../components/DropZone"
import Buttons from "../../../components/Buttons"
import ProgressToolBar from "../../../components/ProgressToolBar"
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { useFileUpload } from "../hooks/useFileUpload"
import { pusherStatus } from "../../../enums/pusher"
import { useSelector } from "react-redux"
import GenericModal from "../../../components/GenericModal"
import NiceModal from "@ebay/nice-modal-react"

export const handleInvalidFile = (handleClick: ()=>void) => {
    return (
        NiceModal.show(GenericModal, {
            title: 'Ocorreu um erro no processamento do arquivo',
            subtitle: 'Verifique as informações do arquivo e tente novamente.',
            nextButton: {
                title: 'TENTAR NOVAMENTE',
                disabled: false,
                isLoading: false,
                onClick: handleClick,
                color: 'secondary',
                closeButtonAfterOnclick: true
            }
        })
    )
};

const FileUpload  = () => {
    const classes = useStyles()
    const history = useHistory();
    const { fileTemplate, submitFile } = useFileUpload()
    const costCenterFileExportStatus = useSelector((state: any) => state.costCenterFileSlice.fileExportStatus);

    const currentStatus = costCenterFileExportStatus?.status

    const handlePusherError = () => {
        return (
            NiceModal.show(GenericModal, {
                title: 'Preenchimento de arquivo incorreto',
                subtitle: 'Verifique se você preencheu o arquivo modelo corretamente e tente novamente.',
                nextButton: {
                    title: 'VERIFICAR ERROS',
                    disabled: false,
                    isLoading: false,
                    onClick: ()=>history.push('/cost-and-sector-center/file-report'),
                }
            })
        )
    };

    const handleSuccess = () => {
        return (
            NiceModal.show(GenericModal, {
                title: 'Processamento concluído com sucesso!',
                subtitle: 'Clique em “CONTINUAR” para seguir com o processo',
                nextButton: {
                    title: 'CONTINUAR',
                    disabled: false,
                    isLoading: false,
                    onClick: ()=>history.push('/cost-and-sector-center/file-report'),
                    color: 'primary'
                }
            })
        )
    };

    const handleCancelConfirm = () => {
        return (
            NiceModal.show(GenericModal, {
                title: 'Tem certeza que quer cancelar o processamento do arquivo?',
                subtitle: 'Você perderá o progresso processado até agora e essa ação não poderá ser desfeita.',
                cancelButton: {
                    title: 'NÃO',
                    disabled: false,
                    isLoading: false,
                    closeButtonAfterOnclick: true,
                    onClick: ()=> {},
                    color: 'primary',
                    variant: 'text',
                },
                nextButton: {
                    title: 'SIM',
                    disabled: false,
                    isLoading: false,
                    onClick: ()=> submitFile.handleCancelFile(),
                    color: 'secondary'
                }
            })
        )
    };
    
    const modalMapping: any = {
        'PROCESSED_CREATE_LOCATION_FILE_ERROR': handlePusherError,
        'PROCESSED_CREATE_LOCATION_FILE_SUCCESS': handleSuccess,
        'UNPROCESSABLE_FILE': () => handleInvalidFile(submitFile.handleCancelFile)
    }

    useEffect(()=>{
        const currentModal = modalMapping[currentStatus]
        !!currentModal && currentModal()

        return () => {
            NiceModal.hide(GenericModal)
        };
    },[currentStatus])

    return (
        <>
            <Box className={classes.wrapper}>
                <Box className={classes.header}>
                    <Typography variant="h2" className={classes.title}>Cadastro via planilha do Excel</Typography>
                </Box>
                <Box className={classes.stepContainer}>
                    <Box className={classes.row} style={{ justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="caption" className={classes.stepTitle}>1. Comece fazendo o download do modelo da planilha.</Typography>
                        <Buttons.PrimaryButton
                            size="large"
                            color="primary"
                            variant="text"
                            icon={<GetAppOutlinedIcon /> as any}
                            title="Baixar modelo"
                            loading={fileTemplate.isLoading}
                            disabled={fileTemplate.isLoading}
                            onClick={fileTemplate.download}
                        />
                    </Box>
                </Box>
                <Box className={`${classes.column} ${classes.stepContainer}`}>
                    <Typography variant="caption" className={classes.stepTitle}>2. Envie o arquivo preenchido.</Typography>
                    <Box className={classes.row}>
                        <Typography variant="caption" className={classes.stepAlert}>Formatos suportados: .xls, .xlsx, e .csv</Typography>
                        <Typography variant="caption" className={classes.stepAlert} style={{ marginInline: 6}}>•</Typography>
                        <Typography variant="caption" className={classes.stepAlert}>Tamanho máximo do arquivo: 10 MB </Typography>
                    </Box>
                </Box>
                <Box className={classes.wrapper}>
                    <FileDropzone 
                        progress={costCenterFileExportStatus?.percentage || 0}
                        progressActive={costCenterFileExportStatus?.status}
                        fileName={costCenterFileExportStatus?.fileName}
                        onDrop={submitFile.setFile}
                        onRemove={()=>{
                            submitFile.handleCancelFile()
                        }}
                        onCancel={handleCancelConfirm}
                        statusDescription={costCenterFileExportStatus?.statusDescription}
                        accept={{
                            "text/csv": [".csv"],
                            "application/vnd.ms-excel": [".xls"],
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"]
                        }}
                        listeners={[
                            {
                                observer: costCenterFileExportStatus?.fileName,
                                action: (removeFile)=>{
                                    if(currentStatus === 'UNPROCESSABLE_FILE'){  
                                        removeFile()
                                    }
                                }
                            }
                        ]}
                    />
                </Box>
                
                <ProgressToolBar
                    disableNext={submitFile.isLoading || !submitFile.file || currentStatus === pusherStatus.PROCESSING}
                    loading={submitFile.isLoading}
                    onBackClicked={() => history.push('/cost-and-sector-center')}
                    onNextClicked={submitFile.submit}
                    nextButtonText="Prosseguir" 
                    numberOfSteps={undefined} 
                    currentStep={undefined} 
                    count={undefined} 
                    numberAccount={undefined} 
                    textAccount={undefined} 
                    orangeNextButton={undefined} 
                    hidden={undefined} 
                    typeNextButton={undefined} 
                    formRef={undefined} 
                    placementTooltip={undefined}                
                />
            </Box>
        </>
    )
}

export default FileUpload 