import React, { useEffect, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { Alert } from '@material-ui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
} from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import useStyles from './styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Buttons from '../Buttons';

interface ButtonModalProps {
  title: string;
  disabled?: boolean;
  isLoading?: boolean;
  closeButtonAfterOnclick?: boolean;
  onClick: () => void;
  color?: 'primary' | 'secondary';
  variant?: string;
}

interface GenericModalProps {
  title: string;
  subtitle?: string;
  nextButton: ButtonModalProps
  cancelButton?: ButtonModalProps,
}

const GenericModal = NiceModal.create(({ 
  title,
  subtitle,
  nextButton,
  cancelButton,
}:GenericModalProps) => {
  
  const modal = useModal();
  const styles = useStyles();


  const handleCancelButton =() => {
    cancelButton?.onClick && cancelButton.onClick()
    cancelButton?.closeButtonAfterOnclick && modal.remove()
  }

  const handleNextButton =() => {
    nextButton.onClick()
    nextButton?.closeButtonAfterOnclick && modal.remove()
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={modal.visible}
      onClose={modal.remove}
      PaperProps={{ className: styles.root }}
    >
      <IconButton onClick={modal.remove} className={styles.closeIcon}>
        <CloseRoundedIcon />
      </IconButton>
      <DialogContent className={styles.header}>
        <Typography variant='h2' className={styles.title}>{title}</Typography>
        <Typography variant='h5' className={styles.description}>{subtitle}</Typography>
      </DialogContent>
      <DialogActions className={styles.buttonContainer}>
        {cancelButton && <Buttons.PrimaryButton 
          title={cancelButton.title}
          disabled={cancelButton.disabled}
          loading={cancelButton.isLoading}
          onClick={handleCancelButton}
          color={cancelButton.color || 'secondary'}
          variant={cancelButton?.variant? cancelButton?.variant:'outlined'}
          />}
        {nextButton && <Buttons.PrimaryButton 
          title={nextButton.title}
          disabled={nextButton.disabled}
          loading={nextButton.isLoading}
          onClick={handleNextButton}
          variant={nextButton?.variant ? nextButton?.variant:'contained'}
          color={nextButton.color || 'secondary'}
          />}
      </DialogActions>
    </Dialog>
  );
});

export default GenericModal;
