import { useState } from "react";
import { 
    useGetFileTemplateMutation, 
    useFileExportMutation
} from "../../../redux/store/Employees/FileUpload/api"
import { handleSnackbarStack } from "../../../utils/handleSnackbarStack";
import { useDispatch, useSelector } from "react-redux";
import { EmployeesFileSliceActions } from "../../../redux/store/Employees/FileUpload/slice";
import { handleInvalidFile } from "../FileUpload";

export const useFileUpload = () => {

    const dispatch = useDispatch()
    const { error } = handleSnackbarStack();
    
    const {
        currentCompany:{code},
    } = useSelector((state: any) => state.companies);

    const [ getFileTemplate, { isLoading: isLoadingFileTemplate } ] = useGetFileTemplateMutation()
    const [ fileExport, { isLoading: isLoadingFileExport } ] = useFileExportMutation()
    
    const [file, setFile] = useState<File[] | null>(null)

    const downloadFile = async (fileUrl: string) => {
        if (!fileUrl) {
            console.error('Erro: URL do arquivo inválida.');
            return;
        }
    
        const link = document.createElement('a');
        link.href = fileUrl;
        
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const download = async () => {
        try {
            const fileTemplateData: any = await getFileTemplate({})
            await downloadFile(fileTemplateData?.error?.data)
        } catch (err) {
            error('Não foi possível baixar o arquivo. Por favor, tente novamente.');
        }
    };

    const handleCancelFile = () => {
        dispatch(EmployeesFileSliceActions.clearReportFeedback());
        dispatch(EmployeesFileSliceActions.clearFileExportStatus());
        setFile(null)
    };

    const submitFile = async () => {
        if(!file) return error('Não foi possível enviar o arquivo. Por favor, tente novamente.')

        try {
            await fileExport({
                companyCode: code,
                fileExtension: '.xls',
                file: file[0]
            }).unwrap();
        } catch (err) {
            // error('Não foi possível enviar o arquivo. Por favor, tente novamente.');
        }
    };

    

    return ({
        fileTemplate: {
            download,
            isLoading: isLoadingFileTemplate
        },
        submitFile: {
            file,
            setFile,
            submit: submitFile,
            isLoading: isLoadingFileExport,
            handleCancelFile
        }
    })
}