import { createSlice } from '@reduxjs/toolkit';

const employeesFileSlice = createSlice({
  name: 'EmployeesFile',
  initialState: {
    fileExportStatus: null,
    fileReportFeedback: null
  },
  reducers: {
    setFileExportStatus(state, action) {
      state.fileExportStatus = action.payload;
    },
    clearFileExportStatus(state) {
      state.fileExportStatus = null;
    },
    setFileReportFeedback(state, action) {
      state.fileReportFeedback = action.payload;
    },
    clearReportFeedback(state) {
      state.fileReportFeedback = null;
    },
  },
});

export const EmployeesFileSliceActions = employeesFileSlice.actions;
export const employeesFileSliceReducer = employeesFileSlice.reducer;
export default employeesFileSliceReducer;