import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    dropzone: {
        display: "flex",
        flexDirection: "column",
        height: 105,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 12,
        padding: theme.spacing(3),
        textAlign: "center",
        backgroundColor: '#F6F3F9',
        boxShadow: 'none',
        cursor: "pointer",
        transition: "background-color 0.3s",
        "& .MuiLinearProgress-barColorPrimary":{
            backgroundColor: 'rgba(111, 0, 189, 0.10)'
        }
    },
    textButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 12,
        color: '#52197F',
        fontSize: 14,
        '& svg':{
            fill: '#52197F'
        },
        '& p':{
            fontWeight: 500,
        }
    },
    subtitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#666666',
        fontSize: 12,
        fontWeight: 400,
        marginTop: 10,
    },
    progressBar:{
        height: 50,
        position: 'absolute'
    },
    progressContentContainer:{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: 16,
    },
    progressStatusIcon:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 12,
        '& h5': {
            fontSize: 18,
            fontWeight: 500,
            color: '#52197F',
            width: 'fit-content'
        }
    },
    progressStatusContent:{
        display: 'flex',
        flexDirection: 'column',
        alignItems:'center',
        justifyContent: 'space-between',
        '& h5': {
            fontSize: 18,
            fontWeight: 500,
            color: '#52197F',
            width: 'fit-content'
        }
    },
    progressStatusDescription:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 12,
        '& h5':{
            fontSize: 14,
            color: '#1B181C'
        },
        '& subtitle2':{
            fontSize: 14,
            color: '#52197F'
        },
    },
    actionsContainer:{
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        '& p':{
            fontWeight: 500
        }
    }
}));