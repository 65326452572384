// @ts-ignore
import { useHistory } from 'react-router-dom';
import { Box, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useStyles } from "./styles"
import { ReactComponent  as PersonCheck } from '../../../assets/personCheck.svg'
import { ReactComponent  as PersonOff } from '../../../assets/personOff.svg'
import { ReactComponent  as Update } from '../../../assets/update.svg'
import SimpleAccordion from "../../../components/SimpleAccordion"
import formatCPF from "../../../utils/formatCPF"
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ProgressToolBar from "../../../components/ProgressToolBar"
import { useDispatch, useSelector } from 'react-redux';
import formatDate from '../../../utils/formatDate';
import { EmployeesFileSliceActions } from '../../../redux/store/Employees/FileUpload/slice';
import { useGetErrorsImportDetailsQuery } from '../../../redux/store/Employees/FileUpload/api';
import { Pagination, Skeleton } from '@material-ui/lab';


const FileReportUpdateWithErrors = ()=> {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()

    const fileReportFeedback = useSelector((state:any) => state?.employeesFileSlice.fileReportFeedback);
    const employeesFileExportStatus = useSelector((state: any) => state.employeesFileSlice.fileExportStatus);

    const [paginationError, setPaginationError] = useState(0)

    const totalPerPage = 10

    const { data: errorsDetailsData, isFetching: isFetchingErrorDetail } = useGetErrorsImportDetailsQuery({
        fileId: employeesFileExportStatus?.fileId,
        params: {
            page: paginationError,
            size: totalPerPage,
            sort: ['lineNumber']
        }
    },{
        skip: !employeesFileExportStatus?.fileId
    })

    
    const totalErrorsPages = Math.floor(((errorsDetailsData?.totalSize || 0) / totalPerPage))

    const handleChangeErrorPage = (_:any, value: any) => {
        setPaginationError(value - 1)
    }

    useEffect(()=>{
        return () => {
            dispatch(EmployeesFileSliceActions.clearFileExportStatus())
        }
    },[])

    if(!employeesFileExportStatus) return history.push('/employees/file-upload')

    return (
        <Box className={classes.wrapper}>
            <Box className={classes.header}>
                <Typography variant="h2" className={classes.title}>Relatório de atualização</Typography>
                <Typography variant="h4" className={classes.subtitle}>Confira a quantidade de colaboradores atualizados e os motivos pelos quais alguns não foram.</Typography>
            </Box>

            <Box className={`${classes.row} ${classes.cardsGroupContainer}`}>
                <Box className={classes.cardContainer}>
                    <PersonCheck/>
                    <Box className={`${classes.column} ${classes.cardContentContainer}`}>
                        <Typography variant="h4" className={classes.cardLabelContainer}>Qtde. colaboradores atualizados</Typography>
                        <Typography variant="h4" className={classes.cardLabelValue}>{fileReportFeedback?.successEmployees?.length}</Typography>
                    </Box>
                </Box>
                <Box className={classes.cardContainer}>
                    <PersonOff/>
                    <Box className={`${classes.column} ${classes.cardContentContainer}`}>
                        <Typography variant="h4" className={classes.cardLabelContainer}>Qtde. colaboradores não atualizados</Typography>
                        <Typography variant="h4" className={classes.cardLabelValue}>{fileReportFeedback?.failedLines?.length}</Typography>
                    </Box>
                </Box>
                <Box className={classes.cardContainer}>
                    <Update/>
                    <Box className={`${classes.column} ${classes.cardContentContainer}`}>
                        <Typography variant="h4" className={classes.cardLabelContainer}>Atualizado em</Typography>
                        <Typography variant="h4" className={classes.cardLabelValue}>{formatDate(new Date(),'DD/MM/YYYY [às] HH:mm')}</Typography>
                    </Box>
                </Box>
            </Box>

            <SimpleAccordion
                title={
                    <Box className={classes.accordionTitleContainer}>
                        <Box className={classes.accordionTitleColumContainer}>
                            <Typography className={classes.accordionTitleEvidence}>
                                Colaboradores não atualizados
                            </Typography>
                            <Typography className={classes.accordionTitle}>
                                Verifique os motivos dos erros abaixo e reenvie o arquivo.
                            </Typography>
                        </Box>
                    </Box>
                }
                content={
                    <Box className={classes.column} style={{width: '100%'}}>
                        <Box style={{display: 'flex', flexDirection:'column', gap: 16, maxHeight: 250, overflow: 'auto' }}>
                            {errorsDetailsData?.employees?.map((currentError: any) => (
                                <>
                                    {isFetchingErrorDetail && (
                                        <Box>
                                            <Skeleton className={classes.skeletonAccordion}/>
                                        </Box>
                                    )}
                                    {!isFetchingErrorDetail && (
                                        <SimpleAccordion
                                            title={
                                                <Box className={`${classes.row} ${classes.errorItem}`}>
                                                    <Box className={classes.currentErrorContainer}>
                                                        <Box className={classes.errorCardLabelContainer}>
                                                        <Typography className={classes.errorCardLabelText}>
                                                            Linha do arquivo
                                                        </Typography>
                                                        </Box>
                                                        <Typography className={classes.errorCardValue}>
                                                        {currentError?.lineNumber || '-'}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={classes.currentErrorContainer}>
                                                        <Box className={classes.errorCardLabelContainer}>
                                                        <Typography className={classes.errorCardLabelText}>
                                                            CPF
                                                        </Typography>
                                                        </Box>
                                                        <Typography className={classes.errorCardValue}>
                                                        {formatCPF(currentError?.cpf)}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            }
                                            content={
                                                <>
                                                    <Box className={classes.errorContainer}>
                                                    {currentError.errors?.map(
                                                        (causeError: any, index: number) => (
                                                        <Box className={classes.accordionTitleContainer}>
                                                            <ErrorOutlineIcon className={classes.errorIcon} />
                                                            <Box className={classes.accordionTitleColumContainer}>
                                                            <Typography
                                                                variant="caption"
                                                                className={classes.errorLabel}
                                                            >
                                                                {`Motivo do erro ${index + 1}`}
                                                            </Typography>
                                                            <Box className={classes.accordionTextContainer}>
                                                                <Typography className={classes.infoValue}>
                                                                    {causeError}
                                                                </Typography>
                                                            </Box>
                                                            </Box>
                                                        </Box>
                                                        ),
                                                    )}
                                                    </Box>
                                                </>
                                            }
                                        />
                                    )}
                                </>
                            ))}
                        </Box>
                        <Box className={classes.paginationContainer}>
                            <Pagination count={totalErrorsPages} page={paginationError  + 1} onChange={handleChangeErrorPage} />
                        </Box>
                    </Box>
                }
            />

            <ProgressToolBar
                disableNext={false}
                loading={false}
                disableBack={true}
                orangeNextButton={false} 
                onBackClicked={()=>''}
                onNextClicked={()=>{
                    dispatch(EmployeesFileSliceActions.clearReportFeedback())
                    history.push('/employees')
                }}
                nextButtonText={'Gerenciar funcionários'}
                numberOfSteps={undefined} 
                currentStep={undefined} 
                count={undefined} 
                numberAccount={undefined} 
                textAccount={undefined} 
                hidden={undefined} 
                typeNextButton={undefined} 
                formRef={undefined} 
                placementTooltip={undefined}                
            />
            
        </Box>
    )
}

export default FileReportUpdateWithErrors