import { reduxApi } from '../../../services/reduxApi';

const slice = reduxApi
  .enhanceEndpoints({
    addTagTypes: [],
  })
  .injectEndpoints({
    endpoints: builder => ({
      verifyIsPinRequired: builder.query({
        query: ({ companyCode }) => `/companies/${companyCode}/search`,
      }),

      validateBalance: builder.mutation({
        query: ({ companyCode = '', nsuList = [] }) => ({
          url: `/employeesPayments/batch/authorize/balance/${companyCode}`,
          method: 'POST',
          body: { nsuList },
        }),
      }),

      preAuthorizePayroll: builder.mutation({
        query: ({ emailNotReceived = false, nsuList = [] }) => ({
          url: '/employeesPayments/pre-authorize',
          method: 'PUT',
          headers: {
            branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
          },
          body: { emailNotReceived, nsuList },
        }),
      }),

      confirmAuthorization: builder.mutation({
        query: ({
          requestId = '',
          pin = '',
          location,
          companyCode = '',
          nsuList = [],
          expressPayment,
        }) => {
          return {
            url: `employeesPayments/batch/authorize/${companyCode}`,
            method: 'PUT',
            headers: {
              requestId,
              pin,
              branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
              ...location,
            },
            body: { nsuList, expressPayment },
          };
        },
      }),

      confirmAppointment: builder.mutation({
        query: ({ fileEncodedKey, authToken, pixProvider }) => ({
          url: `/files/employeespayments/confirmAppointment/${fileEncodedKey}`,
          method: 'POST',
          headers: {
            Authorization: authToken,
            pixProvider,
          },
          body: '',
        }),
        transformErrorResponse: (response) => {
          return response?.data?.errors?.[0];
        },
      }),
    }),
  });

export const {
  useVerifyIsPinRequiredQuery,
  useValidateBalanceMutation,
  usePreAuthorizePayrollMutation,
  useConfirmAuthorizationMutation,
} = slice;
