import { makeStyles, createStyles, alpha } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
      width: 420,
      height: 'fit-content',
      marginTop: 60,
      marginRight: 13,
      paddingBottom: '0px !important',
      borderRadius: 4,

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    positionWrapper: {
      display: 'flex',
      position: 'relative',
      width: 'inherit',
      padding: 20,
    },

    count: {
      background: '#E54217',
      color: '#fff',
      height: 15,
      width: 15,
      borderRadius: 30,

      position: 'absolute',
      top: 10,
      left: 35,
      padding: 8,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 10,
      fontWeight: 600,
    },

    iconAllDownloadConcluded: {
      background: '#168243',
      color: '#FFF',
      fontSize: 10,
      fontWeight: 600,
      padding: 2,
      height: 15,
      width: 15,
      borderRadius: 30,

      position: 'absolute',
      top: 10,
      left: 35,
    },

    iconErrorTop: {
      background: '#CA2317',
      color: '#FFF',
      fontSize: 10,
      fontWeight: 600,
      padding: 3,
      height: 15,
      width: 15,
      borderRadius: 30,

      position: 'absolute',
      top: 10,
      left: 35,
    },
    concludedMain: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: '100%',
    },

    concludedBox: {
      display: 'flex',
      flexDirection: 'column',
      gap: 6,
    },
    concludedMessageBox: {
      display: 'flex',
    },

    iconCheck: {
      background: '#19797F',
      color: '#FFF',
      padding: 2,
      marginRight: 10,
      fontSize: 16,
      height: 21,
      width: 21,
      borderRadius: 30
    },

    iconError: {
      marginRight: 10,

      '&.MuiSvgIcon-root': {
        fill: '#CA2317'
      }
    },

    concludedText: {
      color: '#19797F',
      fontSize: 14
    },

    error: {
      display: 'flex',
    },

    errorText: {
      color: '#CA2317',
      fontSize: 14
    },

    iconStyle: {
      alignSelf: 'center',
      margin: '3px 20px',
      fontSize: '18px',
      cursor: 'pointer',
    }
  })
);
