import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        flex: 1,
        marginBottom: 65,
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8
    },
    title: {
        fontWeight: 700,
        fontSize: 34,
        color: '#1B181C'
    },
    subtitle: {
        fontWeight: 400,
        fontSize: 16,
        color: '#666666'
    },
    cardsGroupContainer: {
        gap: 13,
        marginTop: 8,
        marginBottom: 8,
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 6,
        border: '1px solid #F2F2F2',
        backgroundColor: '#fff',
        width: 246,
        padding: 16,
        gap: 16
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
    column: {
        display: 'flex',
        flexDirection: 'column'
    },
    cardContentContainer: {
        gap: 16
    },
    cardLabelContainer:{
        color: '#808080',
        fontWeight: 500,
        fontSize: 12,
    },
    cardLabelValue:{
        color: '#1A1A1A',
        fontSize: 14,
        fontWeight: 500,
    },
    accordionTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 12,
    },
    accordionTitleColumContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    accordionTitleEvidence: {
        color: '#2E2E2E',
        fontWeight: 700,
    },
    accordionTitle: {
        color: '#2E2E2E',
    },
    cardTitleContainer: {
        alignItems: 'center',
        gap: 8
    },
    cardIndex: {
        width: 4,
        height: 16,
        borderRadius: 6,
        backgroundColor: '#CA2317',
        '&.green':{
            backgroundColor: '#19797F'
        }
    },
    errorItem: {
        gap: 16,
        flex: 1,
        '& > div:first-child': {
            backgroundColor: '#FAFAFA',
        }
    },
    currentErrorContainer: {
        paddingInline: 12,
        paddingBlock: 8,
        borderRadius: 6,
    },
    errorCardLabelContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
    },
    errorCardLabelText: {
        fontSize: '12px !important',
        fontWeight: 500,
        color: '#808080',
    },
    errorCardValue: {
        fontWeight: 500,
        color: '#1A1A1A',
    },
    errorContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: 16,
        '& > *:not(:last-child)': {
            borderBottom: '1px solid #E0E0E0',
            paddingBottom: 8,
            width: '100%'
        }   
    },
    errorIcon: {
        color: '#CA2317',
        marginBlock: 'auto',
    },
    infoValue: {
        fontWeight: 500,
        fontSize: '14px !important',
        color: '#000000',
    },
    accordionTextContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4,
    },
    errorLabel: {
        color: '#808080',
    },
    paginationContainer: {
        display: 'flex',
        justifyContent:'flex-end',
        marginTop: 16,

        '& .Mui-selected': {
            backgroundColor: '#52197F',
            color: 'white',
            fontWeight: 500,

            '&:hover':{
                backgroundColor: '#52197F',
            }
        }
    },
    skeletonAccordionContainer: {
        display: 'flex',
        height: 93,
        alignItems: 'center',
        position: 'relative'
    },
    skeletonAccordion: {
        height: 93,
        '&.MuiSkeleton-text':{
            transform: 'none'
        }
    },
}));