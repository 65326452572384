import React, { useCallback, useEffect, useState } from "react";
import { Accept, useDropzone } from "react-dropzone";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./styles";
import { Box, LinearProgress, CircularProgress } from "@material-ui/core";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import { ReactComponent as DescriptionIcon } from '../../assets/fileText.svg';
import Buttons from "../Buttons";

interface Listener {
    observer: any;
    action: (prop: any) => void;
}

interface FileDropzoneProps {
    onDrop: (acceptedFiles: File[]) => void;
    progressActive: boolean;
    statusDescription?: string;
    fileName?: string;
    progress?: number;
    maxFiles?: number;
    accept?: Accept,
    onRemove?: ()=> void,
    onCancel?: ()=> void,
    listeners?: Listener[];
}

export const FileDropzone = ({ 
    onDrop, 
    progress=0, 
    maxFiles=0,
    progressActive=false, 
    accept, 
    statusDescription = 'Conferindo dados',
    onCancel,
    onRemove,
    fileName,
    listeners = []
}: FileDropzoneProps) => {

    const classes = useStyles();
    const [currentFileName, setCurrentFileName] = useState<string | null | undefined>(fileName);

    const handleDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            setCurrentFileName(acceptedFiles[0].name);
            onDrop(acceptedFiles);
        }
    }, [onDrop]);

    const handleRemoveFile = () => {
        setCurrentFileName(null);
        onRemove && onRemove()
    };

    const handleCancel = () => {
        setCurrentFileName(null);
        onCancel && onCancel()
    };

    const { getRootProps, getInputProps } = useDropzone({ 
        accept, 
        maxFiles,
        onDrop: handleDrop, 
    });

    const renderUploadFileContent = () => {
        return (
            <>
                <input {...getInputProps()} />
                <Box className={classes.textButtonContainer}>
                    <PublishRoundedIcon />
                    <Typography variant="body1">ENVIAR UM ARQUIVO</Typography>
                </Box>
            </>
        )
    }

    useEffect(() => {
        listeners.forEach(({ observer, action }) => {
            if (observer !== undefined) {
                action(handleRemoveFile);
            }
        });
    }, [listeners]);    

    const renderUploadProgressContent = () => {
        return (
            <>
                <LinearProgress
                    variant="determinate" 
                    value={progress} 
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "transparent"
                    }}
                />
                <>
                    <Box className={classes.progressContentContainer}>
                        <Box className={classes.progressStatusIcon}>
                            <DescriptionIcon width="58" height="58" />
                            {!progressActive && <Typography variant="h5">{currentFileName}</Typography>}
                        </Box>
                        {progressActive && (
                            <Box className={classes.progressStatusContent}>
                                <Typography variant="h5">{currentFileName}</Typography>
                                <Box className={classes.progressStatusDescription}>
                                    <CircularProgress size={14}/>
                                    <Typography variant="h5">{statusDescription}</Typography>
                                    <Typography variant="subtitle2">{`${progress}%`}</Typography>
                                </Box> 
                            </Box>
                        )}
                        <Box className={classes.actionsContainer}>
                            { progressActive ? (
                                <Buttons.PrimaryButton
                                    size="large"
                                    color="primary"
                                    variant="outlined"
                                    title="CANCELAR"
                                    onClick={handleCancel}
                                />
                            ): (
                                <Buttons.PrimaryButton
                                    size="large"
                                    color="primary"
                                    variant="outlined"
                                    title="REMOVER"
                                    onClick={handleRemoveFile}
                                />
                            )}
                        </Box>
                    </Box>
                </>
            </>
        )
    }

    const chooseRenderOption = () => {
        const hasFile = !!currentFileName;
    
        if (!hasFile) return renderUploadFileContent();
        
        return renderUploadProgressContent();
    };

    return (
        <Box>
            <Paper 
                {...getRootProps()} 
                className={classes.dropzone} 
                elevation={3} 
                style={{ position: "relative", overflow: "hidden" }}
            >
                {chooseRenderOption()}
            </Paper>
            <Box className={classes.subtitleContainer}>
                {progressActive ? <Typography variant="body1">Enquanto verificamos o arquivo você pode continuar seu trabalho e acompanhar o andamento pelo indicador na parte superior.</Typography> : (
                    <Typography variant="body1">Você pode arrastar ou clicar na caixa para selecionar o arquivo.</Typography>
                )}
            </Box>
        </Box>
    );
};
