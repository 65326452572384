const pusherKeys = {
  APP_KEY: process.env.REACT_APP_KEY,
  APP_CLUSTER: 'mt1',
};

const pusherStatus = {
  PROCESSED_CREATE_LOCATION_FILE_SUCCESS: 'PROCESSED_CREATE_LOCATION_FILE_SUCCESS',
  PROCESSED_CREATE_LOCATION_FILE_ERROR: 'PROCESSED_CREATE_LOCATION_FILE_ERROR',

  UNPROCESSABLE_FILE: 'UNPROCESSABLE_FILE',
  PROCESSED_UPDATE_EMPLOYEE_FILE_SUCCESS: 'PROCESSED_UPDATE_EMPLOYEE_FILE_SUCCESS',
  PROCESSED_UPDATE_EMPLOYEE_FILE_ERROR: 'PROCESSED_UPDATE_EMPLOYEE_FILE_ERROR',

  PROCESSED_PAYROLL_SUCCESS: 'PROCESSED_PAYROLL_SUCCESS',
  PROCESSED_PAYROLL_ERROR: 'PROCESSED_PAYROLL_ERROR',

  PROCESSED_EMPLOYEES_SUCCESS: 'PROCESSED_EMPLOYEES_SUCCESS',
  PROCESSED_EMPLOYEES_ERROR: 'PROCESSED_EMPLOYEES_ERROR',

  PROCESSED_PAYCHECK_SUCCESS: 'PROCESSED_PAYCHECK_SUCCESS',
  PROCESSED_PAYCHECK_ERROR: 'PROCESSED_PAYCHECK_ERROR',

  PROCESSED_INSURANCES_SUCCESS: 'PROCESSED_INSURANCES_SUCCESS',
  PROCESSED_INSURANCES_ERROR: 'PROCESSED_INSURANCES_ERROR',

  PROCESSED_ONBOARDING_REPORT_ERROR: 'PROCESSED_ONBOARDING_REPORT_ERROR',

  PROCESSED_PONTO_GO_EMPLOYEE_REGISTER_SUCCESS:
    'PROCESSED_PONTO_GO_EMPLOYEE_REGISTER_SUCCESS',
  PROCESSED_PONTO_GO_EMPLOYEE_REGISTER_ERROR:
    'PROCESSED_PONTO_GO_EMPLOYEE_REGISTER_ERROR',

  PROCESSING: 'PROCESSING',
  CANCELED: 'CANCELED',
  PROCESSED: 'PROCESSED',
  PROCESSED_RECEIPT_PAYMENT_ERROR: 'PROCESSED_RECEIPT_PAYMENT_ERROR',

  SALARY_ANTICIPATION_REPORT_FINISHED: 'SALARY_ANTICIPATION_REPORT_FINISHED',
};

const pusherChannels = {
  PROCESSED_PAYCHECK: 'PROCESSED_PAYCHECK',
  PROCESSED_PAYCHECK_REPORT: 'PROCESSED_PAYCHECK_REPORT',

  PROCESSED_PAYROLL: 'PROCESSED_PAYROLL',

  PROCESSED_EMPLOYEES: 'PROCESSED_EMPLOYEES',

  PROCESSED_INSURANCES: 'PROCESSED_INSURANCES',
  INSURANCES_REGISTER_CONFIRMATION: 'INSURANCES_REGISTER_CONFIRMATION',

  PONTO_GO_EMPLOYEE_REGISTER: 'PONTO_GO_EMPLOYEE_REGISTER',

  SALARY_ANTICIPATION_REPORT_FINISHED: 'SALARY_ANTICIPATION_REPORT_FINISHED',

  CONSOLIDATED_VALUES_EMPLOYEES_REPORT_FINISHED:
    'CONSOLIDATED_VALUES_EMPLOYEES_REPORT_FINISHED',

  CONSOLIDATED_VALUES_EMPLOYEES_REPORT_PROCESSING:
    'CONSOLIDATED_VALUES_EMPLOYEES_REPORT_PROCESSING',

  CONSOLIDATED_VALUES_LOANS_REPORT_FINISHED:
    'CONSOLIDATED_VALUES_LOANS_REPORT_FINISHED',

  CONSOLIDATED_VALUES_LOANS_REPORT_PROCESSING:
    'CONSOLIDATED_VALUES_LOANS_REPORT_PROCESSING',

  CONSULT_LIMIT_REPORT_FINISHED: 'CONSULT_LIMIT_REPORT_FINISHED',

  CONSULT_LIMIT_REPORT_PROCESSING: 'CONSULT_LIMIT_REPORT_PROCESSING',

  PROCESSED_EMPLOYEES_LIMITS: 'PROCESSED_EMPLOYEES_LIMITS',
};

const pusherEvents = {
  
  LOCATION_CREATE_FILE: 'LOCATION_CREATE_FILE',
  LOCATION_CREATE_FILE_PROCESSING: 'LOCATION_CREATE_FILE_PROCESSING',
  RECEIPT_PAYMENT: 'RECEIPT_PAYMENT',
  RETURN_PAYMENT_FILE_CNAB_240: 'RETURN_PAYMENT_FILE_CNAB_240',
  PROCESS_ONBOARDING_REPORT: 'PROCESS_ONBOARDING_REPORT',
  UPDATE_EMPLOYEE_FILE_PROCESSING: 'UPDATE_EMPLOYEE_FILE_PROCESSING',
  UPDATE_EMPLOYEE_FILE_ERROR: 'UPDATE_EMPLOYEE_FILE_ERROR',
  RECEIPT_PAYMENT_LOCATION: 'RECEIPT_PAYMENT_LOCATION',
  RECEIPT_PAYMENT_STOCKING: 'RECEIPT_PAYMENT_STOCKING',
};

export { pusherKeys, pusherStatus, pusherChannels, pusherEvents };
