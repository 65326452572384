import React from 'react';
import { Avatar, Box, Card, Grid, Tooltip, Typography } from '@material-ui/core';
import {
  BusinessRounded,
  MailOutlineRounded,
  SmartphoneRounded,
  VpnKeyOutlined,
} from '@material-ui/icons';

import KebabMenu from '../KebabMenu';
import { ChipStatus } from '../../../../components/ChipStatus';

import formatCnpj from '../../../../utils/formatCnpj';
import { handleSnackbarStack } from '../../../../utils/handleSnackbarStack';
import { KEY_LABELS, KEY_STATUSES, CLAIM_LABEL } from '../../constants';

import { useStyles } from './styles';
import { usePix } from '../../hooks/usePix';

export default function PixKeyItem({ pixItem, onDelete }) {
  const styles = useStyles();
  const { success } = handleSnackbarStack();

  const {
    handleOpenDetailsPortability
  } = usePix()

  const onCopyKey = () => {
    navigator.clipboard.writeText(pixItem?.key);
    success('Chave copiada!');
  };

  

  const renderKeyTypeIcon = () => {
    switch (pixItem?.type) {
      case 'PHONE':
        return <SmartphoneRounded color="primary" />;
      case 'EMAIL':
        return <MailOutlineRounded color="primary" />;
      case 'EVP':
        return <VpnKeyOutlined color="primary" />;
      case 'CNPJ':
        return <BusinessRounded color="primary" />;
      default:
        return <VpnKeyOutlined color="primary" />;
    }
  };

  const isPortability = pixItem?.claimedDonation

  const renderStatus = () => {
    const currentStatus = isPortability ? `${pixItem?.claim?.claimType}_${pixItem?.claim?.claimStatus}` : pixItem?.status
    
    if (!currentStatus) return '-';

    return (
      <ChipStatus
        variant="outlined"
        size="small"
        {...KEY_STATUSES[currentStatus]}
      />
    );
  };

  const portabilidadeLabel = CLAIM_LABEL[pixItem?.claim?.claimType]

  const handleSelectKebabOption = (optionKebab, currentPix) => {
    const action = kebabActions[optionKebab.key];
    if (action) {
      action(currentPix);
    } else {
      console.log(`No action found for ${optionKebab.key}`);
    }
  };

  const formattedKey =
    pixItem?.type === 'CNPJ' ? formatCnpj(pixItem?.key) : pixItem?.key;

  const kebabActions = {
    see_key_details: () => handleOpenDetailsPortability(pixItem),
    copy_key: () => onCopyKey(),
    exclude_key: () => onDelete(pixItem)
  }

  const itemsKebab = [
    {key: 'see_key_details', label:`Ver detalhes da ${portabilidadeLabel}`, isDisabled: !isPortability, isHidden: !isPortability},
    {key: 'copy_key', label:'Copiar chave', isDisabled: isPortability},
    {key: 'exclude_key', label:'Excluir chave', isDisabled: isPortability},
  ]

  return (
    <Card role="listitem" className={styles.root}>
      <Box className={`${styles.avatarContainer} key-container`}>
        <Avatar variant="rounded" className={styles.avatar}>
          {renderKeyTypeIcon()}
        </Avatar>

        <Grid className={`${styles.infoContainer} `}>
          <Typography variant="caption" className={styles.label}>
            {KEY_LABELS[pixItem?.type] || 'Chave'}
          </Typography>
          <Tooltip title={formattedKey}>
            <Typography className={styles.content}>{formattedKey}</Typography>
          </Tooltip>
        </Grid>
      </Box>
      <Box className={styles.infoContainer}>
        <Typography variant="caption" className={styles.label}>
          Data e hora
        </Typography>
        <Typography className={styles.content}>
          {pixItem?.createdAt || '-'}
        </Typography>
      </Box>
      <Box className={styles.infoContainer}>
        <Typography variant="caption" className={styles.label}>
          Criado por
        </Typography>
        <Typography className={styles.content}>
          {pixItem?.createdBy || '-'}
        </Typography>
      </Box>
      <Box className={styles.infoContainer}>
        <Typography variant="caption" className={styles.label}>
          Status
        </Typography>
        {renderStatus()}
      </Box>
      <Box className={styles.kebabContainer}>
        <KebabMenu 
          items={itemsKebab}
          onSelectItem={(currentSelected)=> handleSelectKebabOption(currentSelected, pixItem.key)}
        />
      </Box>
    </Card>
  );
}
