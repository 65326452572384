import React, { useEffect } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { yupResolver } from '@hookform/resolvers/yup';
import { AttachMoneyOutlined, MeetingRoomOutlined } from '@material-ui/icons';
import { Avatar, Typography } from '@material-ui/core';

import { UserManagementActions as Actions } from '../../../../../../redux/store/UserManagement';

import { handleSnackbarStack } from '../../../../../../utils/handleSnackbarStack';
import { pusher } from '../../../../../../utils/pusher';

import { useStyles } from '../../../styles';

import validations from '../validations';
import unMask from '../../../../../../utils/unmask';

const CPF = sessionStorage.getItem('cpf');

export default function useNewUserPage() {
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { success, error } = handleSnackbarStack();

  const { isLoading, isSending } = useSelector(state => state.userManagement);

  const USER_OPTIONS = [
    {
      label: () => (
        <>
          <Avatar
            variant="rounded"
            className={styles.hrAvatar}
            aria-labelledby="hr-label"
          >
            <MeetingRoomOutlined color="warning" />
          </Avatar>
          <Typography
            id="hr-label"
            variant="body2"
            component="span"
            className={styles.avatarLabel}
          >
            Departamento pessoal
          </Typography>
        </>
      ),
      value: 'FINANCIAL',
    },
    {
      label: () => (
        <>
          <Avatar
            variant="rounded"
            className={styles.financialAvatar}
            aria-labelledby="financial-label"
          >
            <AttachMoneyOutlined color="success" />
          </Avatar>
          <Typography
            id="financial-label"
            variant="body2"
            component="span"
            className={styles.avatarLabel}
          >
            Financeiro
          </Typography>
        </>
      ),
      value: 'PERSONAL_DEPARTMENT',
    },
  ];

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validations),
  });

  const onSubmit = async () => {
    const actionResult = await dispatch(Actions.createUser());

    try {
      unwrapResult(actionResult);
      success('Novo utilizador adicionado com sucesso!');
      // setTimeout(() => {
      //   history.push('/employees/manage-users');
      // }, 1000);
    } catch (err) {
      error(err.error);
    }
  };

  const onContinue = async event => {
    event.preventDefault();

    const cpf = unMask(getValues('cpf'));

    dispatch(Actions.setFormState(getValues()));

    const actionResult = await dispatch(Actions.getFaceAuth({ cpf }));

    try {
      unwrapResult(actionResult);
      NiceModal.show('face-auth-modal');
    } catch (err) {
      error(err.error);
    }
  };

  useEffect(() => {
    const channel = pusher.subscribe(CPF);

    channel.bind('CAF_WEBHOOK_RESPONSE', ({ data }) => {
      if (data.isMatch) {
        success('Tudo certo! Agradecemos por confirmar sua identidade.');
        NiceModal.hide('face-auth-modal');
        onSubmit();
      } else {
        error(
          'Ocorreu um erro na sua autenticação por foto. Por favor, tente novamente em alguns instantes.',
        );
      }
    });

    return () => {
      pusher.unsubscribe(CPF);
    };
  }, []);

  return {
    errors,
    isValid,
    control,
    onContinue,
    isLoading,
    isSending,
    onSubmit: () => handleSubmit(onSubmit),
    userOptions: USER_OPTIONS,
  };
}
