import List from "./List";
import FileErrorReport from "./FileErrorReport";
import FeedbackSuccess from "./FeedbackSuccess";
import FileReportUpdateWithErrors from "./FileReportUpdateWithErrors";
import FileUpload from "./FileUpload";

const CostAndSectorCenter = {
  List,
  FileErrorReport,
  FeedbackSuccess,
  FileReportUpdateWithErrors,
  FileUpload,
};

export default CostAndSectorCenter;