import { Box, Typography } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import ReportRoundedIcon from '@material-ui/icons/ReportRounded';
import { useStyles } from './styles';

interface DownloadCompletedProps {
    title: string,
    subtitle?: string
    isError: boolean;
    action?: () => void;
    onClose?: () => void;
    closeAndActio?: boolean;
}

export const DownloadCompleted = ({
    title,
    subtitle,
    isError,
    action,
    onClose,
    closeAndActio=true,
}: DownloadCompletedProps)=> {
    const styles = useStyles();

    const iconMapper = {
        'Error-false': <CheckIcon className={styles.iconCheck} />,
        'Error-true': <ReportRoundedIcon className={styles.iconError} />
    }
    
    return (
        <Box className={styles.concludedMain}>
            <Box className={styles.concludedBox}>
                <Typography variant="body1">{title}</Typography>
                <Box 
                    className={closeAndActio ? styles.concludedMessageBox : styles.concludedMessageBoxNoPoint} 
                    {...(closeAndActio ? { onClick: () => action && action() } : {})}
                >
                    {iconMapper[`Error-${isError}`]}
                    <Typography variant="caption" className={isError ? styles.errorText: styles.concludedText}>
                        {subtitle}
                    </Typography>
                </Box>
            </Box>
            <CloseIcon
                className={styles.iconStyle}
                style={{ color: '#52197F' }}
                onClick={() => {
                        onClose && onClose()
                    } 
                }
            />
        </Box>
    )
}