import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';

export function SelectField({
  fullWidth = true,
  name = '',
  label = '',
  size = 'small',
  variant = 'outlined',
  disabled = false,
  value = '',
  onChange = e => {},
  options = [
    { label: 'Option 1', value: "1" },
    { label: 'Option 2', value: "2" },
  ],
  getOptionLabel = item =>
    typeof item?.label === 'function' ? item?.label() : item?.label,
  getOptionValue = item => item?.value,
  ...otherProps
}) {
  return (
    <TextField
      fullWidth={fullWidth}
      name={name}
      label={label}
      size={size}
      variant={variant}
      disabled={disabled}
      select
      value={value}
      onChange={(e, i) => onChange(i.props.value)}
      InputProps={{ style: { borderRadius: '0.5rem' } }}
      SelectProps={{
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        },
      }}
      {...otherProps}
    >
      {options.map(option => (
        <MenuItem key={JSON.stringify(option)} value={getOptionValue(option)}>
          {getOptionLabel(option)}
        </MenuItem>
      ))}
    </TextField>
  );
}
