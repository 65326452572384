import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme:Theme) => ({
    root: {
        maxWidth: 960,
        padding: theme.spacing(4),
        paddingTop: 0,

        '& > div': {
            padding: 0,
        },
    },
    closeIcon: {
        position: 'absolute',
        left: 'calc(100% - 36px)',
        padding: 0,
        top: theme.spacing(2),
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        paddingTop: '0 !important',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3),
    },
    title: {
        fontSize: theme.typography.h6.fontSize,
        fontWeight: 700,
        color: theme.palette.text.primary,
    },
    description: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 500,
        color: theme.palette.text.secondary,
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 16,
        marginBottom: 24,
    },
    clearFilterContainer:{
        display: 'flex',
        justifyContent: 'end',
    },
    clearFilterButton:{
        fontSize: 14,
        fontWeight: 500,
        color: 'red',
        "&:hover": {
            backgroundColor: "inherit",
        }
    },
    iconClear:{
        fill: 'red',
        width: '17px !important',
        height: '17px !important',
        marginRight: 6
    },
    alertContainer: {
        marginTop: 24,
        color: '#79560F',
        '& .MuiAlert-root':{
            backgroundColor: '#FCF5E9'
        },
        '& svg':{
            fill: '#E39F17'
        }
    },
    tableContainer: {
        marginTop: 24,
        borderBottom: 32,
        
        '& .MuiTableContainer-root':{
            boxShadow: 'none !important',
            border: '1px solid #E0E0E0'
        }
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 16,
        marginLeft: 'auto'
    },
    footerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 32
    },
    countCnabsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#EEE8F2',
        gap: 9,
        color: '#52197F',
        padding: 5,
        paddingRight: 16,
        borderRadius: 60
    },
    countCnab: {
        display: 'flex',
        minWidth: 25,
        minHeight: 18,
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#52197F',
        color: '#ffffff',
        fontSize: 12,
        padding: 4
    },
    statusTable: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        '& .check-circle':{
            fill: '#19797F'
        },
        '& .schedule':{
            fill: '#E39F17'
        },
        '& .update':{
            fill: '#E39F17'
        },
        '& .tonality':{
            fill: '#E39F17'
        }
    },
}));