import { reduxApi } from '../../../../services/reduxApi';
import { DownloadMultipleCnabsPayload, SearchForCnabPayload, SearchForCnabResponse } from './types';

const slice = reduxApi
  .enhanceEndpoints({
    addTagTypes: [],
  })
  .injectEndpoints({
    endpoints: builder => ({
        searchForCnab: builder.query<SearchForCnabResponse, SearchForCnabPayload>({
            query: ({filters, pageable:{ size=1, page=0, sort=[] }}) => ({
                url: '/files/employeespayments/search-for-cnab',
                method: 'GET',
                params: {
                    ...filters, 
                    size,
                    page,
                    sort
                },
            })
        }),
        downloadMultipleCnabs: builder.mutation<{}, DownloadMultipleCnabsPayload>({
          query: ({companyCode, excludedFileList, fileIdList, getAllFiles, filters}) => ({
              url: `files/employeespayments/return-file/${companyCode}/generate-batch/v2`,
              method: 'GET',
              params: {
                  getAllFiles,
                  excludedFileList,
                  fileIdList,
                  ...{
                    ...filters
                  }
              },
          })
      }),
    }),
  });

export const {
  useSearchForCnabQuery,
  useDownloadMultipleCnabsMutation
} = slice;
